import {MainRequest} from '../../Utils/Request';
import {AlumniPerson} from '../Find/service';
import {LoaderFunctionArgs} from 'react-router-dom';
import {getAllNewsListFromType} from '../News/service';

export interface IAlumniData {
    id: string; //校友会id
    name: string; //校友会姓名
    guid: string; //校友会guid
    manager: string; //联络人姓名
    phone: string; //联络人联系方式
    email: string; //校友会邮箱
    statusFlag: BooleanValue; //审核状态(0.审核中,1.已加入)
    number: string; //成员数量
}
export function getJoinAlumni() {
    //main页根据userId获得校友会列表
    return MainRequest.get<SuccessData<IAlumniData[]>>(
        `alumni/getAlumniByUserId`,
    ).then(res => res.data.data || []);
}

//获取校友会分类
export function getAlumniAllTypeList() {
    //main页根据userId获得校友会列表
    return MainRequest.get<SuccessData<LabelValue[]>>(
        `alumni/getAlumniAllTypeList`,
    )
        .then(res => res.data.data || [])
        .then(res => {
            return res.map(item => {
                return {
                    title: item.label,
                    key: item.value,
                };
            });
        });
}

//获取校友会列表
export interface GetAlumniList {
    alumniType: string;
    name: string;
}
export interface IndexAlumni {
    alumniId: string; //校友会id
    name: string; //校友会名称
}
export function getAlumniList(params: GetAlumniList) {
    //main页根据userId获得校友会列表
    return MainRequest.get<SuccessData<IndexAlumni[]>>(
        `alumni/getTypeAllAlumni`,
        {params},
    ).then(res => res.data.data);
}

export async function AlumniTypeData() {
    return Promise.all([getJoinAlumni(), getAlumniAllTypeList()]).then(res => {
        const [joinData, typeData] = res;
        return {
            joinData,
            typeData,
        };
    });
}

//审核状态("" 未申请,；1.待审核；2.已通过)
export enum JoinStatus {
    CanJoin = '',
    ToBeReviewed = '1',
    Passed = '2',
}
//校友会简介
export interface AlumniAboutProps {
    statusFlag: JoinStatus;
    info: string;
}
export function getAlumniAbout(alumniId: string) {
    //main页根据userId获得校友会列表
    return MainRequest.get<SuccessData<AlumniAboutProps>>(
        `alumni/getInfoByAlumniId`,
        {params: {alumniId}},
    ).then(res => res.data.data);
}

//校友会成员
export interface getAlumniMemberProps extends PageProps {
    alumniId: string;
    allSelectValue: string;
}
export function getAlumniMember(data: getAlumniMemberProps) {
    return MainRequest.postForm<PagedData<AlumniPerson[]>>(
        `alumni/getMemberByAlumniId`,
        data,
    ).then(res => res.data.data);
}

//加入校友会
export function joinAlumni(alumniId: string) {
    //组件ApplyJoin申请加入校友会
    return MainRequest.get(`alumni/joinAlumni`, {
        params: {alumniId},
    }).then(res => res.data);
}

//校友会详情
export async function AlumniDetailData(e: LoaderFunctionArgs) {
    const alumniId = e.params.alumniId as any;

    return Promise.all([
        getAllNewsListFromType('', alumniId),
        getAlumniAbout(alumniId),
    ]).then(res => {
        const [newsData, aboutData] = res;
        return {
            newsData,
            aboutData,
        };
    });
}

//退出
export function cancelAlumni(alumniId: string) {
    return MainRequest.postForm(`alumni/handlesQuittersAlumni`, {
        alumniId,
    });
}
