import React from 'react';
import style from './index.module.scss';
import {FileProps, UploadStatus} from '../../../../Utils/Hooks/UpoadGenProps';

interface ListItemProps {
    file: FileProps;
}

export default function ListItem({file}: ListItemProps) {
    return (
        <div
            className={style.root}
            style={{
                backgroundImage:
                    file.status === UploadStatus.Uploading
                        ? `linear-gradient(to right, #C0E4FF 0%, #C0E4FF ${file.percent}%, #fff ${file.percent}%, #fff 100%)`
                        : '',
            }}>
            <div className={style.name}>{file?.file?.name}</div>

            {file.status && (
                <div className={style.status}>
                    {file.status === UploadStatus.Uploading && (
                        <div className={style.uploading}>上传中</div>
                    )}
                    {file.status === UploadStatus.Done && (
                        <div className={style.success}>成功</div>
                    )}
                    {file.status === UploadStatus.Wait && (
                        <div className={style.success}>排队中</div>
                    )}
                    {file.status === UploadStatus.Error && (
                        <div className={style.error}>未成功</div>
                    )}
                    {file.status === UploadStatus.Stop && (
                        <div className={style.error}>禁止格式</div>
                    )}
                </div>
            )}
        </div>
    );
}
