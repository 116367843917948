import useRefresh from '../../../../../Utils/Hooks/useRefresh';
import {useInterval} from 'react-use';
import {useMemo} from 'react';
import dayjs from 'dayjs';
import {RedoOutline} from 'antd-mobile-icons';
import styles from './index.module.scss';

interface CountTimeProps {
    onRefresh(): void;
}

export function CountTime({onRefresh}: CountTimeProps) {
    const [key, refresh] = useRefresh();
    useInterval(refresh, 1000);

    const time = useMemo(() => dayjs().format('YYYY-MM-DD HH:mm:ss'), [key]);

    return (
        <div className={styles.root}>
            <div className={styles.title}>当前时间</div>

            <div className={styles.content}>
                {time}
                <div onClick={onRefresh}>
                    <RedoOutline />
                </div>
            </div>
        </div>
    );
}
