import {atom} from 'jotai';
import {getLoginUser} from '../Page/Auth/service';

export const userStore = atom(async () => {
    try {
        return await getLoginUser();
    } catch (e) {
        return undefined;
    }
});
