import React from 'react';
import Title from '../../../Components/Title';
import Style from './index.module.scss';
import {
    Button,
    Form,
    Input,
    SafeArea,
    Stepper,
    TextArea,
    Toast,
} from 'antd-mobile';
import {SimpleSwitch} from '../../../Components/Form/SimpleSwitch';
import {errorMessage, isTrue} from '../../../Utils/help';
import {
    BusOptions,
    postReturn,
    PostReturnProps,
    TimeRangerOptions,
} from './service';
import dayjs from 'dayjs';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import rules from '../../../Utils/rules';
import {useNavigate} from 'react-router-dom';
import SelectDictFormItem from '../../../Components/Form/SelectDictFormItem';
import SelectDate from '../../../Components/Form/SelectDate';

const InputProps = {
    placeholder: '请输入',
};

const Index = () => {
    const navigate = useNavigate();
    const [submit, {loading}] = useRequestFn(postReturn);
    async function onFinish(data: PostReturnProps) {
        try {
            const {id} = await submit(data);
            Toast.show('申请成功');
            navigate(`manage/${id}`);
        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }
    const [form] = Form.useForm<PostReturnProps>();
    const shootFlag = Form.useWatch('shootFlag', form);
    const busFlag = Form.useWatch('busFlag', form);
    const bannerFlag = Form.useWatch('bannerFlag', form);

    return (
        <div className={Style.root}>
            <Form
                form={form}
                preserve={false}
                layout="horizontal"
                onFinish={onFinish}
                style={{
                    '--border-top': 'none',
                    '--border-bottom': 'none',
                    '--prefix-width': '7.5em',
                }}>
                <div className={Style.pd}>
                    <Title>申请人信息</Title>
                </div>
                <div className={Style.card}>
                    <Form.Item
                        label="学院名称"
                        name="college"
                        rules={[{required: true}]}>
                        <Input {...InputProps} />
                    </Form.Item>
                    <SelectDate
                        name="enterYear"
                        label="毕业时间"
                        rules={[{required: true}]}
                        dateProps={{
                            min: dayjs('1800').toDate(),
                            max: new Date(),
                            precision: 'month',
                        }}
                    />
                    <Form.Item
                        label="专业"
                        name="major"
                        rules={[{required: true}]}>
                        <Input {...InputProps} />
                    </Form.Item>
                    <SelectDate
                        name="returnTime"
                        label="返校时间"
                        rules={[{required: true}]}
                        dateProps={{
                            min: new Date(),
                        }}
                    />
                    <Form.Item
                        label="人数"
                        name="peopleNum"
                        rules={[{required: true}]}>
                        <Input {...InputProps} />
                    </Form.Item>
                    <Form.Item
                        label="联系人"
                        name="contact"
                        rules={[{required: true}]}>
                        <Input {...InputProps} />
                    </Form.Item>
                    <Form.Item
                        label="联系方式"
                        name="phone"
                        rules={[
                            {
                                required: true,
                            },
                            rules.MobilePhone,
                        ]}>
                        <Input {...InputProps} />
                    </Form.Item>
                    <Form.Item
                        label="现任单位"
                        name="companyName"
                        rules={[{required: true}]}>
                        <Input {...InputProps} />
                    </Form.Item>
                </div>
                <div className={Style.pd}>
                    <Title>定制服务</Title>
                </div>
                <div className={Style.card}>
                    <Form.Item label="协助联系摄影" name="shootFlag">
                        <SimpleSwitch />
                    </Form.Item>
                    {isTrue(shootFlag) && (
                        <SelectDictFormItem
                            name="shootTime"
                            label="摄影时间段"
                            options={TimeRangerOptions}
                        />
                    )}

                    <Form.Item label="往返校园大巴" name="busFlag">
                        <SimpleSwitch />
                    </Form.Item>
                    {isTrue(busFlag) && (
                        <>
                            <SelectDictFormItem
                                name="busTime"
                                label="乘坐方式"
                                options={BusOptions}
                            />
                            <Form.Item label="人数" name="busNum">
                                <Stepper defaultValue={1} min={1} />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item label="条幅定制" name="bannerFlag">
                        <SimpleSwitch />
                    </Form.Item>
                    {isTrue(bannerFlag) && (
                        <Form.Item name="bannerContent">
                            <TextArea autoSize placeholder="请输入条幅内容" />
                        </Form.Item>
                    )}
                </div>
                <div className={Style.pd}>
                    <Title>备注</Title>
                </div>
                <div className={Style.card}>
                    <Form.Item name="info">
                        <TextArea autoSize placeholder="请输入备注" />
                    </Form.Item>
                </div>

                <div className={Style.button}>
                    <Button
                        loading={loading}
                        type="submit"
                        block
                        color="primary"
                        shape="rounded">
                        提交
                    </Button>
                </div>

                <SafeArea position="bottom" />
            </Form>
        </div>
    );
};

export default Index;
