import React, {useMemo} from 'react';
import Style from './index.module.scss';
import {Button, Card, List, ResultPage, Toast} from 'antd-mobile';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useParams} from 'react-router-dom';
import {useLocation} from 'react-use';
import Title from '../../../../Components/Title';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import {ReturnPersonData} from '../service';
export const ReturnPersonDataLoader = makeLoader(
    async e => await ReturnPersonData(e),
);

const Index = () => {
    const data = useLoaderData<typeof ReturnPersonDataLoader>();
    const {id} = useParams<{id: string}>();
    const location = useLocation();
    function onCopy(text: string, result: boolean) {
        if (result) {
            Toast.show('复制成功');
        } else {
            Toast.show('复制失败，请手动复制');
        }
    }

    const url = useMemo(() => {
        return location.origin + '/return/' + id;
    }, [id]);

    return (
        <div className={Style.root}>
            <ResultPage
                className={Style.page}
                status="success"
                title="返校活动已发起"
                description="复制下方的链接给参与者吧！团员打开链接即可报名">
                <ResultPage.Card
                    style={{height: 64, padding: 8}}
                    className={Style.title}>
                    {url}
                    <CopyToClipboard text={url} onCopy={onCopy}>
                        <Button size="small" color="primary">
                            点击复制
                        </Button>
                    </CopyToClipboard>
                </ResultPage.Card>
                <Card className={Style.list}>
                    <Title>报名列表</Title>
                    <List
                        style={{
                            '--border-bottom': 'none',
                            '--border-top': 'none',
                            '--padding-left': '0',
                            '--padding-right': '0',
                        }}>
                        {data.personList.map(item => (
                            <List.Item
                                key={item.joinTime}
                                extra={item.joinTime}>
                                {item.personName}
                            </List.Item>
                        ))}
                    </List>
                </Card>
            </ResultPage>
        </div>
    );
};

export default Index;
