import React from 'react';
import Style from './index.module.scss';
import {Tag} from 'antd-mobile';
import {ActiveStatus, LineStatus} from '../../service';

interface Props {
    status: ActiveStatus;
}
const StatusTag = ({status}: Props) => {
    switch (status) {
        case ActiveStatus.NoStart:
            return <div className={Style.noStartStatus}>未开始</div>;
        case ActiveStatus.Loading:
            return <div className={Style.loadingStatus}>进行中</div>;
        case ActiveStatus.End:
            return <div className={Style.endStatus}>已结束</div>;
        case ActiveStatus.Reject:
            return <div className={Style.rejectStatus}>未通过</div>;
    }
};

interface TagProps {
    status: LineStatus;
}
const DefaultTag = ({status}: TagProps) => {
    let str = '';
    switch (status) {
        case LineStatus.OnLine:
            str = '线上活动';
            break;
        case LineStatus.OffLine:
            str = '线下活动';
            break;
    }
    return (
        <Tag color="primary" fill="outline">
            {str}
        </Tag>
    );
};

export {StatusTag, DefaultTag};
