import {CardContext} from '../../context';
import React from 'react';
import {
    getAlumniCard,
    checkAlumniCardAct,
    getAlumniCardBasicParam,
} from 'src/Public/card';
import {useQuery} from 'react-query';
import {useAtomValue} from 'jotai';
import {ServiceStore} from '../../../../Store/serviceStore';

interface CardProviderProps {
    children: React.ReactNode;
}

export function CardProvider({children}: CardProviderProps) {
    const service = useAtomValue(ServiceStore);

    const getCard = useQuery({
        queryKey: ['getAlumniCard'],
        queryFn: getAlumniCard,
        onError: undefined,
        retry: false,
    });

    const getAct = useQuery({
        queryKey: ['checkAlumniCardAct'],
        queryFn: checkAlumniCardAct,
        onError: undefined,
        retry: false,
    });

    const getBase = useQuery({
        queryKey: ['getAlumniCardBasicParam'],
        queryFn: getAlumniCardBasicParam,
        onError: undefined,
    });

    if (getCard.isLoading || getAct.isLoading || getBase.isLoading) return null;
    if (!service.has('alumni_cards_management')) return null;

    return (
        <CardContext.Provider
            value={{
                data: getCard.data,
                base: getBase.data,
                checkFlag: getAct.data?.checkFlag,
            }}>
            {children}
        </CardContext.Provider>
    );
}
