import React from 'react';
import {CardBaseInfo, CardInfo} from 'src/Public/card';

interface CardContextProps {
    data?: CardInfo | null;
    base?: CardBaseInfo | null;
    checkFlag?: string;
}

export const CardContext = React.createContext<CardContextProps>({});
