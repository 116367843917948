import qs from 'qs';
import {MainRequest} from '../../../Utils/Request';

// 获取行业列表
export function getIndustryType() {
    return MainRequest.post<SuccessData<DictItem[]>>(
        `/alumni/getIndustryType`,
    ).then(res =>
        res.data.data.map(item => ({
            label: item.label,
            value: item.code,
        })),
    );
}

// 激活校友卡
export function activateAlumniCard(data: {
    cardPic: string;
    companyName: string;
    companyOrg: string;
    email: string;
    industry: string;
}) {
    return MainRequest.post('/alumni/activateAlumniCard', qs.stringify(data));
}

export interface IUserInfo {
    name: string; //姓名
    endYear: string; //入学年
    wxHeadPic: string; //微信头像
    companyName: string; //单位名称
    companyOrg: string; //单位部门
    industry: string; //所属行业
    cardPic: string; //证件照片
    email: string; //电子邮箱
    cardPicInfo: string; //图片原因
}

/**
 * 获取用户信息
 */
export function getPersonByLoginUserId() {
    return MainRequest.get<SuccessData<IUserInfo>>(
        `/alumni/getPersonByLoginUserId`,
    ).then(res => res.data.data);
}
