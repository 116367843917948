import React from 'react';
import Style from './index.module.scss';
import {SafeArea} from 'antd-mobile';

interface Props {
    children: React.ReactNode;
    refs?: React.LegacyRef<HTMLDivElement> | undefined;
}
const Index = ({children, refs}: Props) => {
    return (
        <div className={Style.root} ref={refs}>
            {children} <SafeArea position="bottom" />
        </div>
    );
};

export default Index;
