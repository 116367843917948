import React, {useMemo} from 'react';
import Style from './index.module.scss';
import {Swiper, Image} from 'antd-mobile';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import {MainPageAct, NewsListFromType, NewsType} from '../../../News/service';
import {HomeData} from '../../service';
import {useNavTo} from '../../../../Utils/Hooks/useNavTo';
import {getActiveHref} from '../../../Activity/service';

const HomeDataLoader = makeLoader(async () => await HomeData());

const Index = () => {
    const navigate = useNavTo();
    const data = useLoaderData<typeof HomeDataLoader>();

    function gotoActivity(item: MainPageAct) {
        const url = getActiveHref(item);
        navigate(url);
    }

    function gotoNews(item: NewsListFromType) {
        if (item.officialUrl) return (window.location.href = item.officialUrl);
        navigate(`/news/${item.type}/${item.id}`);
    }

    const swiperItem = useMemo(() => {
        const arr: any[] = [];
        data[NewsType.Swiper].forEach(item =>
            arr.push(
                <Swiper.Item
                    key={item.id}
                    className={Style.content}
                    onClick={() => gotoNews(item)}>
                    <Image
                        src={item.smallPic}
                        className={Style.swiperItem}
                        alt=""
                    />
                </Swiper.Item>,
            ),
        );
        data.SwiperAct.forEach(item =>
            arr.push(
                <Swiper.Item
                    key={item.id}
                    className={Style.content}
                    onClick={() => gotoActivity(item)}>
                    <Image
                        src={item.mainPagePic}
                        className={Style.swiperItem}
                        alt=""
                    />
                </Swiper.Item>,
            ),
        );
        return arr;
    }, [data]);

    return (
        <div className={Style.root}>
            <Swiper
                loop
                autoplay
                style={{
                    '--border-radius': '8px',
                }}>
                {swiperItem}
            </Swiper>
        </div>
    );
};

export default Index;
