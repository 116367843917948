import React, {useState} from 'react';
import Style from './index.module.scss';
import {Badge, Image} from 'antd-mobile';
import {List, InfiniteScroll} from 'antd-mobile';
import {
    getNotice,
    getNoticeDetail,
    GetNoticeProps,
    MessageType,
    NoticeList,
} from './service';
import SkeletonLoading from '../../Components/SkeletonLoading';
import {isTrue} from '../../Utils/help';
import Empty from '../../Components/Empty';
import {getActiveHref} from '../Activity/service';
import {useNavTo} from '../../Utils/Hooks/useNavTo';

const Index = () => {
    const initialParams = {
        pageNo: 0,
        pageSize: 10,
    };

    const navigate = useNavTo();
    const [params, setParams] = useState<GetNoticeProps>(initialParams);
    const [hasMore, setHasMore] = useState(true);
    const [list, setList] = useState<NoticeList[]>([]);

    const getList = async (params: GetNoticeProps) => {
        setParams(params);
        const res = await getNotice(params);
        setList(data => [...data, ...res.list]);
        setHasMore(res.hasNextPage);
    };

    //加载更多
    const loadMore = async () => {
        await getList({
            ...params,
            pageNo: params.pageNo + 1,
        });
    };

    async function onClick(item: NoticeList) {
        await getNoticeDetail(item.id);

        switch (item.type) {
            case MessageType.Birthday:
                return navigate(`birthday/${item.id}`);
            case MessageType.System:
                return navigate(`${item.id}`);
            case MessageType.Activity:
                return navigate(
                    getActiveHref({
                        id: item.content,
                        type: item.bmark,
                    }),
                );
            default:
                return navigate(`/notice/${item.id}`);
        }
    }

    if (!list) {
        return <SkeletonLoading />;
    }

    return (
        <div className={Style.root}>
            {list.length === 0 && <Empty />}
            <List>
                {list.map(item => (
                    <List.Item
                        arrow
                        onClick={() => onClick(item)}
                        clickable
                        extra={
                            <Badge content={!isTrue(item.flag) && Badge.dot} />
                        }
                        key={item.id}
                        prefix={
                            <Image
                                src={item.icon}
                                style={{borderRadius: '100%'}}
                                fit="cover"
                                width={40}
                                height={40}
                            />
                        }
                        description={item.title}>
                        {item.noticeType}
                    </List.Item>
                ))}
            </List>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </div>
    );
};

export default Index;
