import {
    getAllNewsListFromType,
    getMainPageAct,
    NewsType,
} from '../News/service';
import {getMenu, MenuType} from '../../Utils/help';
export async function HomeData() {
    return Promise.all([
        getAllNewsListFromType(NewsType.CampusDynamics, '', 2),
        getAllNewsListFromType(NewsType.AlumniInterviews, '', 2),
        getAllNewsListFromType(NewsType.Swiper, '', 5),
        getMainPageAct(),
        getMenu(MenuType.HomeGrid),
        getMenu(MenuType.Ad),
    ]).then(res => {
        const [CampusDynamics, AlumniInterviews, Swiper, SwiperAct, Menu, Ad] =
            res;
        return {
            [NewsType.CampusDynamics]: CampusDynamics,
            [NewsType.AlumniInterviews]: AlumniInterviews,
            [NewsType.Swiper]: Swiper,
            SwiperAct,
            Menu,
            Ad,
        };
    });
}
