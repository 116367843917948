import React, {ReactNode} from 'react';
import Style from './index.module.scss';
import {Ellipsis} from 'antd-mobile';
import {useNavTo} from '../../Utils/Hooks/useNavTo';

export interface ArticleGridProps {
    img: string; //预览图
    imgTag?: React.ReactNode;
    title: string; //标题
    href: string; //链接
    time: string;
    extend?: React.ReactNode;
    middleExtend?: React.ReactNode;
}

const Index = ({
    img,
    title,
    href,
    time,
    extend,
    imgTag,
    middleExtend,
}: ArticleGridProps) => {
    const navigate = useNavTo();

    function Render({children}: {children: ReactNode}) {
        if (href.includes('webview')) {
            return (
                <a href={href} className={Style.root}>
                    {children}
                </a>
            );
        } else {
            return (
                <div onClick={() => navigate(href)} className={Style.root}>
                    {children}
                </div>
            );
        }
    }
    return (
        <Render>
            <div className={Style.img} style={{backgroundImage: `url(${img})`}}>
                {imgTag}
            </div>
            <div className={Style.right}>
                <Ellipsis
                    style={{color: '#333'}}
                    direction="end"
                    rows={middleExtend ? 1 : 2}
                    content={title}
                />
                {middleExtend}
                <p className={Style.tips}>
                    <span>{time}</span>
                    <span>{extend}</span>
                </p>
            </div>
        </Render>
    );
};

export default Index;
