import {AppModel, AppModelInfo, ModelItemCode} from '../../Public';
import {AppSettingAtom} from '../../Store/commonStore';
import {useAtomValue} from 'jotai';
import {useMemo} from 'react';

export default function useModelSetting(
    model: AppModel,
    code: ModelItemCode[],
) {
    const modelData = useAtomValue(AppSettingAtom({model}));
    const data = useMemo(() => {
        let arr: (AppModelInfo | undefined)[] = [];
        code.forEach(item => {
            const o = modelData.find(current => current.code === item);
            arr.push(o);
        });
        return arr;
    }, [modelData, code]);

    return {
        data,
    };
}
