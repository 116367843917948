import React from 'react';
import TabBar from '../TabBar';

interface Props {
    children: React.ReactNode;
}
const Index = ({children}: Props) => {
    return (
        <>
            {children}
            <TabBar />
        </>
    );
};

export default Index;
