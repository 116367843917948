import {Avatar, List} from 'antd-mobile';
import React from 'react';
import Style from './index.module.scss';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import {PersonDetailData} from '../service';

export const PersonDetailDataLoader = makeLoader(
    async e => await PersonDetailData(e),
);
const Index = () => {
    const data = useLoaderData<typeof PersonDetailDataLoader>();
    return (
        <div className={Style.root}>
            <div className={Style.top}>
                <Avatar
                    src={data.pic}
                    style={{'--size': '100px', '--border-radius': '100%'}}
                />
                <p className={Style.name}>{data.name}</p>
            </div>
            <List className={Style.list}>
                <List.Item extra={data.companyName}>工作单位</List.Item>
                <List.Item extra={data.phone}>手机</List.Item>
                <List.Item extra={data.weixinNum}>微信</List.Item>
                <List.Item extra={data.email}>邮箱</List.Item>
            </List>
        </div>
    );
};

export default Index;
