import React, {Suspense, useMemo} from 'react';
import {Outlet, useNavigation} from 'react-router-dom';
import Tab from '../Tab';
import PageLoading from '../../PageLoading';
import TopNav from '../TopNav';
import {SafeArea} from 'antd-mobile';
import useRouteData from '../../../Utils/Hooks/useRouteData';
import {useQuery} from 'react-query';
import {getOrgData} from '../../../Utils/help';

const Index = () => {
    const navigation = useNavigation();
    const {current, haveTab, haveTopNav} = useRouteData();

    useQuery({
        queryKey: ['getOrgData'],
        queryFn: () => getOrgData(),
        onSuccess: res => {
            document.title = res.name;
        },
    });

    const Out = useMemo(() => {
        let node = (
            <Suspense fallback={<PageLoading />}>
                <Outlet />
            </Suspense>
        );
        if (haveTab) {
            node = <Tab>{node}</Tab>;
        }
        if (haveTopNav) {
            node = <TopNav>{node}</TopNav>;
        }
        return node;
    }, [haveTab, current, haveTopNav]);

    const paddingBottom = useMemo(() => {
        if (haveTab) {
            return 50;
        }

        return 0;
    }, [haveTab]);

    if (navigation.state === 'loading') {
        return <PageLoading />;
    }

    return (
        <div
            style={{
                minHeight: '100%',
                paddingBottom,
                maxWidth: 750,
                margin: '0 auto',
            }}>
            {Out}
            <SafeArea position="bottom" />
        </div>
    );
};

export default Index;
