import React from 'react';
import Style from './index.module.scss';
import {Link} from 'react-router-dom';

interface Props {
    title?: string;
    href: string;
}
const Index = ({title, href}: Props) => {
    return (
        <div className={Style.root}>
            <span className={Style.title}>{title}</span>
            <Link to={href} className={Style.href}>
                更多
            </Link>
        </div>
    );
};

export default Index;
