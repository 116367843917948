import React from 'react';
import Style from './index.module.scss';
import Title from '../../../../Components/Title';
import {Button, Form, Input, Toast} from 'antd-mobile';
import {errorMessage, isTrue} from '../../../../Utils/help';
import {SimpleSwitch} from '../../../../Components/Form/SimpleSwitch';
import {JoinReturnProps, PostReturnProps, returnSchAddPerson} from '../service';
import {useNavigate, useParams} from 'react-router-dom';
import useRequestFn from '../../../../Utils/Hooks/useRequestFn';
import Rules from '../../../../Utils/rules';

const InputProps = {
    placeholder: '请输入',
};
const Index = () => {
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();
    const [submit, {loading}] = useRequestFn(returnSchAddPerson);
    async function onFinish(data: JoinReturnProps) {
        try {
            await submit({
                ...data,
                retSchId: id!,
            });
            Toast.show('报名成功');
            navigate('/my/activity', {replace: true});
        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }
    const [form] = Form.useForm<PostReturnProps>();
    const haveCare = Form.useWatch('haveCare', form);

    return (
        <div className={Style.root}>
            <Form
                form={form}
                preserve={false}
                layout="horizontal"
                onFinish={onFinish}
                style={{'--border-top': 'none', '--border-bottom': 'none'}}>
                <div className={Style.pd}>
                    <Title>个人信息填报</Title>
                </div>
                <div className={Style.card}>
                    <Form.Item
                        label="姓名"
                        name="personName"
                        rules={[{required: true}]}>
                        <Input {...InputProps} />
                    </Form.Item>
                    <Form.Item
                        label="手机号"
                        name="phone"
                        rules={[{required: true}, Rules.MobilePhone]}>
                        <Input {...InputProps} />
                    </Form.Item>
                    <Form.Item label="私家车" name="haveCare">
                        <SimpleSwitch />
                    </Form.Item>
                    {isTrue(haveCare) && (
                        <Form.Item
                            label="车牌号"
                            name="privateCarNum"
                            rules={[{required: true}]}>
                            <Input {...InputProps} />
                        </Form.Item>
                    )}
                </div>

                <div className={Style.button}>
                    <Button
                        loading={loading}
                        type="submit"
                        block
                        color="primary"
                        shape="rounded">
                        提交
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default Index;
