import useRefresh from '../../../../../Utils/Hooks/useRefresh';
import {CountTime} from '../CountTime';
import {QRCodeSVG} from 'qrcode.react';
import {useMemo} from 'react';
import styles from './index.module.scss';

interface QrCodeProps {
    value?: string;
}

export function QrCode({value}: QrCodeProps) {
    const [key, refresh] = useRefresh();
    const qrcode = useMemo(() => new Date().toUTCString(), [key]);

    return (
        <div className={styles.root}>
            <CountTime onRefresh={refresh} />
            <QRCodeSVG value={value || qrcode} width={220} />
        </div>
    );
}
