import React from 'react';
import Style from './index.module.scss';
import {ImageViewer} from 'antd-mobile';
import lodash from 'lodash-es';
import Empty from '../Empty';

interface Props {
    text: string;
}
const Index = ({text}: Props) => {
    function onClick(event: React.MouseEvent<HTMLDivElement>) {
        // @ts-ignore
        if (event?.target?.tagName === 'IMG') {
            // @ts-ignore
            ImageViewer.show({image: event?.target?.src});
        }
    }

    if (lodash.isEmpty(text)) {
        return <Empty />;
    }
    return (
        <div>
            <div
                onClick={event => onClick(event)}
                className={Style.root}
                dangerouslySetInnerHTML={{__html: text}}
            />
        </div>
    );
};

export default Index;
