import React from 'react';
import Style from './index.module.scss';
import {SpinLoading} from 'antd-mobile';

interface Props {
    loading: boolean;
    children: React.ReactNode;
}
const Index = ({loading, children}: Props) => {
    if (!loading) {
        return <>{children}</>;
    }
    return (
        <div className={Style.root}>
            <div className={Style.layout}>
                <SpinLoading style={{'--size': '24px'}} color="primary" />
            </div>
            {children}
        </div>
    );
};

export default Index;
