import React from 'react';
import Style from './index.module.scss';
import {Skeleton} from 'antd-mobile';

const Index = () => {
    return (
        <div className={Style.root}>
            <Skeleton.Paragraph lineCount={15} animated />
        </div>
    );
};

export default Index;
