import React from 'react';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import {Grid, SwipeAction, Toast} from 'antd-mobile';
import {
    CollectionList,
    CollectionListData,
    deleteCollectionNews,
} from './service';
import ArticleGrid from '../../../Components/ArticleGrid';
import Style from './index.module.scss';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import {errorMessage} from '../../../Utils/help';
import {useNavigate} from 'react-router-dom';
import Spin from '../../../Components/Spin';
import lodash from 'lodash-es';
import Empty from '../../../Components/Empty';
import {getActiveHref} from '../../Activity/service';

export const CollectionDataLoader = makeLoader(CollectionListData);

export default function Index() {
    const data = useLoaderData<typeof CollectionDataLoader>();
    const navigate = useNavigate();

    function getHref(item: any) {
        return getActiveHref({...item, id: item.activityId});
    }

    const [deleteNews, {loading: deleteLoading}] =
        useRequestFn(deleteCollectionNews);

    async function onDelete(item: CollectionList) {
        try {
            await deleteNews(item.saveId);
            navigate('.', {replace: true});
        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }

    return (
        <div className={Style.root}>
            {lodash.isEmpty(data) && <Empty />}
            <Spin loading={deleteLoading}>
                <Grid columns={1} gap={15}>
                    {data.map(item => (
                        <Grid.Item key={item.saveId}>
                            <SwipeAction
                                rightActions={[
                                    {
                                        key: 'delete',
                                        text: '删除',
                                        onClick: () => onDelete(item),
                                        color: 'danger',
                                    },
                                ]}>
                                <ArticleGrid
                                    href={getHref(item)}
                                    time={item.createTime}
                                    title={item.titleName}
                                    img={item.mainPic}
                                />
                            </SwipeAction>
                        </Grid.Item>
                    ))}
                </Grid>
            </Spin>
        </div>
    );
}
