import React, {useEffect} from 'react';
import {atom, useAtomValue} from 'jotai';
import {goLogin} from '../../Utils/help';
import {getPersonByLoginUserId} from '../../Public';

const userAtom = atom(async () => {
    try {
        return await getPersonByLoginUserId();
    } catch {
        return undefined;
    }
});

interface RequireAuthProps {
    children: React.ReactNode;
}

export default function RequireAuth({children}: RequireAuthProps) {
    const user = useAtomValue(userAtom);

    useEffect(() => {
        if (user) return;
        goLogin();
    }, [user]);

    if (!user) return null;

    return <>{children}</>;
}
