import {useQuery} from 'react-query';
import {getDonateMiniPrgram} from './service';
import styles from './index.module.scss';
import Share from '../../../Components/FunctionIcon/Share';
import {getFullUrl} from '../../../Utils/help';

export function Donate() {
    const query = useQuery({
        queryKey: ['getDonateMiniPrgram'],
        queryFn: getDonateMiniPrgram,
    });

    return (
        <div className={styles.root}>
            <img src={query.data} alt="" className={styles.qrcode} />

            <div className={styles.tip}>请扫描二维码进入捐赠小程序</div>

            <Share
                img={query.data || ''}
                text="助力母校"
                url={getFullUrl(window.location.pathname)}
            />
        </div>
    );
}
