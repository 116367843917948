import {atomFamily} from 'jotai/utils';
import {atomWithRefresh} from '../Utils/atomWithRefresh';
import {
    AppModel,
    getAppSetting,
    getDict,
    getSaasLoginSetting,
    getSaasQiniuInfo,
    getServiceMenu,
    IndustryDict,
    LearningStyleStyle,
    TrainingStyle,
} from '../Public';
import {atom} from 'jotai';
import {makeDict} from '../Utils/Hooks/useDict';

export interface ModelItem {
    label: string;
    value: string;
}

export const dictStore = atomFamily(
    (params: {
        code: string;
        bindValue?: keyof ModelItem;
        bindName?: keyof ModelItem;
    }) =>
        atomWithRefresh(async () => {
            const {code, bindValue, bindName} = params;
            const dictList = await getDict(code);
            return makeDict(
                dictList.map(item => ({
                    ...item,
                    label: item[bindName || 'label'],
                    value: item[bindValue || 'value'],
                })),
            );
        }),
    (newParams, oldParams) => {
        return JSON.stringify(newParams) === JSON.stringify(oldParams);
    },
);

export const TrainingStyleAtom = atom(async () => {
    const data = await TrainingStyle();
    return makeDict(data.map(item => ({label: item.label, value: item.code})));
});

export const LearningStyleAtom = atom(async () => {
    const data = await LearningStyleStyle();
    return makeDict(data.map(item => ({label: item.label, value: item.code})));
});

export const QiniuUploadUrlAtom = atom(async () => {
    return await getSaasQiniuInfo();
});

export const LoginSettingAtom = atom(async () => {
    return await getSaasLoginSetting();
});

export const IndustryAtom = atom(async () => {
    const data = await IndustryDict();
    return makeDict(data.map(item => ({label: item.label, value: item.code})));
});

export const AppSettingAtom = atomFamily(
    (params: {model: AppModel}) =>
        atomWithRefresh(async () => {
            const {model} = params;
            return await getAppSetting(model);
        }),
    (newParams, oldParams) => {
        return JSON.stringify(newParams) === JSON.stringify(oldParams);
    },
);

export const MenuMapAtom = atom(async () => {
    const data = await getServiceMenu();
    return makeDict(
        data.map(item => ({label: item.name, value: item.serviceCode})),
    );
});
