import React, {useEffect, useMemo} from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import './Style/reset.scss';
import {MainMenu} from './MainMenu';
import {useAtom} from 'jotai';
import {BgImgAtom, LogoImgAtom} from './Store/ImgAtom';
import PageLoading from './Components/PageLoading';
import useRequest from './Utils/Hooks/useRequest';
import {getSaasTheme} from './Public';
import {QueryClientProvider} from 'react-query';
import {queryClient} from './Utils/Request';

interface TypeItem {
    target: 'css' | 'imgUrl';
    key: string;
    value: string;
}
interface MsgType {
    type: 'SET_VALUE';
    data: TypeItem[];
}

enum ThemeProp {
    ThemeColor = '--theme-color',
    FontColor = '--theme-font-color',
}

function App() {
    const [themeData] = useRequest(getSaasTheme);
    const [, setBg] = useAtom(BgImgAtom);
    const [, setLogo] = useAtom(LogoImgAtom);
    function onMessage(e: MessageEvent<MsgType>) {
        if (e.data?.type === 'SET_VALUE') {
            e?.data?.data.forEach(item => {
                if (item.target === 'css') {
                    document.documentElement.style.setProperty(
                        item.key,
                        item.value,
                    );
                }
                if (item.target === 'imgUrl') {
                    if (item.key === '--theme-background-image') {
                        setBg(item.value);
                    }
                    if (item.key === '--theme-logo-image') {
                        setLogo(item.value);
                    }
                }
            });
        }
    }

    useEffect(() => {
        if (themeData) {
            setBg(themeData?.paramValue?.backgroundImage);
            setLogo(themeData?.paramValue?.subjectLogo);
            document.documentElement.style.setProperty(
                ThemeProp.ThemeColor,
                themeData?.paramValue?.themeColor,
            );
            document.documentElement.style.setProperty(
                ThemeProp.FontColor,
                themeData?.paramValue?.fontColor,
            );
        }
    }, [themeData]);

    useEffect(() => {
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, []);

    const router = useMemo(() => createBrowserRouter(MainMenu), [MainMenu]);

    return (
        <QueryClientProvider client={queryClient}>
            <RouterProvider
                router={router}
                fallbackElement={<PageLoading />}
                future={{v7_startTransition: true}}
            />
        </QueryClientProvider>
    );
}

export default App;
