import React, {useEffect} from 'react';
import Style from './index.module.scss';
import ShareImg from './img/share.png';
import LoveImg from './img/love.png';
import {Mask, MaskProps} from 'antd-mobile';
import wx from 'weixin-js-sdk';

interface Props extends MaskProps {
    data: {
        shareTitle: string;
        shareUrl: string;
        imageUrl?: string;
    };
    onShare: () => void;
}

const Index = ({onShare, data, ...props}: Props) => {
    useEffect(() => {
        wx?.miniProgram.postMessage({data});
    }, []);

    return (
        <Mask {...props} getContainer={() => document.body}>
            <div className={Style.content}>
                <img src={ShareImg} className={Style.arrow} alt="" />
                <div className={Style.box}>
                    <div className={Style.title}>
                        即刻发布朋友圈或分享好友！
                    </div>
                    <img src={LoveImg} className={Style.icon} alt="" />
                    <div className={Style.button} onClick={onShare}>
                        我知道了
                    </div>
                </div>
            </div>
        </Mask>
    );
};

export default Index;
