import React from 'react';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import {Grid} from 'antd-mobile';
import ArticleGrid from '../../../Components/ArticleGrid';
import {NewsListData} from '../service';

export const NewsListDataLoader = makeLoader(async e => await NewsListData(e));

export default function Index() {
    const data = useLoaderData<typeof NewsListDataLoader>();
    return (
        <Grid columns={1} gap={15}>
            {data.map(item => (
                <Grid.Item key={item.id}>
                    <ArticleGrid
                        href={`${item.id}`}
                        time={item.create_time}
                        title={item.title}
                        img={item.smallPic}
                    />
                </Grid.Item>
            ))}
        </Grid>
    );
}
