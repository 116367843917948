import {MainRequest} from '../../Utils/Request';
import dayjs from 'dayjs';
import lodash from 'lodash-es';
import {getLoginUser} from '../Auth/service';
import {getMenu, MenuType} from '../../Utils/help';

export interface PersonNumber {
    noticeNum: string; //新消息数
    organizationNum: string; //组织数
    sumPoint: string; //积分数
}

export function myStatistics() {
    return MainRequest.postForm<SuccessData<PersonNumber>>(
        `alumni/getNewPersonInfo`,
    ).then(res => res.data.data);
}

export async function UserData() {
    return Promise.all([
        myStatistics(),
        getLoginUser(),
        getMenu(MenuType.My),
        getCustomerMenu(),
    ]).then(res => {
        const [userStatisticsData, user, Menu, flag] = res;
        return {
            userStatisticsData,
            user,
            Menu,
            flag,
        };
    });
}

//个人信息
export interface UserInfoForm {
    sex: string; //性别
    birth: string; //生日
    email: string; //邮箱
    companyName: string; //工作单位
    companyOrg: string; // 单位部门
    companyAddress: string; //通讯地址
    companyPost: string; //职务
    industry: string; //所属行业
    weixinNum: string; //微信
    phone: string; //手机号
    name: string; //姓名
    pic: string; //头像
    workInfoFlag: BooleanValue; //工作信息是否公开
    emailInfoFlag: BooleanValue; //公开邮箱
    phoneInfoFlag: BooleanValue; //公开电话
    weixinInfoFlag: BooleanValue; //公开微信
}

export function getUserInfo() {
    return MainRequest.postForm<SuccessData<UserInfoForm>>(
        `alumni/getPersonByLoginUserId`,
    )
        .then(res => res.data.data)
        .then(res => {
            return {
                ...res,
                sex: [res.sex],
                industry: [res.industry],
                pic: [res.pic],
                birth: dayjs(res.birth).toDate(),
            };
        });
}

export function UserInfoData() {
    return getUserInfo();
}

export function saveUserInfo(data: UserInfoForm) {
    return MainRequest.postForm(`alumni/modifyPersonInfo`, {
        ...data,
        birth: dayjs(data.birth).format('YYYY-MM-DD'),
        sex: lodash.first(data.sex),
        industry: lodash.first(data.industry),
        pic: lodash.first(data.pic),
    });
}

export interface ChangePhone {
    phone: string;
    checkPhoneCode: string;
}
export function changePhone(data: ChangePhone) {
    return MainRequest.postForm(`alumni/editAlumniPersonPhone`, data);
}

/**
 * 获取租户订购菜单的显示flag
 */
export function getCustomerMenu() {
    return MainRequest.post<SuccessData<{IntegralFlag: BooleanValue}>>(
        'alumni/getCustomerMenu',
    ).then(res => res.data.data);
}
