import {atom, Getter} from 'jotai';
import {atomWithDefault} from 'jotai/utils';

export function atomWithRefresh<T>(fn: (get: Getter) => Promise<T>) {
    const innerStore = atomWithDefault<T>(fn);

    return atom(
        get => get(innerStore),
        (get, set) => {
            return fn(get).then(data => set(innerStore, data));
        },
    );
}
