import React from 'react';
import Style from './index.module.scss';
import useMainHeight from '../../../Utils/Hooks/useMainHeight';
import {NoticeData} from '../service';
import {makeLoader, useLoaderData} from 'react-router-typesafe';

export const NoticeDataLoader = makeLoader(async e => await NoticeData(e));
const Index = () => {
    const data = useLoaderData<typeof NoticeDataLoader>();
    const {mainHeight} = useMainHeight();
    return (
        <div className={Style.root} style={{height: mainHeight}}>
            <div className={Style.content}>
                <div className={Style.text}>
                    亲爱的 {data.content}
                    <p>
                        岁岁年年今朝在，日日月月乐常行。在这个特殊的日子里，母校祝您平安喜乐，万事胜意，生日快乐！愿时光悠缓，故人不散。
                    </p>
                    <p className={Style.right}>管理员</p>
                </div>
            </div>
        </div>
    );
};

export default Index;
