import React from 'react';
import Style from './index.module.scss';
import {PacmanLoader} from 'react-spinners';
import {getCssVar} from '../../Utils/help';

const Index = () => {
    const color = getCssVar('--theme-color');
    return (
        <div className={Style.root}>
            <PacmanLoader color={color} />
        </div>
    );
};

export default Index;
