import React from 'react';
import Style from './index.module.scss';
import {
    AppstoreOutline,
    HandPayCircleOutline,
    HeartOutline,
    MailOutline,
    MessageOutline,
    RightOutline,
    ShopbagOutline,
    StarOutline,
    UnorderedListOutline,
} from 'antd-mobile-icons';
import {Button, List} from 'antd-mobile';
import {Link} from 'react-router-dom';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import {UserData} from './service';
import {signOut} from '../../Public';
import {isEmpty} from 'lodash-es';
import {useNavTo} from '../../Utils/Hooks/useNavTo';
import IconRender from '../../Components/IconRender';
import {CardProvider, CardSmall} from '../../Components/Card';
import classNames from 'classnames';
import {isTrue} from '../../Utils/help';
import {useAtomValue} from 'jotai';
import {ServiceStore} from '../../Store/serviceStore';
export const UserDataLoader = makeLoader(UserData);

const Index = () => {
    const {user, Menu, flag} = useLoaderData<typeof UserDataLoader>();
    const service = useAtomValue(ServiceStore);

    const navigate = useNavTo();

    return (
        <div className={Style.root}>
            <div className={classNames(Style.pd, Style.head)}>
                <Link to="info" className={Style.infoRow}>
                    <div className={Style.left}>
                        <img src={user.pic} alt="" className={Style.img} />
                        <span className={Style.userName}>{user.name}</span>
                    </div>
                    <div className={Style.right}>
                        个人信息 <RightOutline />
                    </div>
                </Link>
            </div>

            <div className={Style.card}>
                <CardProvider>
                    <CardSmall />
                </CardProvider>
            </div>

            <div className={Style.main}>
                <List>
                    <List.Item
                        prefix={<MessageOutline />}
                        onClick={() => navigate('/notice')}>
                        我的消息
                    </List.Item>

                    {isTrue(flag.IntegralFlag) && (
                        <List.Item
                            prefix={<HandPayCircleOutline />}
                            onClick={() => {
                                window.location.href = '/webview/Point';
                            }}>
                            积分商城
                        </List.Item>
                    )}

                    {!isTrue(flag.IntegralFlag) && (
                        <List.Item
                            prefix={<HandPayCircleOutline />}
                            onClick={() => navigate('point')}>
                            我的积分
                        </List.Item>
                    )}

                    {service.has('alumni_lotterys_management') && (
                        <List.Item
                            prefix={<StarOutline />}
                            onClick={() => {
                                window.location.href = '/webview/Lottery';
                            }}>
                            我的抽奖
                        </List.Item>
                    )}

                    {service.has('alumni_alumni_enterprises') && (
                        <List.Item
                            prefix={<ShopbagOutline />}
                            onClick={() => {
                                window.location.href =
                                    '/webview/Enterprise/List';
                            }}>
                            企业入驻
                        </List.Item>
                    )}

                    {service.has('alumni_company_recruit_management') && (
                        <List.Item
                            prefix={<MailOutline />}
                            onClick={() => {
                                window.location.href =
                                    '/webview/Enterprise/MyRecruit';
                            }}>
                            已投简历
                        </List.Item>
                    )}

                    <List.Item
                        prefix={<UnorderedListOutline />}
                        onClick={() => navigate('activity')}>
                        我的活动
                    </List.Item>

                    <List.Item
                        prefix={<HeartOutline />}
                        onClick={() => navigate('collection')}>
                        我的收藏
                    </List.Item>

                    {Menu?.map(item => (
                        <List.Item
                            key={item.id}
                            prefix={
                                isEmpty(item?.h5Icon) ? (
                                    <AppstoreOutline />
                                ) : (
                                    <IconRender
                                        value={item.h5Icon}
                                        type={item.iconType}
                                    />
                                )
                            }
                            onClick={() => navigate(item.menuUrl)}>
                            {item.name}
                        </List.Item>
                    ))}
                </List>
            </div>

            <div className={Style.pd}>
                <Button
                    shape="rounded"
                    block
                    className={Style.btn}
                    onClick={signOut}>
                    退出登录
                </Button>
            </div>
        </div>
    );
};

export default Index;
