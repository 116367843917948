import React, {useContext} from 'react';
import styles from './index.module.scss';
import {CardSmallLocked} from '../CardSmallLocked';
import {goViewCard} from '../../utils';
import {CardContext} from '../../context';
import classNames from 'classnames';
import {useAtomValue} from 'jotai';
import {LogoImgAtom} from '../../../../Store/ImgAtom';

interface CardSmallProps {
    locked?: boolean;
    className?: string;
}

export function CardSmall({locked, className}: CardSmallProps) {
    const ctx = useContext(CardContext);
    const logo = useAtomValue(LogoImgAtom);

    const innerLocked = typeof locked === 'boolean' ? locked : !ctx.data;

    if (innerLocked) return <CardSmallLocked className={className} />;

    return (
        <div
            className={classNames(styles.root, className)}
            role="button"
            onClick={goViewCard}>
            <img className={styles.image} src={ctx.base?.backPic} alt="" />

            <div className={styles.logo}>
                <img src={logo} alt="" />
                <div className={styles.title}>校友卡</div>
            </div>

            <div className={styles.button}>查看</div>
        </div>
    );
}
