import {useWindowSize} from 'react-use';
import lodash from 'lodash-es';
import Config from '../../Config';
import {useMemo} from 'react';
import useRouteData from './useRouteData';

export default function useMainHeight(other?: number) {
    const {height} = useWindowSize();
    const {haveTab, haveTopNav} = useRouteData();

    const safeArea = document.querySelector('.adm-safe-area');

    const mainHeight = useMemo(() => {
        let mainHeight = height;
        if (haveTopNav) {
            mainHeight = mainHeight - Config.height.topNavHeight;
        }
        if (haveTab) {
            mainHeight = mainHeight - Config.height.barHeight;
        }
        if (other) {
            mainHeight = mainHeight - other;
        }

        return mainHeight;
    }, [haveTab, other, haveTopNav, lodash.isEmpty(safeArea)]);

    return {
        mainHeight,
    };
}
