import React from 'react';
import Style from './index.module.scss';
import {useLoaderData} from 'react-router-typesafe';
import {AlumniTypeDataLoader} from '../../Alumni';
import JoinCard from '../../Alumni/Components/JoinCard';
import {useNavigate} from 'react-router-dom';
import useMainHeight from '../../../Utils/Hooks/useMainHeight';

const Index = () => {
    const {joinData} = useLoaderData<typeof AlumniTypeDataLoader>();
    const navigate = useNavigate();
    function openDetail(id: string) {
        navigate(`/alumni/${id}`);
    }

    const {mainHeight} = useMainHeight();

    return (
        <div className={Style.join} style={{height: mainHeight}}>
            {joinData.map(item => (
                <JoinCard onClick={openDetail} data={item} key={item.id} />
            ))}
        </div>
    );
};

export default Index;
