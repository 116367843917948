import React from 'react';
import Style from './index.module.scss';
import {Button, Form, Popup, PopupProps, Toast} from 'antd-mobile';
import Title from '../../../../../Components/Title';
import {manualReview, ManualReviewProps, RegisterForm} from '../../../service';
import FormUpload from '../../../../../Components/Form/FormUpload';
import {FileTypeList} from '../../../../../Utils/Hooks/UpoadGenProps';
import useRequestFn from '../../../../../Utils/Hooks/useRequestFn';
import {errorMessage} from '../../../../../Utils/help';
import {useNavigate} from 'react-router-dom';
import {useAtomValue} from 'jotai';
import {QiniuUploadUrlAtom} from '../../../../../Store/commonStore';

interface Props extends PopupProps {
    data: RegisterForm;
}
const Index = ({data, ...props}: Props) => {
    const {uploadUrl} = useAtomValue(QiniuUploadUrlAtom);
    const [form] = Form.useForm<ManualReviewProps>();
    const navigate = useNavigate();
    const [doManual, {loading}] = useRequestFn(manualReview);
    async function onFinish(formData: ManualReviewProps) {
        try {
            await doManual({
                ...formData,
                ...data,
            });
            Toast.show('已经提交人工审核，请耐心等待结果');
            navigate(`/`, {replace: true}); // <-- redirect
        } catch (e: any) {
            Toast.show(errorMessage(e));
        }
    }

    return (
        <Popup bodyStyle={{width: '90vw'}} position="right" {...props}>
            <div className={Style.root}>
                <Title>人工审核</Title>
                <p className={Style.tips}>
                    您好，由于信息自动比对未成功，所以请上传以下材料，配合身份认证，谢谢！
                </p>
                <p className={Style.tips}>
                    若您是已毕业，可选择上传毕业证、学位证或同等效力证明材料内页照片。
                </p>
                <Form
                    form={form}
                    onFinish={onFinish}
                    mode="card"
                    style={{
                        '--border-inner': 'none',
                    }}>
                    <Form.Item label="毕业证书" name="proveInfo">
                        <FormUpload
                            canType={FileTypeList.pic}
                            multiple={false}
                            maxCount={1}
                            uploadUrl={uploadUrl}
                        />
                    </Form.Item>
                </Form>

                <Button
                    shape="rounded"
                    color="primary"
                    block
                    style={{marginTop: 50}}
                    loading={loading}
                    onClick={form.submit}>
                    提交人工审核
                </Button>
            </div>
        </Popup>
    );
};

export default Index;
