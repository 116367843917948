import React, {
    ForwardRefExoticComponent,
    RefAttributes,
    useRef,
    useState,
} from 'react';
import ReactDOM from 'react-dom';
import {ModalProps, PopupProps} from 'antd-mobile';

interface Options<T, P extends object> {
    type?: 'modal' | 'popup';
    component?:
        | ForwardRefExoticComponent<RefAttributes<T> & P>
        | React.ComponentType<P>;
}

export default function useModalHandler<T, P extends object>(
    options?: Options<T, P>,
) {
    const {type = 'modal'} = options || {};
    const ref = useRef<T>();
    const propsRef = useRef<P>();

    const [visible, setVisible] = useState(false);
    const [closed, setClosed] = useState(true);

    function close() {
        setVisible(false);
    }

    /**
     * 这里的async不能删除, 用来异步执行open操作
     * @param modalProps 这个参数设置为可选, 为了兼容旧模式
     */
    async function open(modalProps?: P) {
        propsRef.current = modalProps;

        ReactDOM.unstable_batchedUpdates(() => {
            setVisible(true);
            setClosed(false);
        });
    }

    function afterClose() {
        setClosed(true);
    }

    /**
     * render输出的组件会在关闭后自动清理
     * 注意render方法会接管组件的visible, afterClose, onCancel三个属性.
     * @param children
     */
    function render(children?: React.ReactElement) {
        const vnode =
            children || !options?.component
                ? children
                : React.createElement(options.component, propsRef.current);

        const handleCancel = () => {
            close();
            type === 'modal' && vnode?.props?.onCancel?.();
            type === 'popup' && vnode?.props?.onClose?.();
        };

        const handleAfterClose = (...args: any) => {
            afterClose();
            type === 'modal' && vnode?.props?.afterClose?.(...args);
            type === 'popup' && vnode?.props?.afterOpenChange?.(false);
        };

        // 适配modal属性
        const modalProps: ModalProps = {
            visible,
            afterClose: handleAfterClose,
            onClose: handleCancel,
            closeOnMaskClick: true,
            destroyOnClose: true,
            forceRender: true,
        };

        // 适配drawer属性
        const drawerProps: PopupProps = {
            visible,
            afterClose: handleAfterClose,
            onClose: handleCancel,
            closeOnMaskClick: true,
            destroyOnClose: true,
            forceRender: true,
        };

        if (!vnode) return null;

        const hasRef =
            options?.component &&
            isForwardRefExoticComponent(options.component);

        return (
            !closed &&
            React.cloneElement(vnode, {
                ...(type === 'modal' ? modalProps : drawerProps),
                ref: hasRef ? ref : undefined,
            })
        );
    }

    const props = {
        visible,
        afterClose,
        onCancel: close,
    };

    const modalRef = {
        visible,
        setVisible,
        close,
        open,
        closed,
        afterClose,
        render,
        props,
    };

    const proxyHandler: ProxyHandler<typeof modalRef & T> = {
        get: (target, prop, receiver) => {
            if (prop in modalRef) {
                return modalRef[prop as keyof typeof modalRef];
            } else {
                return ref.current?.[prop as keyof T];
            }
        },
    };

    return new Proxy<typeof modalRef & T>(modalRef as any, proxyHandler);
}

/**
 * 判断一个组件是不是ForwardRef组件
 * @param component
 */
export function isForwardRefExoticComponent(component: any) {
    return String(component.$$typeof) === 'Symbol(react.forward_ref)';
}
