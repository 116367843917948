import React from 'react';
import Style from './index.module.scss';
import useMainHeight from '../../../Utils/Hooks/useMainHeight';
import {NoticeData} from '../service';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import Title from '../../../Components/Title';
import RichText from '../../../Components/RichText';

export const NoticeDataLoader = makeLoader(async e => await NoticeData(e));
const Index = () => {
    const data = useLoaderData<typeof NoticeDataLoader>();
    const {mainHeight} = useMainHeight();
    return (
        <div className={Style.root} style={{height: mainHeight}}>
            <Title>{data.title}</Title>
            <RichText text={data.content} />
        </div>
    );
};

export default Index;
