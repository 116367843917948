import React from 'react';
import {Button, ButtonProps} from 'antd-mobile';
import useModalHandler from '../../../Utils/Hooks/useModalHandler';
import FixedShareModal from './Components/FixedShareModal';
import {isMini, isWechatBrowser} from '../../../Utils/help';

interface Props extends ButtonProps {
    text: string;
    shareText?: string;
    img: string;
    url?: string;
    children?: React.ReactNode;
}

export const Share = ({
    text,
    shareText = '分享给好友',
    img,
    url,
    children,
    ...props
}: Props) => {
    const shareData = {
        title: document.title,
        text,
        url: url || window.location.href,
    };

    const miniShareModal = useModalHandler({
        component: FixedShareModal,
    });

    function onShare() {
        if ('share' in navigator) {
            return onChromeShare();
        }

        miniShareModal.open({
            onShare: miniShareModal.close,
            data: {
                shareTitle: shareData.text,
                shareUrl: shareData.url,
                imageUrl: img,
            },
        });
    }

    async function onChromeShare() {
        if (!img) {
            return navigator.share(shareData);
        }

        const blob = await fetch(img).then(r => r.blob());
        await navigator.share({
            files: [
                new File([blob], 'file.png', {
                    type: blob.type,
                }),
            ],
            ...shareData,
        });
    }

    // 同时满足以下条件的, 不支持应用内分享功能
    // 1. 不支持 navigator.share
    // 2. 不在微信浏览器内
    // 3. 不在小程序内
    if (!window.navigator.share && !isWechatBrowser() && !isMini()) {
        return null;
    }

    if (children) {
        return <div onClick={onShare}>{children}</div>;
    }

    return (
        <Button
            size="small"
            shape="rounded"
            color="primary"
            onClick={onShare}
            {...props}>
            {shareText}

            {miniShareModal.render()}
        </Button>
    );
};

export default Share;
