import {getActiveHref} from '../../Activity/service';
import {useSearchParams} from 'react-router-dom';

export function GoActivity() {
    const [query] = useSearchParams();

    window.location.href = getActiveHref({
        id: query.get('id')!,
        type: query.get('type') as any,
    });

    return null;
}
