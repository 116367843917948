import {useLatest, useToggle} from 'react-use';
import {v4 as uuid} from 'uuid';
import lodash from 'lodash';
import axios from 'axios';
import {useState} from 'react';
import {
    FileProps,
    onUploadErrorProps,
    onUploadOkProps,
    onUploadProgressProps,
    UploadParams,
    UploadStatus,
} from './UpoadGenProps';
import {MainRequest} from '../Request';
import {Toast} from 'antd-mobile';

interface Props {
    data: Record<string, any>;
    uploadUrl: string;
    canType?: string[];
}

async function uploadFile(
    file: File,
    options: UploadParams,
    cancelToken: any,
    data: Record<string, any>,
) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('name', file.name);
    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });

    function onUploadProgress(progressEvent: any) {
        options?.onUploadProgress({
            file,
            index: options.index,
            percent: (progressEvent.loaded * 100) / progressEvent.total,
        });
    }

    return MainRequest.post(options.uploadUrl, formData, {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress,
        cancelToken,
    })
        .then(res => {
            const defaultRes = Array.isArray(res.data.data)
                ? res.data.data[0]
                : res.data.data;
            options.onOk({
                file,
                index: options.index,
                res: defaultRes ?? res.data,
            });
        })
        .catch(e => {
            if (e?.response?.data?.error) {
                Toast.show(e?.response?.data?.error);
            }
            options.onError({e, file, index: options.index});
        });
}

export default function useUploadGen({data, canType, uploadUrl}: Props) {
    const [fileList, setFileList] = useState<FileProps[]>([]);
    const [uploadLoading, toggleUploadLoading] = useToggle(false);
    const [cancel, setCancel] = useState<any>();
    let FileList = useLatest(fileList);

    function humanFileSize(bytes: number, dp = 0) {
        const thresh = 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (
            Math.round(Math.abs(bytes) * r) / r >= thresh &&
            u < units.length - 1
        );

        return bytes.toFixed(dp) + units[u];
    }

    function getExtName(file: string) {
        return file.split('.').pop();
    }

    function checkCourseWare(file: File, type: string[]) {
        const MaxFileSize = 1024 * 1024 * 100; // 附件大小限制500M
        const ext = getExtName(file.name);

        // 校验文件名
        if (/[\\|/:?*<>]/.test(file.name)) {
            throw new Error(
                file.name + '文件名不能包含以下符号\\|/:?*<>以及\\0',
            );
        }

        // 校验文件名长度
        if (file.name.length > 200) {
            throw new Error(file.name + '文件名不能超过200个字符');
        }

        // 校验文件类型
        if (!type.includes(ext?.toLowerCase() || '')) {
            throw new Error(file.name + `该文件格式不允许上传`);
        }

        if (file.size > MaxFileSize) {
            throw new Error(
                file.name +
                    `单个文件不能超过${humanFileSize(MaxFileSize)}, 上传失败`,
            );
        }
    }

    async function onFileChange(Files: File[], cb?: () => void) {
        toggleUploadLoading();
        setFileList(
            Array.from(Files).map(item => {
                return {
                    file: item,
                    uid: uuid(),
                    status: UploadStatus.Uploading,
                    percent: 0,
                };
            }),
        );

        function onUploadProgress({
            percent,
            file,
            index,
        }: onUploadProgressProps) {
            setFileList((fileList: FileProps[]) => {
                const _list_ = lodash.cloneDeep(fileList);
                _list_[index].percent = percent;
                _list_[index].status = UploadStatus.Uploading;
                return _list_;
            });
        }

        function onError({e, file, index}: onUploadErrorProps) {
            setFileList((fileList: FileProps[]) => {
                const _list_ = lodash.cloneDeep(fileList);
                _list_[index].percent = 100;
                _list_[index].status = UploadStatus.Error;
                _list_[index].error = e;
                return _list_;
            });
        }

        function onOk({file, index, res}: onUploadOkProps) {
            setFileList((fileList: FileProps[]) => {
                const _list_ = lodash.cloneDeep(fileList);
                _list_[index].percent = 100;
                _list_[index].status = UploadStatus.Done;
                _list_[index].response = res;
                return _list_;
            });
        }

        const cancelToken = new axios.CancelToken(cancel => {
            setCancel(() => cancel);
        });

        function* upload() {
            let index = 0;
            function setError(e: Error) {
                setFileList((fileList: FileProps[]) => {
                    const _list_ = lodash.cloneDeep(fileList);
                    _list_[index].percent = 0;
                    _list_[index].status = UploadStatus.Stop;
                    index++;
                    return _list_;
                });
            }

            async function send(opt: UploadParams) {
                try {
                    if (canType) {
                        await checkCourseWare(Files[index], canType);
                    }
                    const res = await uploadFile(
                        Files[index],
                        opt,
                        cancelToken,
                        data,
                    );
                    index++;
                    return res;
                } catch (e: any) {
                    setError(e);
                }
            }

            while (index < Files.length) {
                const opt = {
                    onUploadProgress,
                    onError,
                    index,
                    onOk,
                    uploadUrl,
                };
                yield send(opt);
            }

            return new Promise(resolve => resolve('ALL_FILE_OK'));
        }

        const u = upload();
        let gen = null;
        do {
            gen = u.next();
            const res = await gen.value;
            if (res === 'ALL_FILE_OK') {
                toggleUploadLoading();
                cb?.();
            }
        } while (!gen.done);
    }

    return {
        cancel,
        uploadLoading,
        fileList: FileList.current,
        onFileChange,
        setFileList,
    };
}
