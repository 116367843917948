import {MainRequest} from '../../../Utils/Request';

export enum collectType { //收藏类型
    News = 'xinwen',
}

export interface CollectionList {
    saveId: string; //收藏id
    parentId: number; //父级id
    activityId: number; //活动id
    mainPic: string; //缩略图
    name: string; //被收藏者创建人姓名
    createTime: string; //收藏时间
    type: collectType; //类型
    titleName: string; //标题名称
}

//我的收藏
export function getMyCollection() {
    return MainRequest.postForm<SuccessData<CollectionList[]>>(
        `alumni/getMyCollection`,
    ).then(res => res.data.data);
}

//新闻取消收藏
export function deleteCollectionNews(saveId: string) {
    return MainRequest.postForm(`alumni/newsReturnCollection`, {saveId});
}

export function CollectionListData() {
    return getMyCollection();
}
