import React from 'react';
import Style from './index.module.scss';
import SwiperArea from './Components/SwiperArea';
import PalaceItem from './Components/PalaceItem';
import ArticleTitle from '../../Components/ArticleTitle';
import {Grid} from 'antd-mobile';
import ArticleGrid from '../../Components/ArticleGrid';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import {HomeData} from './service';
import {NewsType} from '../News/service';
import classNames from 'classnames';
import {useAtomValue} from 'jotai';
import {BgImgAtom, LogoImgAtom} from '../../Store/ImgAtom';
import lodash from 'lodash-es';
import IconRender from '../../Components/IconRender';
import {useDict} from './useDict';
import {AdPlace} from './Components/AdPlace';
import {CardProvider, CardSmall} from '../../Components/Card';

export const HomeDataLoader = makeLoader(async () => await HomeData());

export default function Index() {
    const dict = useDict();
    const Logo = useAtomValue(LogoImgAtom);
    const BgImg = useAtomValue(BgImgAtom);
    const data = useLoaderData<typeof HomeDataLoader>();

    return (
        <div
            className={classNames(Style.root, {
                [Style.noBg]: lodash.isEmpty(BgImg),
            })}
            style={{backgroundImage: `url(${BgImg})`}}>
            <img src={Logo} alt="" className={Style.logo} />

            <SwiperArea />

            <div className={Style.Palace}>
                {data?.Menu?.map(item => (
                    <PalaceItem
                        key={item.id}
                        title={item.name}
                        href={item.menuUrl}
                        icon={
                            <IconRender
                                type={item.iconType}
                                value={item.h5Icon}
                            />
                        }
                    />
                ))}
            </div>

            <CardProvider>
                <CardSmall className={Style.card} />
            </CardProvider>

            <div className={Style.card}>
                <ArticleTitle
                    title={dict.NewsType.getName(NewsType.CampusDynamics)}
                    href={`/news/${NewsType.CampusDynamics}`}
                />
                <Grid columns={1} gap={15}>
                    {data[NewsType.CampusDynamics].map(item => (
                        <Grid.Item key={item.id}>
                            <ArticleGrid
                                href={`/news/${NewsType.CampusDynamics}/${item.id}`}
                                time={item.create_time}
                                title={item.title}
                                img={item.smallPic}
                            />
                        </Grid.Item>
                    ))}
                </Grid>
            </div>

            <div className={Style.card}>
                <ArticleTitle
                    title={dict.NewsType.getName(NewsType.AlumniInterviews)}
                    href={`/news/${NewsType.AlumniInterviews}`}
                />
                <Grid columns={1} gap={15}>
                    {data[NewsType.AlumniInterviews].map(item => (
                        <Grid.Item key={item.id}>
                            <ArticleGrid
                                href={`/news/${NewsType.AlumniInterviews}/${item.id}`}
                                time={item.create_time}
                                title={item.title}
                                img={item.smallPic}
                            />
                        </Grid.Item>
                    ))}
                </Grid>
            </div>

            <AdPlace data={data.Ad} />
            {/*隐藏备案号和技术支持信息*/}
            {/*已知去掉备案号可能导致后果*/}
            {/*执行人：李明哲 建议人：石经理 */}
            {/*“@兰海 兰经理，这个能隐藏么”*/}
            {/*领导说没事 2024-06-26 13:24:00 */}
            {/*{!isDemo && (*/}
            {/*    <Footer*/}
            {/*        content={*/}
            {/*            <div style={{textAlign: 'center'}}>*/}
            {/*                辽ICP备2024019112号-2 <br />*/}
            {/*                技术支持：大连东财文华教育科技有限公司*/}
            {/*            </div>*/}
            {/*        }*/}
            {/*    />*/}
            {/*)}*/}
        </div>
    );
}
