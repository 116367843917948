import React from 'react';
import Style from './index.module.scss';
import {Outlet, useParams} from 'react-router-dom';
import TopNav from '../../Components/Layout/TopNav';
import {useDict} from './useDict';

export default function Index() {
    const {type} = useParams();
    const dict = useDict();
    const title = dict.NewsType.getName(type) || '新闻详情';
    return (
        <TopNav text={title}>
            <div className={Style.root}>
                <Outlet />
            </div>
        </TopNav>
    );
}
