import React, {useEffect, useState} from 'react';
import {List, Loading, SearchBar, Image} from 'antd-mobile';
import {useNavigate, useParams} from 'react-router-dom';
import useRequestFn from '../../../../Utils/Hooks/useRequestFn';
import {getAlumniMember, getAlumniMemberProps} from '../../service';
import {AlumniPerson} from '../../../Find/service';
import Style from './index.module.scss';
import SkeletonLoading from '../../../../Components/SkeletonLoading';

const Index = () => {
    const navigate = useNavigate();
    const {alumniId} = useParams<{alumniId: string}>();
    const initialParams = {
        alumniId: alumniId!,
        pageNo: 1,
        pageSize: 75,
        allSelectValue: '',
    };

    const [params, setParams] = useState<getAlumniMemberProps>(initialParams);
    const [list, setList] = useState<AlumniPerson[]>([]);

    const [getData, {loading}] = useRequestFn(getAlumniMember);
    const getList = async (params: getAlumniMemberProps) => {
        setParams(params);
        const res = await getData(params);
        setList(data => [...data, ...res.list]);
    };

    const onKeywordChange = async (name: string) => {
        setList([]);
        await getList({
            ...initialParams,
            allSelectValue: name,
            pageNo: 1,
        });
    };

    useEffect(() => {
        getList(params);
    }, []);

    if (!list) {
        return <SkeletonLoading />;
    }

    return (
        <div className={Style.root}>
            <div className={Style.search}>
                <SearchBar
                    placeholder="请输入关键字搜索"
                    onSearch={onKeywordChange}
                />
            </div>
            {loading && (
                <div className={Style.loading}>
                    <Loading />
                </div>
            )}

            <List>
                {list.map(item => (
                    <List.Item
                        arrow
                        clickable
                        onClick={() => navigate(`/find/${item.userId}`)}
                        key={item.userId}
                        prefix={
                            <Image
                                src={item.pic}
                                style={{borderRadius: '100%'}}
                                fit="cover"
                                width={40}
                                height={40}
                            />
                        }
                        description={item.rankName || '会员'}>
                        {item.name}
                    </List.Item>
                ))}
            </List>
        </div>
    );
};

export default Index;
