import React from 'react';
import Styles from './index.module.scss';
import RichText from '../../../../Components/RichText';
import {useLoaderData} from 'react-router-typesafe';
import {AlumniDetailDataLoader} from '../index';

const Index = () => {
    const {aboutData} = useLoaderData<typeof AlumniDetailDataLoader>();
    return (
        <div className={Styles.root}>
            <RichText text={aboutData.info} />
        </div>
    );
};

export default Index;
