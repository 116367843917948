import React from 'react';
import Style from './index.module.scss';
import RichText from '../../../Components/RichText';
import FixedBottom from '../../../Components/Layout/FixedBottom';
import Like from '../../../Components/FunctionIcon/Like';
import lodash from 'lodash-es';
import {useParams} from 'react-router-dom';
import useRequest from '../../../Utils/Hooks/useRequest';
import {getNewsDetail} from '../service';
import Collection from '../../../Components/FunctionIcon/Collection';
import Spin from '../../../Components/Spin';
import useInfo from '../../../Utils/Hooks/useInfo';
import Share from '../../../Components/FunctionIcon/Share';
import SkeletonLoading from '../../../Components/SkeletonLoading';

export default function Index() {
    const {isLogin} = useInfo();
    const {id} = useParams<{id: string}>();
    const [data, {loading}, reload] = useRequest(() => getNewsDetail(id!));
    if (!data) {
        return <SkeletonLoading />;
    }
    return (
        <div className={Style.root}>
            <div className={Style.title}>{data.title}</div>
            <div className={Style.tips}>
                <span>{data.createTime}</span>
                <span>作者：{data.author}</span>
            </div>
            <div className={Style.content}>
                <RichText text={data.info} />
            </div>

            {isLogin && (
                <FixedBottom>
                    <Spin loading={loading}>
                        <div className={Style.bar}>
                            <div className={Style.barLeft}>
                                <>
                                    <Like
                                        isActive={!lodash.isEmpty(data.goodId)}
                                        number={data.goodNum}
                                        likeId={data.goodId}
                                        onSuccess={reload}
                                        targetId={data.id}
                                    />
                                    <Collection
                                        isActive={!lodash.isEmpty(data.saveId)}
                                        number={data.saveNum}
                                        likeId={data.saveId}
                                        onSuccess={reload}
                                        targetId={data.id}
                                    />
                                </>
                            </div>

                            <Share text={data.title} img={data.shareSmallPic} />
                        </div>
                    </Spin>
                </FixedBottom>
            )}
        </div>
    );
}
