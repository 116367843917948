import React, {useState} from 'react';
import Style from './index.module.scss';
import AuthContent from '../Components/AuthContent';
import {Button, Form, Input, Toast} from 'antd-mobile';
import {NavLink, useNavigate} from 'react-router-dom';
import {
    getUrls,
    loginByCode,
    loginByPassword,
    LoginFormByCode,
    LoginFormByPassword,
    LoginType,
} from '../service';
import {EyeInvisibleOutline, EyeOutline} from 'antd-mobile-icons';
import {useAtomValue} from 'jotai';
import {AgreeAtom} from '../store';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import classNames from 'classnames';
import SendMsg from '../../../Components/Form/SendMsg';
import useDemo from '../../../Utils/Hooks/useDemo';
import TopNav from '../../../Components/Layout/TopNav';
import {useSearchParam} from 'react-use';
import {errorMessage} from '../../../Utils/help';

const Index = () => {
    const targetUrl = useSearchParam('targetUrl');
    const [loginType, setLoginType] = useState(LoginType.Password);
    const [visible, setVisible] = useState(false);
    const agree = useAtomValue(AgreeAtom);
    const [form] = Form.useForm<LoginFormByCode>();
    const isDemo = useDemo();

    const [login, {loading}] = useRequestFn(loginByCode);
    const [loginPassword, {loading: passwordLoading}] =
        useRequestFn(loginByPassword);

    const navigate = useNavigate();
    const phone = Form.useWatch('phone', form);

    async function onFinishCode(data: LoginFormByCode) {
        if (!agree) {
            return Toast.show('请阅读并同意隐私条款及服务协议');
        }
        try {
            await login(data);
            loginAfter();
        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }

    async function onFinishPassword(data: LoginFormByPassword) {
        if (!agree) {
            return Toast.show('请阅读并同意隐私条款及服务协议');
        }
        try {
            await loginPassword(data);
            loginAfter();
        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }

    function loginAfter() {
        if (targetUrl) {
            const url = new URL(targetUrl);
            url.searchParams.append('token', localStorage.getItem('token')!);
            url.searchParams.append(
                'refreshToken',
                localStorage.getItem('refreshToken')!,
            );
            window.location.replace(url);
        } else {
            navigate(`/`, {replace: true}); // <-- redirect
        }
    }

    return (
        <TopNav text="登录" canBack={false}>
            <div className={classNames(Style.root, 'bgCircle')}>
                <AuthContent>
                    <div>
                        {loginType === LoginType.Code && (
                            <Form
                                layout="horizontal"
                                onFinish={onFinishCode}
                                form={form}
                                mode="card">
                                <Form.Item
                                    rules={[{required: true}]}
                                    label="手机号"
                                    name="phone">
                                    <Input placeholder="请输入手机号" />
                                </Form.Item>
                                {!isDemo && (
                                    <Form.Item
                                        rules={[{required: true}]}
                                        name="checkPhoneCode"
                                        label="验证码"
                                        extra={<SendMsg phone={phone} />}>
                                        <Input placeholder="请输入验证码" />
                                    </Form.Item>
                                )}
                                <Button
                                    color="primary"
                                    type="submit"
                                    block
                                    loading={loading}
                                    className={Style.submitBtn}>
                                    登录
                                </Button>
                            </Form>
                        )}

                        {loginType === LoginType.Password && (
                            <Form
                                layout="horizontal"
                                onFinish={onFinishPassword}
                                mode="card">
                                <Form.Item
                                    name="userName"
                                    rules={[{required: true}]}>
                                    <Input placeholder="请输入手机号或身份证号" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{required: true}]}
                                    extra={
                                        <div className={Style.extraPart}>
                                            {!visible ? (
                                                <EyeInvisibleOutline
                                                    onClick={() =>
                                                        setVisible(true)
                                                    }
                                                />
                                            ) : (
                                                <EyeOutline
                                                    onClick={() =>
                                                        setVisible(false)
                                                    }
                                                />
                                            )}
                                            <NavLink to={getUrls('/forget')}>
                                                忘记密码
                                            </NavLink>
                                        </div>
                                    }>
                                    <Input
                                        placeholder="请输入密码"
                                        type={visible ? 'text' : 'password'}
                                    />
                                </Form.Item>
                                <Button
                                    color="primary"
                                    type="submit"
                                    block
                                    loading={passwordLoading}
                                    className={Style.submitBtn}>
                                    登录
                                </Button>
                            </Form>
                        )}

                        <div className={Style.links}>
                            {loginType === LoginType.Code && (
                                <span
                                    onClick={() =>
                                        setLoginType(LoginType.Password)
                                    }>
                                    账号密码登录
                                </span>
                            )}
                            {loginType === LoginType.Password && (
                                <span
                                    onClick={() =>
                                        setLoginType(LoginType.Code)
                                    }>
                                    手机验证码登录
                                </span>
                            )}
                            <NavLink to={getUrls('/reg', targetUrl)}>
                                去注册
                            </NavLink>
                        </div>
                    </div>
                </AuthContent>
            </div>
        </TopNav>
    );
};

export default Index;
