import React from 'react';
import {TabBar} from 'antd-mobile';

import {useLocation} from 'react-use';
import Style from './index.module.scss';
import useRequest from '../../../Utils/Hooks/useRequest';
import {getMenu, MenuType} from '../../../Utils/help';
import IconRender from '../../IconRender';
import {AppOutline, UserSetOutline} from 'antd-mobile-icons';
import {useNavTo} from '../../../Utils/Hooks/useNavTo';

const Index = () => {
    const navigate = useNavTo();
    const location = useLocation();
    const {pathname} = location;

    const [Menu] = useRequest(() => getMenu(MenuType.Nav), {}, []);

    const setRouteActive = (value: string) => {
        navigate(value);
    };

    return (
        <div className={Style.root}>
            <TabBar
                activeKey={pathname}
                safeArea
                onChange={value => setRouteActive(value)}>
                <TabBar.Item icon={<AppOutline />} title="首页" key="/" />
                {Menu?.map(item => (
                    <TabBar.Item
                        key={item?.menuUrl}
                        icon={
                            <IconRender
                                type={item.iconType}
                                value={item.h5Icon}
                            />
                        }
                        title={item?.name}
                    />
                ))}
                <TabBar.Item icon={<UserSetOutline />} title="我的" key="/my" />
            </TabBar>
        </div>
    );
};

export default Index;
