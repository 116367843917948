import styles from './index.module.scss';
import {useAsync, useAsyncFn} from 'react-use';
import {getPersonByLoginUserId} from './service';
import {activateAlumniCard, getIndustryType} from './service';
import {useState} from 'react';
import {Select} from './components/Select';
import {Upload} from './components/Upload';
import {errorMessage} from '../../../Utils/help';
import {Input, List, Modal, NoticeBar} from 'antd-mobile';
import TopNav from '../../../Components/Layout/TopNav';

export function CardVerify() {
    const dict = useAsync(getIndustryType);

    const [error, setError] = useState('');

    // 所属行业
    const [industry, setIndustry] = useState('');
    // 单位部门
    const [companyOrg, setCompanyOrg] = useState('');
    // 单位名称
    const [companyName, setCompanyName] = useState('');
    // 证件照片
    const [cardPic, setCardPic] = useState('');
    // 电子邮箱
    const [email, setEmail] = useState('');

    useAsync(async () => {
        try {
            const userInfo = await getPersonByLoginUserId();

            setIndustry(userInfo.industry);
            setCompanyOrg(userInfo.companyOrg);
            setCompanyName(userInfo.companyName);
            setCardPic(userInfo.cardPic);
            setEmail(userInfo.email);
            if (userInfo.cardPicInfo) setError(userInfo.cardPicInfo);
        } catch (e) {
            Modal.alert({content: errorMessage(e)});
        }
    });

    const [{loading}, sendData] = useAsyncFn(async () => {
        if (loading) return;

        try {
            await activateAlumniCard({
                industry,
                companyOrg,
                companyName,
                cardPic,
                email,
            });

            Modal.alert({
                content: '激活成功!',
                onClose: () => {
                    window.history.back();
                },
            });
        } catch (e) {
            Modal.alert({content: errorMessage(e)});
            setError(errorMessage(e));
        }
    }, [industry, companyOrg, companyName, cardPic, email]);

    return (
        <TopNav text="校友卡">
            <div className={styles.root}>
                {error && <NoticeBar content={error} color="alert" closeable />}

                {cardPic && (
                    <div className={styles.photo}>
                        <img src={cardPic} alt="" />
                    </div>
                )}

                <div className={styles.form}>
                    <List mode="card">
                        <List.Item
                            title="传证件照"
                            description="照片要求：请上传正规一寸免冠证件照片。"
                            extra={
                                <Upload
                                    onChange={setCardPic}
                                    text={cardPic ? '更改图片' : '上传图片'}
                                />
                            }
                        />

                        <List.Item
                            title="工作单位"
                            extra={
                                <Input
                                    type="text"
                                    placeholder="请输入工作单位"
                                    value={companyName}
                                    onChange={(value: any) =>
                                        setCompanyName(value)
                                    }
                                    maxLength={50}
                                />
                            }
                        />

                        <List.Item
                            title="单位部门"
                            extra={
                                <Input
                                    type="text"
                                    placeholder="请填写"
                                    value={companyOrg}
                                    onChange={(value: any) =>
                                        setCompanyOrg(value)
                                    }
                                    maxLength={50}
                                />
                            }
                        />

                        <List.Item
                            title="所属行业"
                            extra={
                                <Select
                                    value={industry}
                                    onChange={val => setIndustry(val)}
                                    options={dict.value || []}
                                    style={{marginLeft: 'auto'}}
                                />
                            }
                        />

                        <List.Item
                            title="电子邮箱"
                            extra={
                                <Input
                                    type="text"
                                    placeholder="请填写"
                                    value={email}
                                    onChange={(value: any) => setEmail(value)}
                                    maxLength={50}
                                />
                            }
                        />
                    </List>

                    <button
                        className={styles.button}
                        onClick={sendData}
                        disabled={loading}>
                        激活校友卡
                    </button>
                </div>
            </div>
        </TopNav>
    );
}
