/**
 * https://github.com/validatorjs/validator.js
 */
import {RuleObject} from 'antd-mobile/es/components/form';
import {StoreValue} from 'rc-field-form/lib/interface';
import validator from 'validator';

const CustomRules = {
    // 数字, 校验长度
    Number: (min: number, max: number, message = '${label}格式不正确') => ({
        validator: (rule: RuleObject, value: StoreValue) => {
            if (!value) return Promise.resolve();
            if (new RegExp(`^\\d{${min},${max}}$`).test(value))
                return Promise.resolve();
            return Promise.reject();
        },
        message,
    }),
    // 字母数字
    NumberAlphabet: {
        validator: (rule: RuleObject, value: StoreValue) => {
            if (!value) return Promise.resolve();
            if (/^[a-zA-Z0-9]*$/.test(value)) return Promise.resolve();
            return Promise.reject();
        },
        message: '${label}格式不正确',
    },
    // 中文
    HanZi: {
        validator: (rule: RuleObject, value: StoreValue) => {
            if (!value) return Promise.resolve();
            if (/^[\u4e00-\u9fa5]*$/.test(value)) return Promise.resolve();
            return Promise.reject();
        },
        message: '${label}只能输入中文',
    },
    // 身份证号
    IdCard: {
        validator: (rule: RuleObject, value: StoreValue) => {
            if (!value) return Promise.resolve();
            if (validator.isIdentityCard(value, 'zh-CN' as any))
                return Promise.resolve();
            return Promise.reject();
        },
        message: '${label}格式不正确',
    },
    // 手机号
    MobilePhone: {
        validator: (rule: RuleObject, value: StoreValue) => {
            if (!value) return Promise.resolve();
            if (validator.isMobilePhone(value, 'zh-CN' as any))
                return Promise.resolve();
            return Promise.reject();
        },
        message: '${label}格式不正确',
    },
    // QQ号
    QQ: {
        validator: (rule: RuleObject, value: StoreValue) => {
            if (!value) return Promise.resolve();
            if (/^\d{5,10}$/.test(value)) return Promise.resolve();
            return Promise.reject();
        },
        message: '${label}格式不正确',
    },
    // 微信号
    WeChat: {
        validator: (rule: RuleObject, value: StoreValue) => {
            if (!value) return Promise.resolve();
            if (/^[a-zA-Z\d_-]{1,20}$/.test(value)) return Promise.resolve();
            return Promise.reject();
        },
        message: '${label}格式不正确',
    },
    Required: {
        required: true,
        message: '${label}不能为空',
    },
};

export default CustomRules;
