import React, {useMemo} from 'react';
import Style from './index.module.scss';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import {
    ActiveStatus,
    ActivityDetailData,
    ButtonType,
    cancelActivity,
} from '../service';
import {Button, Image, List, Tag, Toast} from 'antd-mobile';
import {useNavigate} from 'react-router-dom';
import Title from '../../../Components/Title';
import FixedBottom from '../../../Components/Layout/FixedBottom';
import Share from '../../../Components/FunctionIcon/Share';
import useModalHandler from '../../../Utils/Hooks/useModalHandler';
import JoinModal from './Components/JoinModal';
import SkeletonLoading from '../../../Components/SkeletonLoading';
import {errorMessage} from '../../../Utils/help';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import {useDict} from '../useDict';
export const ActiveDetailLoader = makeLoader(
    async e => await ActivityDetailData(e),
);

const Index = () => {
    const dict = useDict();
    const joinModal = useModalHandler({
        type: 'popup',
        component: JoinModal,
    });
    const navigate = useNavigate();
    const data = useLoaderData<typeof ActiveDetailLoader>();

    function onJoin() {
        joinModal.open({
            max: data.maxNum,
            id: data.id,
            onOk: () => {
                navigate('.', {replace: true});
                joinModal.close();
            },
        });
    }

    const [cancelSubmit, {loading: cancelLoading}] =
        useRequestFn(cancelActivity); //取消报名
    async function onCancel() {
        try {
            await cancelSubmit(data.id);
            navigate('.', {replace: true});
            Toast.show('已取消报名');
        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }

    const joinButton = useMemo(() => {
        let str = '';
        let event = () => {};
        let disabled = false;
        switch (data.btnType) {
            case ButtonType.CanJoin:
                str = '活动报名';
                event = onJoin;
                break;
            case ButtonType.CanCancel:
                str = '取消报名';
                event = onCancel;
                break;
        }
        if (data.flag !== ActiveStatus.Loading) {
            str = '活动报名';
            disabled = true;
        }
        return (
            <Button
                size="small"
                shape="rounded"
                color="primary"
                loading={cancelLoading}
                className={Style.applyButton}
                onClick={event}
                disabled={disabled}>
                {str}
            </Button>
        );
    }, [data.btnType, cancelLoading]);

    if (!data) {
        return <SkeletonLoading />;
    }

    return (
        <div className={Style.root}>
            <div className={Style.card}>
                <Image src={data.mainPic} className={Style.mainImg} />
                <div className={Style.name}>{data.titleName}</div>
                <Tag color="primary">
                    {dict.ActivityType.getName(data.type)}
                </Tag>
            </div>
            <div style={{marginBottom: 15}}>
                <div className={Style.pd}>
                    <Title>活动信息</Title>
                </div>
                <List className={Style.list}>
                    <List.Item extra={`${data.startTime}`}>
                        活动开始时间
                    </List.Item>
                    <List.Item extra={`${data.endTime}`}>
                        活动结束时间
                    </List.Item>
                    <List.Item extra={data.style}>举办形式</List.Item>
                    <List.Item extra={data.moneyStyle}>是否收费</List.Item>
                    <List.Item extra={data.joinAlumniName || '注册用户'}>
                        报名范围
                    </List.Item>
                    <List.Item extra={data.userName}>发起人</List.Item>
                    <List.Item extra={data.phone}>咨询电话</List.Item>
                    <List.Item
                        extra={data.alumniName}
                        arrow
                        onClick={() => navigate(`/alumni/${data.alumniId}`)}>
                        发起组织
                    </List.Item>
                </List>
            </div>
            <div className={Style.card}>
                <Title>活动详情</Title>
                <div className={Style.info}>{data.info}</div>
            </div>

            <FixedBottom>
                <div className={Style.bar}>
                    <div className={Style.barLeft}>
                        已报名人数:{data.joinNum} 人
                    </div>

                    <div className={Style.barRight}>
                        <Share
                            img={data.mainPic}
                            text={data.titleName}
                            shareText="分享"
                            color="default"
                        />
                        {joinButton}
                    </div>
                </div>
            </FixedBottom>

            {joinModal.render()}
        </div>
    );
};

export default Index;
