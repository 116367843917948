import {MainRequest} from '../../Utils/Request';
import ActivityImg from './img/activity.png';
import BirthdayImg from './img/birthday.png';
import SystemImg from './img/system.png';
import {LoaderFunctionArgs} from 'react-router-dom';
import {ActiveType} from '../Activity/service';

export enum MessageType {
    Message = '1',
    Activity = '2',
    Birthday = '3',
    Certification = '4',
    System = '5',
}

export interface NoticeList {
    id: string; //消息id
    type: MessageType; //消息类型(1.校友打招呼消息；2.活动消息;3.生日祝福;4.审核通知 5,系统通知)
    content: string; //消息内容(是活动类型时存的是活动id，审核通知类型时候存放征文ID或者摄影ID或者云祝福ID)
    flag: BooleanValue; //消息状态(0.未读、1.已读)
    title: string; //消息标题
    createTime: string; //创建时间
    sendId: string; //发送者userId(人员id)
    bmark: ActiveType; //大活动唯一标识
    checkRes: string; //消息类型为4；审核通知时候，显示审核状态；1审核通过；2审核不通过
    checkResActId: string; //消息类型为4的时候才有值，其他类型值为空，存放活动id
    icon: string;
    noticeType: string;
}

export interface GetNoticeProps extends PageProps {
    type?: string;
}
export function getNotice(params: GetNoticeProps) {
    //list页根据通知type获得全部通知标题
    return MainRequest.get<PagedData<NoticeList[]>>(`alumni/getNoticeByType`, {
        params,
    })
        .then(res => res.data.data)
        .then(res => {
            return {
                ...res,
                list: res.list.map(item => {
                    let icon = ActivityImg;
                    let noticeType = '';
                    switch (item.type) {
                        case MessageType.Activity:
                            icon = ActivityImg;
                            noticeType = '活动开启';
                            break;
                        case MessageType.Birthday:
                            icon = BirthdayImg;
                            noticeType = '生日祝福';
                            break;
                        case MessageType.System:
                            icon = SystemImg;
                            noticeType = '系统通知';
                            break;
                        case MessageType.Message:
                            icon = SystemImg;
                            noticeType = '打招呼';
                            break;
                        case MessageType.Certification:
                            icon = SystemImg;
                            noticeType = '审核通知';
                            break;
                    }
                    return {
                        ...item,
                        icon,
                        noticeType,
                    };
                }),
            };
        });
}

interface NoticeDataProp {
    id: string;
    title: string;
    content: string;
}

export function getNoticeDetail(id: string) {
    //Main页根据根据id获得通知详细(用来标记已读)
    return MainRequest.get<SuccessData<NoticeDataProp>>(
        `alumni/getNoticeById`,
        {
            params: {id},
        },
    ).then(res => res.data.data);
}

export async function NoticeData(e: LoaderFunctionArgs) {
    const id = e.params.id as any;

    return getNoticeDetail(id);
}
