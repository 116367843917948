import React from 'react';
import style from './index.module.scss';
import {Popup} from 'antd-mobile';

interface Props {
    isShow: boolean; //弹窗显示隐藏
    onClose: () => void; //点击关闭
}

export default function NoticeLayer({isShow, onClose}: Props) {
    return (
        <Popup visible={isShow}>
            <div className={style.content}>
                <img
                    className={style.img}
                    src="https://niualumni.dufe.edu.cn/weapp/assets/40e486553af93eaacaab61c985daa7fb-aaa.png"
                    alt=""
                />
                <div className={style.detail}>
                    <div>您暂时无法解锁该功能，</div>
                    <div>期待毕业后您的加入！</div>
                </div>
                <div className={style.button} onClick={() => onClose()}>
                    关闭
                </div>
            </div>
        </Popup>
    );
}
