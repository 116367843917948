import React from 'react';
import Styles from './index.module.scss';
import {CenterPopup, CenterPopupProps} from 'antd-mobile';

interface InstanceType {}

interface Props extends CenterPopupProps {
    src: string;
}

const Index = React.forwardRef<InstanceType | undefined, Props>(function (
    {src, ...extraProps},
    ref,
) {
    return (
        <CenterPopup
            {...extraProps}
            closeOnMaskClick
            showCloseButton
            maskClassName={Styles.rootMask}
            style={{
                '--max-width': '100vw',
                '--background-color': 'transparent',
            }}>
            <div className={Styles.box}>
                <img src={src} alt="" />

                <p className={Styles.tips}>请长按图片保存到相册</p>
            </div>
        </CenterPopup>
    );
});

export default Index;
