import {MainRequest} from '../../Utils/Request';
import {LoaderFunctionArgs} from 'react-router-dom';
import {checkLogin} from '../../Utils/help';
import {ActiveType} from '../Activity/service';

export enum NewsType {
    CampusDynamics = 'xiaoyuandongtai',
    AlumniInterviews = 'xiaoyoufangtan',
    Swiper = 'shouyelunbo',
    News = 'xinwenzhongxin',
}

export interface NewsListFromType {
    styleModel: string; //新闻模板：0普通；1校庆
    author: string; //作者
    id: number; //新闻ID
    smallPic: string; //缩略图
    type: string; //栏目
    title: string; //标题
    statusFlag: string; //发布状态
    create_time: string;
    officialUrl: string; // 公众号文章地址
}

//新闻
export async function getAllNewsListFromType(
    newsType?: string,
    alumniId?: string,
    dataSize?: number,
) {
    return MainRequest.postForm<SuccessData<NewsListFromType[]>>(
        `alumni/getAllNewsListFromType`,
        {newsType, alumniId, dataSize},
    ).then(res => res.data.data);
}

export interface MainPageAct {
    createOrg: string; //创建组织
    titleName: string; //标题名称
    createTime: string; //创建时间
    mainPic: string; //主页大图
    id: number; //活动id
    mainPagePic: string; //首页轮播图
    type: ActiveType; //活动类型
    callPhone: string; //云直播频道号
}

//活动
export function getMainPageAct() {
    return MainRequest.postForm<SuccessData<MainPageAct[]>>(
        `alumni/getMainPageAct`,
    ).then(res => res.data.data);
}

export function NewsListData(e: LoaderFunctionArgs) {
    const type = e.params.type as any;
    return getAllNewsListFromType(type, '', 50);
}

export interface AllNewsFrom {
    info: string; //内容
    author: string; //作者
    id: number; //新闻ID
    title: string; //标题
    statusFlag: string; //发布状态
    createTime: string; //发布时间
    saveNum: string; //收藏数量
    saveId: string; //收藏id（没收藏时为空）
    goodNum: string; //收藏数量
    goodId: string; //点赞id（没点赞时为空）
    shareSmallPic: string;
}

/**
 * 通过新闻ID获取新闻内容
 * @param newsId 新闻ID
 */
function getNewsNoTokenFromId(newsId: string) {
    return MainRequest.postForm<SuccessData<AllNewsFrom>>(
        `alumni/getNewsNoTokenFromId`,
        {newsId},
    ).then(res => res.data.data);
}

/**
 * 通过新闻ID获取新闻内容(不需要token未登录时用)
 * @param newsId 新闻ID
 */
function getAllNewsFromId(newsId: string) {
    return MainRequest.postForm<SuccessData<AllNewsFrom>>(
        `alumni/getAllNewsFromId`,
        {newsId},
    ).then(res => res.data.data);
}

export function getNewsDetail(newsId: string) {
    if (checkLogin()) {
        return getAllNewsFromId(newsId);
    } else {
        return getNewsNoTokenFromId(newsId);
    }
}
