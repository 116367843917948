/**
 * 读取字典
 */
import {Atom, useAtomValue} from 'jotai';
import {waitForAll} from 'jotai/utils';
import {useMemo} from 'react';

import _ from 'lodash-es';
import {
    dictStore,
    IndustryAtom,
    LearningStyleAtom,
    MenuMapAtom,
    TrainingStyleAtom,
} from '../../Store/commonStore';

export type DictInstance = ReturnType<typeof makeDict>;
type KeyType =
    | 'TrainingStyleAtom' //培养方式
    | 'LearningStyleAtom' //学习形式
    | 'Sex' //性别
    | 'NewsType' //新闻类型
    | 'ActivityType' //新闻类型
    | 'IndustryAtom' //所属行业
    | 'MenuMapAtom'; //全部菜单

type StoreType = {
    [key in KeyType]: Atom<DictInstance> | Atom<Promise<DictInstance>>;
};

export function makeDict<T extends LabelValue>(data: T[]) {
    const firstValue = data[0]?.value;

    function getName(key?: string | BooleanValue) {
        return data.find(item => String(item.value) === String(key))?.label;
    }

    return {data, getName, firstValue};
}

/**
 * 注意事项, 一定要在模块的首页把所有相关dict都一起引进来进行缓存
 * 避免进入页面时再加载数据
 * @param keys
 */
export function useDictAll<T extends KeyType>(keys: T[]) {
    const stores: StoreType = useMemo(
        () => ({
            TrainingStyleAtom,
            IndustryAtom,
            MenuMapAtom,
            LearningStyleAtom,
            Sex: dictStore({code: 'sex'}),
            NewsType: dictStore({code: 'xyh_news_type'}),
            ActivityType: dictStore({code: 'xyh_myActivity_type'}),
        }),
        [],
    );

    const allKeys = useMemo(() => Object.keys(stores), [stores]);

    if (_.difference(keys, allKeys).length !== 0)
        throw new Error(`不存在的字典key: ${_.difference(keys, allKeys)}`);

    const dictList = useAtomValue(waitForAll(keys.map(key => stores[key])));

    return useMemo(() => {
        const map: any = {};

        keys.forEach((key, index) => {
            map[key] = dictList[index];
        });

        return map as Record<T, DictInstance>;
    }, [keys, dictList]);
}
