import {MainRequest} from '../../../Utils/Request';

/**
 * 新闻点赞
 * @param newsId 新闻ID
 */
export function newsGood(newsId: number) {
    return MainRequest.postForm(`alumni/newsGood`, {
        newsId,
    }).then(res => res.data);
}

/**
 * 新闻取消点赞
 * @param goodId 点赞Id
 */
export function newsBad(goodId: string) {
    return MainRequest.postForm(`alumni/newsBad`, {
        goodId,
    }).then(res => res.data);
}
