import React, {useMemo} from 'react';
import Style from './index.module.scss';
import {Link, useNavigate} from 'react-router-dom';
import {NavBar} from 'antd-mobile';
import useRouteData from '../../../Utils/Hooks/useRouteData';
import {useDict} from './useDict';

interface Props {
    children: React.ReactNode;
    canBack?: boolean;
    text?: string;
}
const Index = ({children, text, canBack = true}: Props) => {
    const navigate = useNavigate();
    const {current, haveTab, haveTopNav} = useRouteData();
    const title = current?.title;
    const dict = useDict();
    const backArrow = useMemo(() => {
        if (canBack) {
            if (haveTab) {
                return false;
            }
            if (haveTopNav) {
                return true;
            }
            return true;
        } else {
            return false;
        }
    }, [haveTab, haveTopNav]);
    return (
        <div className={Style.root}>
            <NavBar
                className={Style.nav}
                onBack={() => navigate(-1)}
                right={
                    <Link to="/" style={{color: '#fff'}}>
                        回到首页
                    </Link>
                }
                backArrow={backArrow}>
                {text || dict?.MenuMapAtom?.getName(title) || title}
            </NavBar>
            {children}
        </div>
    );
};

export default Index;
