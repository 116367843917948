import React from 'react';
import {cancelAlumni, IAlumniData} from '../../service';
import {Card, Dialog, SwipeAction, Toast} from 'antd-mobile';
import {RightOutline} from 'antd-mobile-icons';
import Style from './index.module.scss';
import {errorMessage} from '../../../../Utils/help';
import useRequestFn from '../../../../Utils/Hooks/useRequestFn';
import {useNavigate} from 'react-router-dom';
import Spin from '../../../../Components/Spin';

interface Props {
    data: IAlumniData;
    onClick: (id: string) => void;
}
const Index = ({data, onClick}: Props) => {
    const navigate = useNavigate();
    const right = (
        <div className={Style.right}>
            <RightOutline />
        </div>
    );

    const [postData, {loading}] = useRequestFn(cancelAlumni);
    async function onCancel() {
        const confirm = await Dialog.confirm({
            content: '确定要退出此组织吗？',
        });
        if (confirm) {
            try {
                await postData(data.id);
                navigate('.', {replace: true});
                Toast.show('已退出组织');
            } catch (e) {
                Toast.show(errorMessage(e));
            }
        }
    }

    return (
        <Spin loading={loading}>
            <SwipeAction
                rightActions={[
                    {
                        key: 'delete',
                        text: '退出',
                        onClick: () => onCancel(),
                        color: 'danger',
                    },
                ]}>
                <Card
                    title={data.name}
                    extra={right}
                    onHeaderClick={() => onClick(data.id)}>
                    <div className={Style.content}>
                        <ul>
                            <li className={Style.row}>
                                <span className={Style.label}>联系人</span>:
                                {data.manager}
                            </li>
                            <li className={Style.row}>
                                <span className={Style.label}>电 话</span>:
                                {data.phone}
                            </li>
                            <li className={Style.row}>
                                <span className={Style.label}>邮 箱</span>:
                                {data.email}
                            </li>
                        </ul>
                    </div>
                </Card>
            </SwipeAction>
        </Spin>
    );
};

export default Index;
