import {MainRequest} from '../../../Utils/Request';
import html2canvas from 'html2canvas';

/**
 * 参加寻根大活动
 */
interface ShareNumber {
    joinCount: number;
    userId: string;
}
export function getShareNumber() {
    return MainRequest.get<SuccessData<ShareNumber>>(
        `alumni/joinSearchRoot`,
    ).then(data => data.data.data);
}

/**
 * 号召力排行榜
 */
interface RankList {
    userId: string;
    personSum: number;
    pic: string;
    name: string;
}

export function getRank() {
    return MainRequest.get<SuccessData<RankList[]>>(
        `alumni/getAppealRank`,
    ).then(data => data.data.data);
}

/**
 * 我的邀请
 */
interface Self extends RankList {
    createTime: string;
}
export function getMyShare() {
    return MainRequest.get<SuccessData<Self[]>>(`alumni/myInvitation`).then(
        data => data.data.data,
    );
}

export function getImgSrc(element: HTMLDivElement) {
    const width = element.offsetWidth;
    const height = element.offsetHeight;

    return html2canvas(element, {
        useCORS: true,
        width,
        height,
    }).then(function (canvas) {
        return canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');
    });
}

export async function ShareData() {
    return Promise.all([getShareNumber(), getRank(), getMyShare()]).then(
        res => {
            const [shareData, rankList, myShareList] = res;
            return {
                shareData,
                myShareList,
                rankList,
            };
        },
    );
}
