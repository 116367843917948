import {MainRequest} from '../../../Utils/Request';

export interface FindPointProps extends PageProps {}
export interface PointList {
    detailName: string;
    createTime: string;
    point: number;
}
export function getPoint(data: FindPointProps) {
    return MainRequest.postForm<PagedData<PointList[]>>(
        `alumni/getIntegralDetail`,
        data,
    ).then(res => res.data.data);
}
