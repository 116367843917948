import React, {useState} from 'react';
import {
    Button,
    List,
    Popup,
    PopupProps,
    SafeArea,
    Stepper,
    Toast,
} from 'antd-mobile';
import Title from '../../../../../Components/Title';
import Style from './index.module.scss';
import useRequestFn from '../../../../../Utils/Hooks/useRequestFn';
import {joinActivity} from '../../../service';
import {errorMessage} from '../../../../../Utils/help';

interface Props extends PopupProps {
    max: number;
    id: string;
    onOk: () => void;
}
const Index = ({max, id, onOk, ...props}: Props) => {
    const [postData, {loading}] = useRequestFn(joinActivity); //报名提交
    const [value, onChange] = useState(1);
    async function onJoin() {
        try {
            await postData(id, value);
            Toast.show('报名成功');
            onOk();
        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }
    return (
        <Popup {...props}>
            <div className={Style.root}>
                <Title>请选择报名人数</Title>
                <List
                    style={{
                        '--border-top': 'none',
                        '--border-bottom': 'none',
                        '--border-inner': 'none',
                        '--padding-left': '0',
                        '--padding-right': '0',
                    }}>
                    <List.Item
                        extra={
                            <Stepper
                                min={1}
                                max={max}
                                value={value}
                                onChange={onChange}
                            />
                        }>
                        报名人数
                    </List.Item>
                </List>
                <Button
                    block
                    color="primary"
                    shape="rounded"
                    loading={loading}
                    onClick={onJoin}
                    className={Style.btn}>
                    确定
                </Button>
                <SafeArea position="bottom" />
            </div>
        </Popup>
    );
};

export default Index;
