import React, {useRef, useState} from 'react';
import Styles from './index.module.scss';
import useRequest from '../../Utils/Hooks/useRequest';
import {
    getActiveHref,
    GetActivityList,
    getJoinActivity,
    LineStatus,
} from './service';
import {CheckList, Dropdown, DropdownRef, Grid, SearchBar} from 'antd-mobile';
import ArticleGrid from '../../Components/ArticleGrid';
import {StatusTag} from './Components/StatusTag';
import Config from '../../Config';
import Spin from '../../Components/Spin';
import SkeletonLoading from '../../Components/SkeletonLoading';

const Index = () => {
    const ref = useRef<DropdownRef>(null);
    const [params, setParams] = useState<GetActivityList>({
        name: '',
        style: LineStatus.All,
        type: '',
    });
    const [data, {loading}] = useRequest(
        () => {
            return getJoinActivity(params);
        },
        {},
        [params],
    );

    if (!data) {
        return <SkeletonLoading />;
    }
    return (
        <Spin loading={loading}>
            <div>
                <div className={Styles.searchBar}>
                    <SearchBar placeholder="请输入内容" />
                    <Dropdown ref={ref} className={Styles.right}>
                        <Dropdown.Item
                            key="sorter"
                            title={
                                params.style === LineStatus.All
                                    ? '全部形式'
                                    : params.style
                            }>
                            <CheckList
                                value={[params.style]}
                                onChange={e => {
                                    ref.current?.close();
                                    setParams({
                                        ...params,
                                        style:
                                            (e?.[0] as LineStatus) ||
                                            LineStatus.All,
                                    });
                                }}>
                                <CheckList.Item value={LineStatus.All}>
                                    全部形式
                                </CheckList.Item>
                                <CheckList.Item value={LineStatus.OnLine}>
                                    线上
                                </CheckList.Item>
                                <CheckList.Item value={LineStatus.OffLine}>
                                    线下
                                </CheckList.Item>
                            </CheckList>
                        </Dropdown.Item>
                    </Dropdown>
                </div>
                <div
                    className={Styles.main}
                    style={{
                        top: Config.height.topNavHeight,
                    }}>
                    <Grid columns={1} gap={15}>
                        {data.map(item => (
                            <Grid.Item key={item.id}>
                                <ArticleGrid
                                    imgTag={
                                        <StatusTag status={item.statusFlag} />
                                    }
                                    href={getActiveHref({
                                        id: item.id,
                                        type: item.bMark,
                                    })}
                                    time={item.createName}
                                    title={item.titleName}
                                    img={item.mainPic}
                                />
                            </Grid.Item>
                        ))}
                    </Grid>
                </div>
            </div>
        </Spin>
    );
};

export default Index;
