import React, {useRef, useState} from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Button} from 'antd-mobile';
import Style from './index.module.scss';
import {Swiper as SwiperClass} from 'swiper/types';
import SaveImgCard from './Components/SaveImgCard';
import {Navigation} from 'swiper';
import {getImgSrc} from '../service';
import useModalHandler from '../../../../Utils/Hooks/useModalHandler';
import {QRCodeSVG} from 'qrcode.react';
import {useLocation} from 'react-use';
import useUser from '../../../../Utils/Hooks/useUser';
import useModelSetting from '../../../../Utils/Hooks/useModelSetting';
import {AppModel, ModelItemCode} from '../../../../Public';

const Index = () => {
    const refs = useRef<(HTMLDivElement | null)[]>([]);
    const swiperRef = useRef(null);
    const {
        data: [temp],
    } = useModelSetting(AppModel.Share, [ModelItemCode.ShareTemp]);

    const [swiper, setSwiper] = useState<SwiperClass>();
    function Submit() {
        if (swiper) {
            const current = refs.current[swiper.activeIndex];
            if (current) {
                getImgSrc(current).then(res => {
                    saveImgModal.open({
                        src: res,
                    });
                });
            }
        }
    }

    const saveImgModal = useModalHandler({
        component: SaveImgCard,
    });

    const location = useLocation();
    const {origin} = location;

    const [user] = useUser();

    const shareUrl = `${origin}/reg?referrer=${user?.userId}`;

    return (
        <div className={Style.root}>
            <div className={Style.sbox}>
                <Swiper
                    ref={swiperRef}
                    slidesPerView="auto"
                    navigation
                    modules={[Navigation]}
                    onSwiper={swiper => setSwiper(swiper)}
                    centeredSlides>
                    {temp?.dictValue?.map((item, index) => (
                        <SwiperSlide className={Style.slide} key={item.id}>
                            <div
                                ref={el => (refs.current[index] = el)}
                                className={Style.box}>
                                <img
                                    className={Style.bgimg}
                                    src={item.label}
                                    alt=""
                                />
                                <div className={Style.qrBox}>
                                    <p>
                                        一键生成专属海报，转发给您身边的朋友，邀请朋友们通过扫描二维码注册吧。
                                    </p>
                                    <div className={Style.img}>
                                        <QRCodeSVG
                                            height="100%"
                                            value={shareUrl}
                                            width="100%"
                                        />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={Style.pd}>
                    <Button
                        className={Style.button}
                        onClick={Submit}
                        shape="rounded"
                        color="primary"
                        block>
                        生成毕业海报
                    </Button>
                </div>
            </div>

            {saveImgModal.render()}
        </div>
    );
};

export default Index;
