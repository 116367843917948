import React, {useMemo, useState} from 'react';
import * as Icon from 'antd-mobile-icons';
import {IconType} from '../../Utils/help';
import * as AntdIcons from '@ant-design/icons';
import Style from './index.module.scss';

interface Props {
    type: IconType;
    value: string;
    className?: string;
    onClick?: () => void;
}
const IconRender = ({type, value, className, onClick}: Props) => {
    const [svgContent, setSvgContent] = useState<string | null>(null);

    function getSvg(url: string) {
        fetch(url)
            .then(res => res.text())
            .then(data => {
                setSvgContent(data);
            });
    }

    if (type === IconType.WebSvg) {
        getSvg(value);
    }

    const IconNode = useMemo(() => {
        let Components = null;
        switch (type) {
            case IconType.WebSvg:
                return (
                    <div
                        className={Style.svgBox}
                        dangerouslySetInnerHTML={{__html: svgContent || ''}}
                    />
                );
            case IconType.WebImg:
                return <img src={value} alt="" />;
            case IconType.AntdMobile:
                Components = Icon?.[value as keyof typeof Icon];
                return Components ? <Components /> : '';
            case IconType.Antd:
                Components = AntdIcons?.[value as keyof typeof AntdIcons];
                // @ts-ignore
                return Components ? <Components /> : '';
            default:
                return '';
        }
    }, [type, svgContent, value]);

    return (
        <div className={className} onClick={onClick}>
            {IconNode}
        </div>
    );
};

export default IconRender;
