import React from 'react';
import Style from './index.module.scss';
import {Button, Form, Input, Toast} from 'antd-mobile';
import {NavLink, useNavigate} from 'react-router-dom';
import {
    EditPassword,
    forgetPassword,
    getUrls,
    LoginFormByCode,
} from '../service';
import classNames from 'classnames';
import SendMsg from '../../../Components/Form/SendMsg';
import TopNav from '../../../Components/Layout/TopNav';
import {useSearchParam} from 'react-use';
import {useMutation} from 'react-query';

const Index = () => {
    const targetUrl = useSearchParam('targetUrl');
    const [form] = Form.useForm<LoginFormByCode>();

    const doForget = useMutation({
        mutationFn: forgetPassword,
        onSuccess: () => {
            Toast.show('成功!');
            navigate(getUrls('/login'), {replace: true}); // <-- redirect
        },
    });

    const navigate = useNavigate();
    const phone = Form.useWatch('phone', form);

    function onFinish(data: EditPassword) {
        doForget.mutate(data);
    }

    return (
        <TopNav text="登录" canBack={false}>
            <div className={classNames(Style.root, 'bgCircle')}>
                <Form
                    layout="horizontal"
                    onFinish={onFinish}
                    form={form}
                    mode="card">
                    <Form.Item
                        rules={[{required: true}]}
                        label="手机号"
                        name="phone">
                        <Input placeholder="请输入手机号" />
                    </Form.Item>
                    <Form.Item
                        rules={[{required: true}]}
                        name="checkPhoneCode"
                        label="验证码"
                        extra={<SendMsg phone={phone} />}>
                        <Input placeholder="请输入验证码" />
                    </Form.Item>
                    <Form.Item
                        rules={[{required: true}]}
                        name="password"
                        label="密码">
                        <Input placeholder="请输入密码" />
                    </Form.Item>
                    <Button
                        color="primary"
                        type="submit"
                        block
                        loading={doForget.isLoading}
                        className={Style.submitBtn}>
                        修改密码
                    </Button>
                </Form>

                <div className={Style.links}>
                    <NavLink to={getUrls('/login', targetUrl)}>去登陆</NavLink>
                    <div />
                </div>
            </div>
        </TopNav>
    );
};

export default Index;
