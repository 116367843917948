import {MainRequest} from '../../../Utils/Request';

/**
 * 新闻收藏
 * @param newsId 新闻ID
 */
export function newsCollection(newsId: number) {
    return MainRequest.postForm(`alumni/newsCollection`, {newsId}).then(
        res => res.data,
    );
}

/**
 * 新闻取消收藏
 * @param saveId 收藏ID
 */
export function newsReturnCollection(saveId: string) {
    return MainRequest.postForm(`alumni/newsReturnCollection`, {saveId}).then(
        res => res.data,
    );
}
