import {MainRequest, NoLoginRequest} from '../../Utils/Request';
import dayjs from 'dayjs';
import lodash from 'lodash-es';

export enum LoginType {
    Code,
    Password,
}
export interface LoginFormByPassword {
    userName: string;
    password: string;
}
export interface LoginFormByCode {
    phone: string;
    checkPhoneCode: string;
}
export interface LoginRes {
    token: string;
    refreshToken: string;
}
export function loginByPassword(data: LoginFormByPassword) {
    return MainRequest.postForm<SuccessData<LoginRes>>('alumni/login', data)
        .then(res => res.data.data)
        .then(res => {
            const {token, refreshToken} = res;
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            return res;
        });
}
export function loginByCode(data: LoginFormByCode) {
    return MainRequest.postForm<SuccessData<LoginRes>>(
        'alumni/loginPhoneCheckCode ',
        data,
    )
        .then(res => res.data.data)
        .then(res => {
            const {token, refreshToken} = res;
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            return res;
        });
}

export interface UserInfo {
    userId: string; //人员userId
    cardId: string; //身份证
    name: string; //姓名
    pic: string; //头像
    guid: string; //人员guid
    birth: string; //生日
    sex: string; //性别(1.男；2.女)
    majorId: string; //专业id
    majorLabel: string; //专业名
    enterYear: string; //入学年
    email: string; //邮箱
    eduFlag: string; //学历
    eduLabel: string; //学历名称（新）
    oldCollege: string; //学院id
    oldCollegeLabel: string; //学院名
    endYear: string; //毕业年份
    phone: string; //手机号码
    loginName: string; //账号名称
    homeAddress: string; //家庭住址
    companyAddress: string; //单位地址
    companyName: string; //单位名称
    companyOrg: string; //单位部门
    companyPost: string; // 职务
    wxunionid: string; //微信号
    alumniNo: string; //校友号
    infoFlag: string; //通讯信息是否公开(0.否；1.是)（新）
    workInfoFlag: string; //工作信息是否公开(0.否；1.是)（新）
    classInfoFlag: string; //班级信息是否公开(0.否；1.是)（新）
    industry: string; //所属行业
    industryLabel: string; //所属行业名称（新）
    className: string; //班级code
    classLabel: string; //班级code名称（新）
    weixinNum: string; //微信号
    studyStyle: string; //学习形式（新）
    studyStyleLabel: string; //学习形式名称（新）
    studyId: string; //班级信息修改索引ID（新）
    customerId?: any; //
    customerCode?: any; //
    enrollmentFlag: string; //1：在校生 0：毕业生
    cardExpirationDate: string; // 校友卡有效期
    emailInfoFlag: string;
    phoneInfoFlag: string;
    weixinInfoFlag: string;
    checkCompanyFlag: string;
    checkCompanyState: string;
}

/**
 * 获取用户信息
 */
export function getLoginUser() {
    return NoLoginRequest.get<SuccessData<UserInfo>>(
        `alumni/getPersonByLoginUserId`,
    ).then(res => res.data.data);
}

export interface RegisterForm {
    majorName: string;
    referrer?: string; //邀请人id
    name: string;
    cardId: string;
    eduFlag?: string;
    enterYear?: string;
    endYear?: string;
    className?: number;
    oldCollege?: string; //学院code
    studyStyle?: string; //学习形式：0：全日制;1：非全日制
    phone?: string;
    password?: string;
    password2?: string;
}
export function register(data: RegisterForm) {
    return MainRequest.postForm(`alumni/checkAlumniSelfInfo`, {
        ...data,
        checkType: '1',
        enterYear: dayjs(data.enterYear).format('YYYY-MM'),
        endYear: dayjs(data.endYear).format('YYYY-MM'),
        studyStyle: lodash.first(data.studyStyle),
        eduFlag: lodash.first(data.eduFlag),
    });
}

export function editPersonInfo(data: RegisterForm) {
    return MainRequest.postForm<SuccessData<LoginRes>>(
        `alumni/editPersonInfo`,
        {
            ...data,
            checkType: '1',
            enterYear: dayjs(data.enterYear).format('YYYY-MM'),
            endYear: dayjs(data.endYear).format('YYYY-MM'),
            studyStyle: lodash.first(data.studyStyle),
            eduFlag: lodash.first(data.eduFlag),
        },
    )
        .then(res => res.data.data)
        .then(res => {
            const {token, refreshToken} = res;
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            return res;
        });
}

export interface ManualReviewProps extends RegisterForm {
    proveInfo: string[];
}
export function manualReview(data: ManualReviewProps) {
    return MainRequest.postForm<SuccessData<UserInfo>>(
        `alumni/checkFailAlumniUpload`,
        {
            ...data,
            checkType: '1',
            enterYear: dayjs(data.enterYear).format('YYYY-MM'),
            endYear: dayjs(data.endYear).format('YYYY-MM'),
            studyStyle: lodash.first(data.studyStyle),
            eduFlag: lodash.first(data.eduFlag),
            proveInfo: lodash.first(data.proveInfo),
        },
    );
}

export interface EditPassword {
    phone: string;
    password: string;
    checkPhoneCode: string;
}
export function forgetPassword(data: EditPassword) {
    return MainRequest.postForm(`alumni/forgetPassword`, data);
}

export function getUrls(path: string, targetUrl?: string | null) {
    if (targetUrl) {
        return `${path}?targetUrl=${targetUrl}`;
    } else {
        return path;
    }
}
