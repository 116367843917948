import React from 'react';
import Style from './index.module.scss';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import {saveUserInfo, UserInfoData, UserInfoForm} from '../service';
import {Button, Dialog, Form, Input, Toast} from 'antd-mobile';
import {errorMessage} from '../../../Utils/help';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import {useNavigate} from 'react-router-dom';
import {useDict} from '../useDict';
import SelectDictFormItem from '../../../Components/Form/SelectDictFormItem';
import SelectDate from '../../../Components/Form/SelectDate';
import {SimpleSwitch} from '../../../Components/Form/SimpleSwitch';
import FormUpload from '../../../Components/Form/FormUpload';
import {FileTypeList} from '../../../Utils/Hooks/UpoadGenProps';
import {useAtomValue} from 'jotai';
import {QiniuUploadUrlAtom} from '../../../Store/commonStore';
const InputProps = {
    placeholder: '请输入',
};
export const UserInfoDataLoader = makeLoader(async () => await UserInfoData());

export default function Index() {
    const dict = useDict();
    const data = useLoaderData<typeof UserInfoDataLoader>();
    const [form] = Form.useForm<UserInfoForm>();
    const navigate = useNavigate();
    const {uploadUrl} = useAtomValue(QiniuUploadUrlAtom);

    const [saveAction, {loading}] = useRequestFn(saveUserInfo);
    async function onFinish(data: UserInfoForm) {
        try {
            await saveAction(data);
            Toast.show('保存成功');
            navigate(`.`, {replace: true}); // <-- redirect
        } catch (e: any) {
            Toast.show(errorMessage(e));
        }
    }

    async function editPhone() {
        if (form.isFieldsTouched()) {
            const confirm = await Dialog.confirm({
                confirmText: '仍要跳转',
                content:
                    '你的操作还没有提交，请先保存,如果继续跳转，未提交的数据将不会保存',
            });
            if (!confirm) {
                return;
            }
        }
        navigate('/my/changePhone');
    }

    return (
        <div className={Style.root}>
            <Form
                layout="horizontal"
                onFinish={onFinish}
                initialValues={data}
                form={form}>
                <Form.Item label="头像" name="pic">
                    <FormUpload
                        canType={FileTypeList.pic}
                        multiple={false}
                        maxCount={1}
                        uploadUrl={uploadUrl}
                    />
                </Form.Item>
                <Form.Item name="name" label="姓名">
                    <Input {...InputProps} disabled />
                </Form.Item>
                <SelectDictFormItem
                    name="sex"
                    label="性别"
                    options={dict.Sex.data}
                />
                <SelectDate name="birth" label="生日" />
                <Form.Item name="emailInfoFlag" label="公开邮箱">
                    <SimpleSwitch />
                </Form.Item>
                <Form.Item name="email" label="邮箱地址">
                    <Input {...InputProps} />
                </Form.Item>
                <Form.Item name="phoneInfoFlag" label="公开手机">
                    <SimpleSwitch />
                </Form.Item>
                <Form.Item label="手机号" onClick={editPhone}>
                    <span className={Style.phone}>{data.phone}</span>
                </Form.Item>
                <Form.Item name="weixinInfoFlag" label="公开微信">
                    <SimpleSwitch />
                </Form.Item>
                <Form.Item name="weixinNum" label="微信号">
                    <Input {...InputProps} />
                </Form.Item>
                <Form.Item name="workInfoFlag" label="公开工作单位">
                    <SimpleSwitch />
                </Form.Item>
                <Form.Item name="companyName" label="工作单位">
                    <Input {...InputProps} />
                </Form.Item>
                <Form.Item name="companyOrg" label="部门">
                    <Input {...InputProps} />
                </Form.Item>
                <Form.Item name="companyPost" label="职务">
                    <Input {...InputProps} />
                </Form.Item>
                <Form.Item name="companyAddress" label="通讯地址">
                    <Input {...InputProps} />
                </Form.Item>
                <SelectDictFormItem
                    name="industry"
                    label="所属行业"
                    options={dict.IndustryAtom.data}
                />
            </Form>

            <div className={Style.pd}>
                <Button
                    color="primary"
                    shape="rounded"
                    block
                    className={Style.btn}
                    onClick={form.submit}
                    loading={loading}>
                    保存
                </Button>
            </div>
        </div>
    );
}
