import {MainRequest} from '../../../Utils/Request';
import dayjs from 'dayjs';
import {ActiveStatus, ActiveType, LineStatus} from '../../Activity/service';

export interface MyActivityList {
    startTime: string; //开始时间
    endTime: string; //结束时间
    name: string; //执年返校名称
    pic: string; //封面
    type: ActiveType; //类型(zhinianfanxiaoyes为执年返校，zhengwenhuodong为征文活动，sheyinghuodong为摄影活动)
    perStatusFlag: LineStatus; //状态
    activityId: string; //活动id（仅在type为征文活动和摄影活动时有）
    statusFlag: ActiveStatus; //活动状态(0.未开始，1.进行中，2.已结束)
    personName: string; //发起人
    maType: string; //自发活动类型
    id: string;
    content: string;
}

/**
 * 参与的活动列表
 */
export function getJoinActivity() {
    return MainRequest.postForm<SuccessData<MyActivityList[]>>(
        `alumni/getNewMyActivity`,
    )
        .then(res => res.data.data)
        .then(res => {
            return res.map(item => {
                return {
                    ...item,
                    startTime: dayjs(item.startTime).format('YYYYMM/DD'),
                    endTime: dayjs(item.endTime).format('YYYY/MM/DD'),
                };
            });
        });
}

/**
 * 发起的活动列表
 */
export function getLaunchActivity() {
    return MainRequest.postForm<SuccessData<MyActivityList[]>>(
        `alumni/getMyLaunchActivity`,
    )
        .then(res => res.data.data)
        .then(res => {
            return res.map(item => {
                return {
                    ...item,
                    startTime: dayjs(item.startTime).format('YYYY/MM/DD'),
                    endTime: dayjs(item.endTime).format('YYYY/MM/DD'),
                };
            });
        });
}

export async function MyActivityData() {
    return Promise.all([getLaunchActivity(), getJoinActivity()]).then(res => {
        const [launchData, joinData] = res;
        return {
            launchData,
            joinData,
        };
    });
}
