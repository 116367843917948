import React from 'react';
import Style from './index.module.scss';
import {Link} from 'react-router-dom';

interface Props {
    title: string;
    href: string;
    icon?: React.ReactNode;
}
const Index = ({title, href, icon}: Props) => {
    return (
        <Link to={href}>
            <dl className={Style.root}>
                <dt className={Style.icon}>{icon}</dt>
                <dd className={Style.text}>{title}</dd>
            </dl>
        </Link>
    );
};

export default Index;
