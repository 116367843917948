import {MainRequest} from '../../Utils/Request';
import {LoaderFunctionArgs} from 'react-router-dom';
import dayjs from 'dayjs';

export enum ActiveStatus { //活动状态
    NoStart = '0', //未开始
    Loading = '1', //进行中
    End = '2', //已结束
    Reject = '3', //未通过
}

export enum LineStatus { //线上线下类型
    All = '',
    OnLine = '线上',
    OffLine = '线下',
}

export enum ActiveType { //活动类型
    Return = 'zhinianfanxiaoyes',
    Share = 'xungenyes',
    Spontaneous = 'zifahuodong',
    Photograph = 'sheyinghuodong',
    Paper = 'zhengwenhuodong',
    AnnualRank = 'niandupingxuan',
    GroupPhoto = 'yunheying',
    Avatar = 'yuntouxiang',
    Blessing = 'yunzhufu',
    CloudReport = 'yunbaodao',
    LightWorld = 'dianliangquanqiu',
    Enterprise = 'xiaoyouqiye',
    News = 'xinwen',
}

export const ActiveRouter = {
    [ActiveType.Spontaneous]: '/activity/',
    [ActiveType.Return]: '/return/',
    [ActiveType.Share]: '/share/',
    [ActiveType.Photograph]: `/webview/Photography`,
    [ActiveType.Paper]: `/webview/CallForPapers`,
    [ActiveType.AnnualRank]: `/webview/AnnualSelection`,
    [ActiveType.GroupPhoto]: `/webview/CloudGroupPhoto`,
    [ActiveType.Avatar]: `/webview/CloudAvatar`,
    [ActiveType.Blessing]: `/webview/CloudBless`,
    [ActiveType.CloudReport]: `/webview/CloudReport`,
    [ActiveType.LightWorld]: `/webview/LightWorld`,
    [ActiveType.Enterprise]: `/webview/Enterprise`,
    [ActiveType.News]: `/news/xinwen/`,
};

function getWebviewUrl(path: string, params?: Record<string, any>) {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    const url = new URL(
        path,
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_WEB_URL!
            : window.location.origin,
    );
    url.searchParams.append('token', token!);
    url.searchParams.append('refreshToken', refreshToken!);
    if (params) {
        for (const paramsKey in params) {
            url.searchParams.append(paramsKey, params[paramsKey]);
        }
    }
    return url.href;
}

export function getActiveHref(item: {type: ActiveType; id: string | number}) {
    const pathName = ActiveRouter[item.type];

    if (
        [
            ActiveType.Photograph,
            ActiveType.Paper,
            ActiveType.AnnualRank,
            ActiveType.GroupPhoto,
            ActiveType.Avatar,
            ActiveType.Blessing,
            ActiveType.CloudReport,
            ActiveType.LightWorld,
        ].includes(item.type)
    ) {
        return getWebviewUrl(pathName, {
            activeId: item.id,
        });
    }

    // 校友企业
    if (item.type === ActiveType.Enterprise) {
        return getWebviewUrl(pathName + '/Detail', {id: item.id});
    }

    if ([ActiveType.Return, ActiveType.Share].includes(item.type)) {
        return pathName;
    }

    if (item.type === ActiveType.News) {
        return `/news/${item.type}/${(item as any).activityId}`;
    }

    return `${pathName}${item.id}`;
}

export interface AllActivity {
    titleName: string; //标题名词
    mainPic: string; //缩略图
    id: string; //活动id
    createName: string; //创建组织
    statusFlag: ActiveStatus; //状态（0.未开始，1.进行中，2.已结束）
    bMark: ActiveType; //活动标识(zifahuodong为校友活动)
    callPhone: string; //云直播，直播频道id
}

/**
 * 参与的活动列表
 */
export interface GetActivityList {
    style: LineStatus;
    type: string;
    name: string;
}
export function getJoinActivity(data: GetActivityList) {
    return MainRequest.postForm<SuccessData<AllActivity[]>>(
        `alumni/getAllActivity`,
        data,
    ).then(res => res.data.data);
}

//按钮状态
//1：活动报名；2：现场签到；3：取消报名；4：已签到
export enum ButtonType {
    CanJoin = '1',
    Sign = '2',
    CanCancel = '3',
    Signed = '4',
}
export function showNewActivityButton(id: string) {
    return MainRequest.postForm<SuccessData<{resFlag: ButtonType}>>(
        `alumni/showNewActivityButton`,
        {id},
    ).then(res => res.data.data.resFlag);
}

export interface NewActInfo {
    flag: ActiveStatus; //活动状态(0.待审核，1.进行中，2.已结束，3.审核不通过)
    joinAlumniId: string; //活动范围id
    joinAlumniName: string; //活动范围名
    joinNum: number; //已报名人数
    type: string; //活动类型
    maxNum: number; //最大人数
    joinStartTime: string; //报名开始时间
    everyJoinNum: number; //每人限报人数
    titleName: string; //活动主题
    alumniId: string; //所属组织id
    alumniName: string; //所属组织名称
    phone: string; //咨询电话
    joinEndTime: string; //报名结束时间
    mainPic: string; //活动主题图
    style: string; //活动形式
    startTime: string; //开始时间
    id: string; //活动id
    endTime: string; //结束时间
    moneyStyle: string; //支付方式
    info: string; //活动内容
    userName: string; //发起人
    btnType: ButtonType; //活动情况
}

export async function getNewActInfo(id: string) {
    const btnType = await showNewActivityButton(id);
    return await MainRequest.postForm<SuccessData<NewActInfo>>(
        `alumni/getNewActInfo`,
        {id},
    )
        .then(res => res.data.data)
        .then(res => {
            return {
                ...res,
                startTime: dayjs(res.startTime).format('YYYY-MM-DD HH:mm'),
                endTime: dayjs(res.endTime).format('YYYY-MM-DD HH:mm'),
                btnType,
            };
        });
}

export function ActivityDetailData(e: LoaderFunctionArgs) {
    const id = e.params.id as any;
    return getNewActInfo(id);
}

//报名
export function joinActivity(id: string, joinNum: number) {
    return MainRequest.postForm(`alumni/JoinNewActivity`, {
        id,
        joinNum,
    });
}

//取消报名
export function cancelActivity(id: string) {
    return MainRequest.postForm(`alumni/deleteNewActivity`, {
        id,
    });
}
