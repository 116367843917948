import {MainRequest, NoLoginRequest} from '../Utils/Request';
import {ModelItem} from '../Store/commonStore';
import {checkLogin, DictOpenType} from '../Utils/help';

//发送验证码
export function sendPhoneMsg(phone: string) {
    return MainRequest.postForm(`alumni/sendCheckCodeMessage`, {phone});
}
/**
 * 获取数据字典
 * @param dictTypeId
 */
export function getDict(dictTypeId: string) {
    return MainRequest.postForm<DictData<ModelItem[]>>(
        `/alumniJeeplus/getDictListByTypeId`,
        {
            dictTypeId,
        },
    ).then(res => res.data.data[0].dataList);
}

//培养方式
export function TrainingStyle() {
    return MainRequest.postForm<SuccessData<LabelCode[]>>(
        `alumni/getEducationFlag`,
    ).then(res => res.data.data);
}
//学习形式
export function LearningStyleStyle() {
    return MainRequest.postForm<SuccessData<LabelCode[]>>(
        `alumni/getStudyStyleType`,
    ).then(res => res.data.data);
}
//学习形式
export function IndustryDict() {
    return MainRequest.postForm<SuccessData<LabelCode[]>>(
        `alumni/getIndustryType`,
    ).then(res => res.data.data);
}
//获取主题
export interface ThemeProps {
    createTime: string;
    themeId: string;
    updateTime: string;
    id: number;
    paramValue: {
        themeColor: string;
        backgroundImage: string;
        fontColor: string;
        subjectLogo: string;
    };
}
export function getSaasTheme() {
    return MainRequest.postForm<SuccessData<ThemeProps>>(
        `serviceInfo/getSaasTheme`,
    ).then(res => res.data.data);
}
//获取登录信息
export interface LoginSetting {
    agreementContent: string; // 协议内容
    privacyPolicyContent: string; // 隐私内容
    privacyPolicy: DictOpenType; //隐私开关
    serviceAgreement: DictOpenType; //服务协议开关
}
export function getSaasLoginSetting() {
    return MainRequest.postForm<SuccessData<LoginSetting>>(
        `serviceInfo/getSaasLoginSetting`,
    ).then(res => res.data.data);
}

//获取七牛信息
export interface QiniuInfo {
    bucket: string;
    uploadUrl: string;
}
export function getSaasQiniuInfo() {
    return MainRequest.postForm<SuccessData<QiniuInfo>>(
        `serviceInfo/getSaasQiniuInfo`,
    ).then(res => res.data.data);
}

//获取各个模块信息
export enum AppModel {
    Alumni = 'alumni_association_management',
    Association = 'alumni_association_management',
    Return = 'alumni_zhinian_returns_to_school',
    News = 'alumni_news_center',
    Share = 'alumni_root_seeking_alma_mater',
}
export enum ModelItemCode {
    ReturnBg = 'dd48fbead02d4748a675847ce16e05ec',
    ReturnAbout = '69cd9969db404625b13276cb7bcca42f',
    ReturnPhone = '2a9674b627df48beb4b2d9592ceaa1e7',
    ShareBg = 'a2c99a70bcf241a2a467464d6384351e',
    ShareAbout = 'd096b92406d845588485eff0714de116',
    ShareTemp = '3def157996f343fcacffe96f5f5da826',
}
export function getModelSetting(code: ModelItemCode) {}

export enum ModelDictStatus {
    Disabled = '0',
    Enable = '1',
}
export interface ModelDictList {
    status: ModelDictStatus;
    id: string;
    value: string;
    name?: string;
    label: string;
    sort: number;
}

export interface AppModelInfo {
    name: string; //名称
    datasource: string; //数据源，字典值时记录字典type
    value: string; //非字典时的值
    url?: string;
    img?: string;
    code: ModelItemCode;
    status?: BooleanValue;
    dictValue: ModelDictList[];
}
export function getAppSetting(code: AppModel) {
    return MainRequest.postForm<SuccessData<AppModelInfo[]>>(
        `serviceInfo/serviceDetailByCode`,
        {code},
    ).then(res => res.data.data);
}

export interface AppMenu {
    serviceCode: string;
    name: string;
}
export function getServiceMenu() {
    return MainRequest.postForm<SuccessData<AppMenu[]>>(
        `serviceInfo/getAllServiceMenuList`,
    ).then(res => res.data.data);
}

export function getUploadToken() {
    let str = '';
    if (checkLogin()) {
        str = '/upload/getQiniuToken';
    } else {
        str = '/upload/getQiniuToken';
    }
    return MainRequest.postForm<SuccessData<{domain: string; token: string}>>(
        str,
    ).then(res => res.data.data);
}

export function signOut() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
}

interface UploadQiNiu {
    key: string;
    message: string; //报错信息或者图片信息
}

export async function uploadToQiNiu(file: File, token: string) {
    const headers = {'Content-Type': 'multipart/Form-data'};
    const formData = new FormData();
    formData.append('file', file);
    formData.append('token', token);
    const uploadSetting = await getSaasQiniuInfo();

    return MainRequest.post<UploadQiNiu>(uploadSetting.uploadUrl, formData, {
        headers,
    }).then(res => res.data);
}

export async function uploadFile(file: File) {
    const token = await getUploadToken();
    const data = await uploadToQiNiu(file, token.token);
    const url = `${token.domain}/${data.key}`;
    return {...data, url};
}

export interface UserInfo {
    userId: string; //人员userId
    cardId: string; //身份证
    name: string; //姓名
    pic: string; //头像
    guid: string; //人员guid
    birth: string; //生日
    sex: string; //性别(1.男；2.女)
    majorId: string; //专业id
    majorLabel: string; //专业名
    enterYear: string; //入学年
    email: string; //邮箱
    eduFlag: string; //学历
    eduLabel: string; //学历名称（新）
    oldCollege: string; //学院id
    oldCollegeLabel: string; //学院名
    endYear: string; //毕业年份
    phone: string; //手机号码
    loginName: string; //账号名称
    homeAddress: string; //家庭住址
    companyAddress: string; //单位地址
    expertWay: string; //擅长领域
    companyName: string; //单位名称
    companyOrg: string; //单位部门
    companyPost: string; // 职务
    wxunionid: string; //微信号
    alumniNo: string; //校友号
    infoFlag: string; //通讯信息是否公开(0.否；1.是)（新）
    workInfoFlag: string; //工作信息是否公开(0.否；1.是)（新）
    classInfoFlag: string; //班级信息是否公开(0.否；1.是)（新）
    industry: string; //所属行业
    industryLabel: string; //所属行业名称（新）
    className: string; //班级code
    classLabel: string; //班级code名称（新）
    weixinNum: string; //微信号
    studyStyle: string; //学习形式（新）
    studyStyleLabel: string; //学习形式名称（新）
    studyId: string; //班级信息修改索引ID（新）
    customerId?: any; //
    customerCode?: any; //
    enrollmentFlag: string; //1：在校生 0：毕业生
    cardExpirationDate: string; // 校友卡有效期
    emailInfoFlag: string;
    phoneInfoFlag: string;
    weixinInfoFlag: string;
    checkCompanyFlag: string;
    checkCompanyState: string;
    liaisonFlag: BooleanValue; // 是否为联络人
}

/**
 * 获取用户信息
 */
export function getPersonByLoginUserId() {
    return NoLoginRequest.get<SuccessData<UserInfo>>(
        `/alumni/getPersonByLoginUserId`,
    ).then(res => res.data.data);
}

/**
 * 获取所有未过期服务
 */
export function getCustomerService() {
    return NoLoginRequest<SuccessData<{name: string; code: string}[]>>(
        '/alumni/getCustomerService',
    ).then(res => res.data.data);
}
