import React from 'react';
import Style from './index.module.scss';
import classNames from 'classnames';

interface Props {
    children: React.ReactNode;
    className?: string;
}
const Index = ({children, className}: Props) => {
    return <div className={classNames(Style.root, className)}>{children}</div>;
};

export default Index;
