import {MenuItem} from '../../../../Utils/help';
import React from 'react';
import styles from './index.module.scss';
import {useNavTo} from '../../../../Utils/Hooks/useNavTo';

interface AdPlaceProps {
    data: MenuItem[];
}

export function AdPlace({data}: AdPlaceProps) {
    const navigate = useNavTo();

    return (
        <div className={styles.root}>
            {data.map(item => (
                <div
                    key={item.id}
                    className={styles.item}
                    onClick={() => navigate(item.menuUrl)}>
                    <img src={item.h5Icon} alt={item.name} />
                </div>
            ))}
        </div>
    );
}
