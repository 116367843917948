import React, {useEffect, useMemo} from 'react';
import Style from './index.module.scss';
import {SideBar} from 'antd-mobile';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import {AlumniTypeData} from './service';
import JoinCard from './Components/JoinCard';
import OrganizationList from './Components/OrganizationList';
import {useNavigate} from 'react-router-dom';
import useMainHeight from '../../Utils/Hooks/useMainHeight';
import {useAtom} from 'jotai';
import {currentAtom} from './store';
import {currentAtom as currentDetailAtom} from './Detail/store';

export const AlumniTypeDataLoader = makeLoader(AlumniTypeData);

const Index = () => {
    const [activeKey, setActiveKey] = useAtom(currentAtom);
    const {joinData, typeData} = useLoaderData<typeof AlumniTypeDataLoader>();
    const navigate = useNavigate();
    const [, setActive] = useAtom(currentDetailAtom);
    useEffect(() => {
        setActive(0);
    }, []);
    const tabList = useMemo(() => {
        const tabs = [];
        if (typeData.length > 0) {
            if (!activeKey) {
                setActiveKey(typeData[0].key);
            }
            tabs.push(...typeData);
        }
        if (joinData.length > 0) {
            if (!activeKey) {
                setActiveKey('join');
            }
            tabs.unshift({
                key: 'join',
                title: '已加入',
            });
        }

        return tabs;
    }, [joinData, typeData]);

    const {mainHeight} = useMainHeight();

    function openDetail(id: string) {
        navigate(id);
    }

    return (
        <div className={Style.container} style={{height: mainHeight}}>
            <div className={Style.side}>
                <SideBar activeKey={activeKey} onChange={setActiveKey}>
                    {tabList.map(item => (
                        <SideBar.Item key={item.key} title={item.title} />
                    ))}
                </SideBar>
            </div>
            <div className={Style.main}>
                {activeKey === 'join' && (
                    <div className={Style.join}>
                        {joinData.map(item => (
                            <JoinCard
                                onClick={openDetail}
                                data={item}
                                key={item.id}
                            />
                        ))}
                    </div>
                )}
                {activeKey !== 'join' && activeKey && (
                    <OrganizationList
                        onClick={openDetail}
                        alumniType={activeKey}
                        key={activeKey}
                    />
                )}
            </div>
        </div>
    );
};

export default Index;
