import React from 'react';
import Style from './index.module.scss';
import useMainHeight from '../../../Utils/Hooks/useMainHeight';
import FixedBottom from '../../../Components/Layout/FixedBottom';
import {Button, Card, Image, List, Modal, Tabs} from 'antd-mobile';
import classNames from 'classnames';
import {ShareData} from './service';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import Empty from '../../../Components/Empty';
import {useNavigate} from 'react-router-dom';
import useModelSetting from '../../../Utils/Hooks/useModelSetting';
import {AppModel, ModelItemCode} from '../../../Public';

export const ShareDataLoader = makeLoader(async () => await ShareData());

const Index = () => {
    const {shareData, rankList, myShareList} =
        useLoaderData<typeof ShareDataLoader>();
    const {mainHeight} = useMainHeight();
    const navigator = useNavigate();
    const {
        data: [about, bg],
    } = useModelSetting(AppModel.Share, [
        ModelItemCode.ShareAbout,
        ModelItemCode.ShareBg,
    ]);

    function openRule() {
        Modal.show({
            title: '活动规则',
            closeOnMaskClick: true,
            content: <div className={Style.about}>{about?.value}</div>,
        });
    }

    return (
        <div
            className={Style.root}
            style={{
                minHeight: mainHeight,
                backgroundImage: `url('${bg?.value}')`,
            }}>
            <div className={classNames(Style.pd, Style.main)}>
                <div className={Style.rule} onClick={openRule}>
                    活动规则
                </div>
                <Card>
                    <div className={Style.number}>
                        目前共找到 <span>{shareData.joinCount}</span> 位朋友
                    </div>
                    <Tabs>
                        <Tabs.Tab title="号召力排行" key="rank">
                            <List
                                style={{
                                    '--border-top': 'none',
                                }}>
                                {rankList.map(item => (
                                    <List.Item
                                        key={item.userId}
                                        extra={
                                            <div>
                                                邀请人数：{item.personSum}
                                            </div>
                                        }
                                        prefix={
                                            <Image
                                                src={item.pic}
                                                style={{borderRadius: '100%'}}
                                                fit="cover"
                                                width={40}
                                                height={40}
                                            />
                                        }>
                                        <div className={Style.name}>
                                            {item.name}
                                        </div>
                                    </List.Item>
                                ))}
                            </List>
                            {rankList.length === 0 && <Empty />}
                        </Tabs.Tab>
                        <Tabs.Tab title="我邀请的" key="myShare">
                            <List
                                style={{
                                    '--border-top': 'none',
                                }}>
                                {myShareList.map(item => (
                                    <List.Item
                                        key={item.userId}
                                        extra={<div>{item.createTime}</div>}>
                                        {item.name}
                                    </List.Item>
                                ))}
                            </List>
                            {myShareList.length === 0 && <Empty />}
                        </Tabs.Tab>
                    </Tabs>
                </Card>
            </div>
            <FixedBottom>
                <div className={Style.pd}>
                    <Button
                        color="primary"
                        shape="rounded"
                        block
                        onClick={() => navigator('create')}>
                        我要去邀请
                    </Button>
                </div>
            </FixedBottom>
        </div>
    );
};

export default Index;
