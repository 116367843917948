import React from 'react';
import Styles from './index.module.scss';
import {Grid, Tag} from 'antd-mobile';
import ArticleGrid from '../../../../../Components/ArticleGrid';
import lodash from 'lodash-es';
import {StatusTag} from '../../../../Activity/Components/StatusTag';
import {MyActivityList} from '../../service';
import Empty from '../../../../../Components/Empty';
import {useDict} from '../../../useDict';
import {getActiveHref} from '../../../../Activity/service';

interface Props {
    data: MyActivityList[];
}
const Index = ({data}: Props) => {
    const dict = useDict();
    return (
        <div className={Styles.root}>
            {lodash.isEmpty(data) && <Empty />}
            <Grid columns={1} gap={15}>
                {data.map(item => (
                    <Grid.Item key={item.activityId}>
                        <ArticleGrid
                            imgTag={<StatusTag status={item.statusFlag} />}
                            href={getActiveHref({
                                type: item.type,
                                id: item.activityId,
                            })}
                            time={`${item.startTime} 至 ${item.endTime}`}
                            title={item.name}
                            img={item.pic}
                            middleExtend={
                                <div className={Styles.tagRow}>
                                    {item.maType && (
                                        <Tag color="primary" fill="outline">
                                            {dict.ActivityType.getName(
                                                item.maType,
                                            )}
                                        </Tag>
                                    )}

                                    <Tag color="primary" fill="outline">
                                        {item.perStatusFlag}
                                    </Tag>
                                </div>
                            }
                        />
                    </Grid.Item>
                ))}
            </Grid>
        </div>
    );
};

export default Index;
