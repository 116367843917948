import {useNavigate} from 'react-router-dom';

export function getWebviewUrl(path: string) {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    const url = new URL(
        path,
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_WEB_URL!
            : window.location.origin,
    );

    const params = url.searchParams;

    url.searchParams.append('token', token!);
    url.searchParams.append('refreshToken', refreshToken!);

    for (const paramsKey in params) {
        url.searchParams.append(paramsKey, params.get('paramsKey')!);
    }

    return url.href;
}

export function useNavTo() {
    const navigate = useNavigate();

    return (url: string) => {
        if (url.startsWith('http')) {
            window.location.href = url;
            return;
        }

        if (url.startsWith('/webview')) {
            window.location.href = getWebviewUrl(url);
            return;
        }

        navigate(url);
    };
}
