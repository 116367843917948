import styles from './index.module.scss';
import {AiOutlineUpload} from 'react-icons/ai';
import {useAsyncFn} from 'react-use';
import {ChangeEvent} from 'react';
import {errorMessage} from '../../../../../Utils/help';
import {uploadFile} from '../../../../../Public';
import {Modal} from 'antd-mobile';

interface UploadProps {
    text: string;
    onChange(url: string): void;
}

export function Upload({text, onChange}: UploadProps) {
    const [{loading}, onPickFile] = useAsyncFn(
        async (evt: ChangeEvent<any>) => {
            try {
                const {url} = await uploadFile(evt.target.files[0]);
                onChange(url);
            } catch (e) {
                Modal.alert({content: errorMessage(e)});
            }
        },
    );

    return (
        <div className={styles.root}>
            <button className={styles.button}>
                <AiOutlineUpload /> {text}
            </button>

            <input
                disabled={loading}
                type="file"
                className={styles.upload}
                accept="image/*"
                onChange={onPickFile}
            />
        </div>
    );
}
