import React, {useMemo, useState} from 'react';
import {Picker} from 'antd-mobile';

interface Option {
    label: string;
    value: string;
}

interface SelectProps {
    options?: Option[];
    value?: string;
    onChange?(value: string): void;
    placeholder?: string;
    style?: React.CSSProperties;
}

export function Select({
    options,
    placeholder = '请选择',
    value,
    onChange,
    style,
}: SelectProps) {
    const [visible, setVisible] = useState(false);

    function onSelect(value: any) {
        setVisible(false);
        onChange?.(value[0]);
    }

    const selected = useMemo(
        () => options?.find(item => item.value === value),
        [value, options],
    );

    return (
        <>
            <span style={style} onClick={() => setVisible(true)}>
                {selected ? selected.label : placeholder}
            </span>

            <Picker
                visible={visible}
                columns={[options as any] || []}
                onConfirm={onSelect}
                onCancel={() => setVisible(false)}
            />
        </>
    );
}
