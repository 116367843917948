import React from 'react';
import Styles from './index.module.scss';
import {Button, Toast} from 'antd-mobile';
import About from './About';
import News from './News';
import Member from './Member';
import {AlumniDetailData, joinAlumni, JoinStatus} from '../service';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import {errorMessage} from '../../../Utils/help';
import {useNavigate, useParams} from 'react-router-dom';
import TabPage from '../../../Components/Layout/TabPage';
import {useAtom} from 'jotai';
import {currentAtom} from './store';

const tabItems = [
    {key: 'about', title: '简介', element: <About />},
    {key: 'news', title: '动态', element: <News />},
    {key: 'member', title: '成员', element: <Member />},
];

export const AlumniDetailDataLoader = makeLoader(
    async e => await AlumniDetailData(e),
);

const Index = () => {
    const navigator = useNavigate();
    const {alumniId} = useParams<{alumniId: string}>();
    const [active, setActive] = useAtom(currentAtom);
    const {aboutData} = useLoaderData<typeof AlumniDetailDataLoader>();

    const [joinAction, {loading: joinLoading}] = useRequestFn(joinAlumni);
    async function onJoin() {
        try {
            await joinAction(alumniId!);
            navigator('.', {replace: true});
        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }

    function Bottom() {
        if (aboutData.statusFlag === JoinStatus.CanJoin) {
            return (
                <div className={Styles.join}>
                    <Button
                        onClick={onJoin}
                        loading={joinLoading}
                        block
                        shape="rounded"
                        color="primary">
                        立即加入
                    </Button>
                </div>
            );
        } else {
            return null;
        }
    }

    return (
        <div className={Styles.root}>
            <TabPage
                active={active}
                setActive={setActive}
                bottom={<Bottom />}
                tabItems={tabItems}
            />
        </div>
    );
};

export default Index;
