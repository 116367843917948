import React from 'react';
import Styles from './index.module.scss';
import {Grid} from 'antd-mobile';
import ArticleGrid from '../../../../Components/ArticleGrid';
import lodash from 'lodash-es';
import {useLoaderData} from 'react-router-typesafe';
import {AlumniDetailDataLoader} from '../index';
import Empty from '../../../../Components/Empty';

const Index = () => {
    const {newsData} = useLoaderData<typeof AlumniDetailDataLoader>();

    return (
        <div className={Styles.root}>
            {lodash.isEmpty(newsData) && <Empty />}
            <Grid columns={1} gap={15}>
                {newsData.map(item => (
                    <Grid.Item key={item.id}>
                        <ArticleGrid
                            href={`/news/""/${item.id}`}
                            time={item.create_time}
                            title={item.title}
                            img={item.smallPic}
                        />
                    </Grid.Item>
                ))}
            </Grid>
        </div>
    );
};

export default Index;
