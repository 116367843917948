import {UIMatch, useMatches} from 'react-router-dom';
import {AppMenuHandle} from '../../MainMenu';
import lodash from 'lodash-es';

export default function useRouteData() {
    const matches = useMatches() as UIMatch<unknown, AppMenuHandle>[];
    const current = lodash.last(matches)?.handle;
    const haveTab = current?.isTabNav;
    const haveTopNav = current?.haveTopNav;

    return {
        current,
        haveTab,
        haveTopNav,
    };
}
