import React from 'react';
import Style from './index.module.scss';
import {Button, Form, Input, Toast} from 'antd-mobile';
import SendMsg from '../../../Components/Form/SendMsg';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import {useNavigate} from 'react-router-dom';
import {errorMessage} from '../../../Utils/help';
import {ChangePhone, changePhone} from '../service';

const Index = () => {
    const [form] = Form.useForm<ChangePhone>();
    const [login, {loading}] = useRequestFn(changePhone);
    const navigate = useNavigate();
    const phone = Form.useWatch('phone', form);

    async function onFinish(data: ChangePhone) {
        try {
            await login(data);
            navigate(-1);
        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }
    return (
        <div className={Style.root}>
            <Form
                layout="horizontal"
                onFinish={onFinish}
                form={form}
                mode="card">
                <Form.Item
                    rules={[{required: true}]}
                    label="新手机号"
                    name="phone">
                    <Input placeholder="请输入手机号" />
                </Form.Item>
                <Form.Item
                    rules={[{required: true}]}
                    name="checkPhoneCode"
                    label="验证码"
                    extra={<SendMsg phone={phone} />}>
                    <Input placeholder="请输入验证码" />
                </Form.Item>
            </Form>
            <div className={Style.pd}>
                <Button
                    color="primary"
                    shape="rounded"
                    block
                    className={Style.btn}
                    onClick={form.submit}
                    loading={loading}>
                    保存
                </Button>
            </div>
        </div>
    );
};

export default Index;
