import {MainRequest} from '../../../Utils/Request';
import {PickerDate} from 'antd-mobile/es/components/date-picker/util';
import dayjs from 'dayjs';
import lodash from 'lodash-es';
import {LoaderFunctionArgs} from 'react-router-dom';

export const BusOptions = [
    {
        label: '单程',
        value: '单程',
    },
    {
        label: '往返',
        value: '往返',
    },
];

const dateRangeArr = [
    '全程',
    '9:00~10:00',
    '10:00~11:00',
    '11:00~12:00',
    '12:00~13:00',
    '13:00~14:00',
    '14:00~15:00',
    '15:00~16:00',
    '16:00~17:00',
];

export const TimeRangerOptions = dateRangeArr.map(item => {
    return {
        value: item,
        label: item,
    };
});

export interface PostReturnProps {
    college: string; //院系
    enterYear: PickerDate | string; //毕业时间
    major: string; //专业
    contact: string; //联系人
    phone: string; //联系方式
    companyName: string; //现任单位
    peopleNum: string; //人数规模
    returnTime: PickerDate | string; //返校时间
    shootFlag: BooleanValue; //协助摄影（字典 no_yes）0：否；1：是
    shootTime: string[]; //摄影时间段
    busFlag: BooleanValue; //校园大巴（字典 no_yes）0：否；1：是
    busTime: string[]; //定制方式
    busNum: string; //人数
    bannerFlag: BooleanValue; //条幅定制
    bannerContent: string; //文字内容
}

export function postReturn(data: PostReturnProps) {
    return MainRequest.postForm<SuccessData<{id: string}>>(
        `alumni/writeReturnSchool`,
        {
            ...data,
            enterYear: dayjs(data.enterYear).format('YYYY-MM'),
            returnTime: dayjs(data.returnTime).format('YYYY-MM-DD'),
            shootTime: lodash.first(data.shootTime),
            busTime: lodash.first(data.busTime),
        },
    ).then(res => res.data.data);
}

interface JoinList {
    personList: ReturnList[];
    allNum: number; //参与人数
    commander: string; //团场id
}

export interface ReturnList {
    joinTime: string; //报名时间
    personName: string; //姓名
    phone: string; //手机号
}

//报名列表
export function getReturnPerson(id: string) {
    //Detail页发送通知消息
    return MainRequest.postForm<SuccessData<JoinList>>(
        `alumni/getRetSchPerNum`,
        {
            id,
        },
    ).then(res => res.data.data);
}
export function ReturnPersonData(e: LoaderFunctionArgs) {
    const id = e.params.id as any;
    //Detail页发送通知消息
    return getReturnPerson(id);
}

//返校详情
export interface ReturnDetailProp extends PostReturnProps {
    id: number;
    enterYear: string;
    returnTime: string;
}
export function getReturnDetail(id: number) {
    return MainRequest.get<SuccessData<ReturnDetailProp>>(
        `/alumni/getReturnSchoolInfo`,
        {
            params: {id},
        },
    ).then(res => res.data.data);
}

export async function ReturnDetailData(e: LoaderFunctionArgs) {
    const id = e.params.id as any;
    return Promise.all([
        getReturnDetail(id),
        isSubmit(id),
        getReturnPerson(id),
    ]).then(res => {
        const [detailData, isSubmit, isManage] = res;
        return {
            detailData,
            isSubmit,
            createId: isManage.commander,
        };
    });
}

//提交报名
export interface JoinReturnProps {
    personName: string;
    phone: string;
    privateCarNum: string;
    retSchId: string; //返校id
}
export function returnSchAddPerson(data: JoinReturnProps) {
    return MainRequest.postForm(`alumni/returnSchAddPerson`, data);
}

//是否已经报名
export function isSubmit(retSchId: string) {
    return MainRequest.postForm<SuccessData<{result: boolean}>>(
        `alumni/returnSchCheckHavePerson`,
        {
            retSchId,
        },
    ).then(res => res.data.data.result);
}
