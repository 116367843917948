import React, {useContext, useState} from 'react';
import {ActivationType} from 'src/Public/card';
import NoticeLayer from '../Activation/Components/NoticeLayer';
import styles from './index.module.scss';
import {CardContext} from '../../context';

interface props {
    children: React.ReactNode;
    isLogin: boolean;
}

function Activation({children, isLogin}: props) {
    const ctx = useContext(CardContext);
    const [isBuilding, setIsBuilding] = useState(false);
    const clickHandler = () => {
        if (ctx.checkFlag === ActivationType.noAccess) {
            setIsBuilding(true);
        }
    };

    return (
        <>
            <div className={`${styles.root}`} onClick={clickHandler}>
                {children}
            </div>
            {isLogin && (
                <NoticeLayer
                    isShow={isBuilding}
                    onClose={() => setIsBuilding(false)}
                />
            )}
        </>
    );
}

export default Activation;

Activation.defaultProps = {
    isLogin: false,
};
