import React from 'react';
import {
    AppOutline,
    SearchOutline,
    UserAddOutline,
    UserSetOutline,
} from 'antd-mobile-icons';
import {RouteObject} from 'react-router/dist/lib/context';
import Main from './Components/Layout/Main';
import Home, {HomeDataLoader} from './Page/Home';
import Find from './Page/Find';
import FindDetail, {PersonDetailDataLoader} from './Page/Find/FindDetail';
import My, {UserDataLoader} from './Page/My';
import Collection, {CollectionDataLoader} from './Page/My/Collection';
import MyActivity, {MyActivityDataLoader} from './Page/My/Activity';
import MyOrganization from './Page/My/Organization';
import MyPoint from './Page/My/Point';
import Login from './Page/Auth/Login';
import Reg from './Page/Auth/Reg';
import NewsLayout from './Page/News';
import NewsList, {NewsListDataLoader} from './Page/News/List';
import NewsDetail from './Page/News/Detail';
import Alumni, {AlumniTypeDataLoader} from './Page/Alumni';
import AlumniDetail, {AlumniDetailDataLoader} from './Page/Alumni/Detail';
import Activity from './Page/Activity';
import ActivityDetail, {ActiveDetailLoader} from './Page/Activity/Detail';
import Notice from './Page/Notice';
import NoticeDetail from './Page/Notice/Detail';
import Birthday, {NoticeDataLoader} from './Page/Notice/Birthday';
import ReturnSchool from './Page/Other/Return';
import ReturnSchoolDetail, {
    ReturnDetailDataLoader,
} from './Page/Other/Return/Detail';
import ReturnSchoolSubmit from './Page/Other/Return/Submit';
import ReturnSchoolManage, {
    ReturnPersonDataLoader,
} from './Page/Other/Return/Manage';
import UserInfo, {UserInfoDataLoader} from './Page/My/Info';
import ChangePhone from './Page/My/ChangePhone';
import {ErrorBlock} from 'antd-mobile';
import Share, {ShareDataLoader} from './Page/Other/Share';
import ShareCreate from './Page/Other/Share/Posters';
import ForgetPassword from './Page/Auth/ForgetPassword';
import {Donate} from './Page/Other/Donate';
import {CardVerify} from './Page/Other/CardVerify';
import Card from './Page/Other/Card';
import {GoActivity} from './Page/Other/GoActivity';

export interface AppMenuHandle {
    title?: string;
    isTabNav?: boolean;
    haveTopNav?: boolean;
    icon?: React.ReactNode;
}

interface Handle {
    children?: AppMenuItem[];
    handle?: AppMenuHandle;
}

export type AppMenuItem = RouteObject & Handle;

export const MainMenu: AppMenuItem[] = [
    {
        element: <Main />,
        errorElement: <ErrorBlock />,
        children: [
            {
                path: '/',
                index: true,
                element: <Home />,
                loader: HomeDataLoader,
                handle: {
                    isTabNav: true,
                    icon: <AppOutline />,
                },
            },
            {
                path: '/find',
                children: [
                    {
                        path: '/find',
                        index: true,
                        element: <Find />,
                        handle: {
                            title: 'alumni_app_find_person', //找校友
                            isTabNav: true,
                            haveTopNav: true,
                            icon: <UserAddOutline />,
                        },
                    },
                    {
                        path: ':userId',
                        element: <FindDetail />,
                        loader: e => PersonDetailDataLoader(e),
                        handle: {
                            haveTopNav: true,
                            title: 'alumni_app_find_person', //找校友
                        },
                    },
                ],
            },
            {
                path: '/alumni',
                children: [
                    {
                        path: '/alumni',
                        index: true,
                        element: <Alumni />,
                        loader: AlumniTypeDataLoader,
                        handle: {
                            title: 'alumni_app_organization', //校友会
                            isTabNav: true,
                            haveTopNav: true,
                            icon: <SearchOutline />,
                        },
                    },
                    {
                        path: ':alumniId',
                        loader: AlumniDetailDataLoader,
                        element: <AlumniDetail />,
                        handle: {
                            haveTopNav: true,
                            title: 'alumni_app_organization', //校友会
                        },
                    },
                ],
            },
            {
                path: '/my',
                children: [
                    {
                        path: '/my',
                        index: true,
                        element: <My />,
                        loader: UserDataLoader,
                        handle: {
                            title: '我的',
                            isTabNav: true,
                            haveTopNav: true,
                            icon: <UserSetOutline />,
                        },
                    },
                    {
                        path: 'info',
                        loader: UserInfoDataLoader,
                        element: <UserInfo />,
                        handle: {
                            haveTopNav: true,
                            title: '个人信息',
                        },
                    },
                    {
                        path: 'changePhone',
                        element: <ChangePhone />,
                        handle: {
                            haveTopNav: true,
                            title: '修改手机号',
                        },
                    },
                    {
                        path: 'collection',
                        loader: CollectionDataLoader,
                        element: <Collection />,
                        handle: {
                            haveTopNav: true,
                            title: '我的收藏',
                        },
                    },
                    {
                        path: 'activity',
                        loader: MyActivityDataLoader,
                        element: <MyActivity />,
                        handle: {
                            haveTopNav: true,
                            title: '我的活动',
                        },
                    },
                    {
                        path: 'organization',
                        loader: AlumniTypeDataLoader,
                        element: <MyOrganization />,
                        handle: {
                            haveTopNav: true,
                            title: '我的组织',
                        },
                    },
                    {
                        path: 'point',
                        element: <MyPoint />,
                        handle: {
                            haveTopNav: true,
                            title: '我的积分',
                        },
                    },
                ],
            },
            {
                path: '/login',
                element: <Login />,
                handle: {
                    title: '登录',
                },
            },
            {
                path: '/forget',
                element: <ForgetPassword />,
                handle: {
                    title: '登录',
                },
            },
            {
                path: '/reg',
                element: <Reg />,
                handle: {
                    title: '注册',
                },
            },
            {
                path: '/activity',
                children: [
                    {
                        path: '/activity',
                        index: true,
                        element: <Activity />,
                        handle: {
                            title: 'alumni_app_activities', //活动
                            haveTopNav: true,
                        },
                    },
                    {
                        path: ':id',
                        loader: ActiveDetailLoader,
                        element: <ActivityDetail />,
                        handle: {
                            title: 'alumni_app_activities',
                            haveTopNav: true,
                        },
                    },
                ],
            },
            {
                path: '/notice',
                children: [
                    {
                        index: true,
                        element: <Notice />,
                        handle: {
                            haveTopNav: true,
                            title: '通知',
                        },
                    },
                    {
                        path: 'birthday/:id',
                        element: <Birthday />,
                        loader: NoticeDataLoader,
                        handle: {
                            haveTopNav: true,
                            title: '生日祝福',
                        },
                    },
                    {
                        path: ':id',
                        element: <NoticeDetail />,
                        loader: NoticeDataLoader,
                        handle: {
                            haveTopNav: true,
                            title: '通知详情',
                        },
                    },
                ],
            },
            {
                path: '/news/:type',
                element: <NewsLayout />,
                children: [
                    {
                        index: true,
                        element: <NewsList />,
                        loader: e => NewsListDataLoader(e),
                    },
                    {
                        path: ':id',
                        element: <NewsDetail />,
                    },
                ],
            },
            {
                path: 'return',
                children: [
                    {
                        index: true,
                        path: '/return',
                        element: <ReturnSchool />,
                        handle: {
                            haveTopNav: true,
                            title: 'alumni_app_zhinian_returns_to_school', //返校
                        },
                    },
                    {
                        path: ':id',
                        element: <ReturnSchoolDetail />,
                        loader: ReturnDetailDataLoader,
                        handle: {
                            haveTopNav: true,
                            title: 'alumni_app_zhinian_returns_to_school', //返校
                        },
                    },
                    {
                        path: 'manage/:id',
                        element: <ReturnSchoolManage />,
                        loader: ReturnPersonDataLoader,
                        handle: {
                            haveTopNav: true,
                            title: 'alumni_app_zhinian_returns_to_school', //返校
                        },
                    },
                    {
                        path: 'submit/:id',
                        element: <ReturnSchoolSubmit />,
                        handle: {
                            haveTopNav: true,
                            title: 'alumni_app_zhinian_returns_to_school', //返校
                        },
                    },
                ],
            },
            {
                path: '/share',
                children: [
                    {
                        index: true,
                        path: '/share',
                        loader: ShareDataLoader,
                        element: <Share />,
                        handle: {
                            haveTopNav: true,
                            title: 'alumni_app_root_seeking_alma_mater', //寻根母校,
                        },
                    },
                    {
                        path: 'create',
                        element: <ShareCreate />,
                        handle: {
                            haveTopNav: true,
                            title: 'alumni_app_root_seeking_alma_mater', //寻根母校,
                        },
                    },
                ],
            },
            {
                path: '/other',
                children: [
                    {
                        path: 'donate',
                        element: <Donate />,
                        handle: {
                            haveTopNav: true,
                        },
                    },
                    {
                        path: 'Card',
                        element: <Card />,
                    },
                    {
                        path: 'CardVerify',
                        element: <CardVerify />,
                    },
                    {
                        path: 'GoActivity',
                        element: <GoActivity />,
                    },
                ],
            },
        ],
    },
];
