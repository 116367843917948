import React, {useContext} from 'react';
import styles from './index.module.scss';
import {CardContext} from '../../context';
import {goVerify} from '../../utils';
import {ActivationType} from 'src/Public/card';
import Activation from '../../components/Activation';
import {goLogin} from '../../../../Utils/help';
import useUser from '../../../../Utils/Hooks/useUser';
import classNames from 'classnames';
import {useAtomValue} from 'jotai';
import {LogoImgAtom} from '../../../../Store/ImgAtom';

interface CardSmallLockedProps {
    className?: string;
}

export function CardSmallLocked({className}: CardSmallLockedProps) {
    const ctx = useContext(CardContext);
    const [user] = useUser();
    const logo = useAtomValue(LogoImgAtom);

    const clickHandler = () => {
        if (!user) {
            goLogin();
        }
        if (ctx.checkFlag === ActivationType.access) {
            goVerify();
        }
    };

    return (
        <Activation isLogin={!!user}>
            <div
                className={classNames(styles.root, className)}
                role="button"
                onClick={clickHandler}>
                <img className={styles.image} src={ctx.base?.backPic} alt="" />

                <div className={styles.logo}>
                    <img src={logo} alt="" />
                    <div className={styles.title}>校友卡</div>
                </div>

                <div className={styles.overlay} />

                <div className={styles.button}>
                    <img
                        className={styles.lock}
                        width="12px"
                        height="14px"
                        src={require('./img/lock.png')}
                        alt=""
                    />
                    激活校友卡
                </div>
            </div>
        </Activation>
    );
}
