import React, {useState} from 'react';
import Style from './index.module.scss';
import {useAtom, useAtomValue} from 'jotai';
import {AgreeAtom} from '../../store';
import {LoginSettingAtom} from '../../../../Store/commonStore';
import {Popup} from 'antd-mobile';
import {DictOpenType} from '../../../../Utils/help';

interface Props {
    children: React.ReactElement;
}
const Index = ({children}: Props) => {
    const [agree, setAgree] = useAtom(AgreeAtom);
    const loginSetting = useAtomValue(LoginSettingAtom);
    const [visible, setVisible] = useState(false);
    const [text, setText] = useState('');

    function onShow(text: string) {
        setText(text);
        setVisible(true);
    }

    return (
        <div className={Style.root}>
            <div className={Style.main}>{children}</div>
            <p className={Style.show}>
                <input
                    checked={agree}
                    type="checkbox"
                    onChange={event => setAgree?.(event.target.checked)}
                />
                我同意
                {loginSetting.serviceAgreement === DictOpenType.Open && (
                    <span onClick={() => onShow(loginSetting.agreementContent)}>
                        《服务条款》
                    </span>
                )}
                {loginSetting.privacyPolicy === DictOpenType.Open &&
                    loginSetting.serviceAgreement === DictOpenType.Open &&
                    '与'}
                {loginSetting.privacyPolicy === DictOpenType.Open && (
                    <span
                        onClick={() =>
                            onShow(loginSetting.privacyPolicyContent)
                        }>
                        《隐私政策》
                    </span>
                )}
            </p>

            <Popup
                visible={visible}
                onMaskClick={() => {
                    setVisible(false);
                }}>
                <div
                    style={{
                        height: '60vh',
                        overflowY: 'scroll',
                        padding: '20px',
                    }}>
                    <div dangerouslySetInnerHTML={{__html: text}} />
                </div>
            </Popup>
        </div>
    );
};

export default Index;
