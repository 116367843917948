interface FileUploadResProps {
    fileUrl: string;
    name: string;
    code?: string;
    path?: string;
    key?: string;
}

export enum UploadStatus {
    Uploading = 1,
    Done,
    Error,
    Wait,
    Stop,
}
export interface FileProps {
    file: File;
    uid: string;
    status: UploadStatus;
    error?: ErrorEvent;
    percent: number;
    response?: FileUploadResProps;
}

export interface onUploadProgressProps {
    percent: number;
    file: File;
    index: number;
}
export interface onUploadOkProps {
    res: FileUploadResProps;
    file: File;
    index: number;
}
export interface onUploadErrorProps {
    e: ErrorEvent;
    file: File;
    index: number;
}
export interface UploadParams {
    onUploadProgress: (data: onUploadProgressProps) => void;
    onOk: (data: onUploadOkProps) => void;
    onError: (data: onUploadErrorProps) => void;
    index: number;
    uploadUrl: string;
}

export const FileTypeList = {
    ppt: ['ppt', 'pptx'],
    word: ['doc', 'docx'],
    excel: ['xls', 'xlsx'],
    pdf: ['pdf'],
    href: ['html'],
    pic: ['png', 'jpg', 'jpeg', 'gif'],
    txt: ['txt', 'csv'],
    video: ['mp4', 'mp3'],
    compression: ['rar', '7z', 'zip'],
};
