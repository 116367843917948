import {PickerColumnItem} from 'antd-mobile/es/components/picker-view';
import {PickerDate} from 'antd-mobile/es/components/date-picker/util';
import dayjs from 'dayjs';
import {MainRequest} from './Request';

/**
 * 去登录
 */
export async function goLogin() {
    window.location.replace('/login');
}

// 从错误对象中获取错误消息
export function errorMessage(e: any, defaultMessage: string = '发生未知错误') {
    if (!e) return defaultMessage;
    const errCodes = [400, 401];

    if (e.response && !errCodes.includes(e.response.status))
        return '数据请求失败';
    if (e.isAxiosError && e.message === 'Network Error') return '网络异常';
    if (
        e.response &&
        errCodes.includes(e.response.status) &&
        e.response.data &&
        e.response.data.message
    )
        return e.response.data.message;
    if (e instanceof Error && e.message) return e.message;
    return defaultMessage;
}

//睡眠函数
export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function checkLogin() {
    return localStorage.getItem('token');
}
export const canVibrate = 'vibrate' in navigator;

export function getCssVar(name: string) {
    return getComputedStyle(document.documentElement).getPropertyValue(name);
}

export function isTrue(value: BooleanValue): value is BooleanTrue {
    const TrueValueArray: any[] = [1, '1', true, 'true', 'True', 'TRUE'];
    return TrueValueArray.includes(value);
}

export function RenderPickerValue(value: (PickerColumnItem | null)[]) {
    return value.every(item => item === null)
        ? '请选择'
        : value.map(item => item?.label ?? '未选择').join(' - ');
}

export function RenderPickerDateValue(
    value: PickerDate | null,
    format?: string,
) {
    return value ? dayjs(value).format(format || 'YYYY-MM-DD') : '请选择';
}

export enum DictOpenType {
    Open = 'SWITCH10000101',
    Close = 'SWITCH10000102',
}

//菜单位置
export enum MenuType {
    Nav = 'H5ST10000102',
    HomeGrid = 'H5ST10000103',
    My = 'H5ST10000104',
    Ad = 'H5ST10000105',
}
export enum IconType {
    WebSvg = 'icon_type_svg_web',
    WebImg = 'icon_type_img_web',
    Antd = 'icon_type_antd_local',
    AntdMobile = 'icon_type_antd_Mobile_local',
}
export interface MenuItem {
    h5Icon: string;
    iconType: IconType;
    id: string;
    menuUrl: string;
    name: string;
    serviceCode: string;
}
export function getMenu(h5ShowType: MenuType) {
    return MainRequest.postForm<SuccessData<MenuItem[]>>(
        `serviceInfo/getH5SortMenuList`,
        {h5ShowType},
    ).then(res => res.data.data);
}

export interface OrgInfo {
    code: string; // code,
    name: string; // 名称,
    url: string; // test1.biyela.cn,
    createTime: string; // 注册时间,
    expireDayNum: string; // 剩余有效期天数,
    smsTempId: string; // 短信模板id,
    signType: string; // 签名类型,
    sendMessageNumber: string; // 短信发送数,
    maxMessageNumber: string; // 短信最大发送数,
    bucket: string; // 七牛上传bucket,
    uploadUrl: string; // 七牛上传url,
    isExpire: string; // 是否到期,
    expireTime: string; // 到期时间,
    type: '1' | '2'; // 1是sass服务 2是私有化
}
export function getOrgData() {
    return MainRequest.post<SuccessData<OrgInfo>>('/serviceInfo/orgInfo').then(
        res => res.data.data,
    );
}

// 判断是否小在小程序webview中
export function isMini() {
    return navigator.userAgent.includes('miniProgram');
}

// 判断是否微信浏览器
export function isWechatBrowser() {
    return navigator.userAgent.toLowerCase().includes('micromessenger');
}

export function getFullUrl(url: string) {
    return new URL(
        url,
        `${window.location.protocol}//${window.location.host}`,
    ).toString();
}
