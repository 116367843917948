import React, {CSSProperties, useMemo, useRef, useState} from 'react';
import Style from './index.module.scss';
import {Loading} from 'antd-mobile';
import {List, InfiniteScroll} from 'antd-mobile';
import {AutoSizer, WindowScroller} from 'react-virtualized';
import {List as VirtualizedList} from 'react-virtualized/dist/es/List';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import SkeletonLoading from '../../../Components/SkeletonLoading';
import useMainHeight from '../../../Utils/Hooks/useMainHeight';
import {FindPointProps, getPoint, PointList} from './service';

const Index = () => {
    const initialParams = {
        pageNo: 0,
        pageSize: 20,
    };

    const [params, setParams] = useState<FindPointProps>(initialParams);
    const [hasMore, setHasMore] = useState(true);
    const [list, setList] = useState<PointList[]>([]);

    // 请求数据
    const [getData, {loading}] = useRequestFn(getPoint);
    const getList = async (params: FindPointProps) => {
        setParams(params);
        const res = await getData(params);
        setList(data => [...data, ...res.list]);
        setHasMore(res.hasNextPage);
    };

    //加载更多
    const loadMore = async () => {
        await getList({
            ...params,
            pageNo: params.pageNo + 1,
        });
    };

    function rowRenderer({
        index,
        key,
        style,
    }: {
        index: number;
        key: string;
        style: CSSProperties;
    }) {
        const item = list[index];
        if (!item) return;

        return (
            <List.Item
                key={key}
                style={style}
                extra={item.point > 0 ? `+${item.point}` : item.point}
                description={item.createTime}>
                {item.detailName}
            </List.Item>
        );
    }

    const topRef = useRef<HTMLDivElement>(null);
    const topHeight = useMemo(() => {
        return topRef?.current?.getBoundingClientRect().height || 0;
    }, [topRef?.current]);
    const {mainHeight} = useMainHeight(topHeight);

    if (!list) {
        return <SkeletonLoading />;
    }

    return (
        <div className={Style.root}>
            {loading && (
                <div className={Style.loading}>
                    <Loading />
                </div>
            )}
            <div className={Style.main} style={{height: mainHeight}}>
                <WindowScroller>
                    {({height, scrollTop, isScrolling}) => (
                        <List>
                            <AutoSizer disableHeight>
                                {({width}) => (
                                    <VirtualizedList
                                        autoHeight
                                        rowCount={list.length}
                                        rowRenderer={rowRenderer}
                                        width={width}
                                        height={height}
                                        rowHeight={70}
                                        overscanRowCount={10}
                                        isScrolling={isScrolling}
                                        scrollTop={scrollTop}
                                    />
                                )}
                            </AutoSizer>
                        </List>
                    )}
                </WindowScroller>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
            </div>
        </div>
    );
};

export default Index;
