import React, {useEffect} from 'react';
import Style from './index.module.scss';
import {Button, Image, List} from 'antd-mobile';
import Title from '../../../../Components/Title';
import FixedBottom from '../../../../Components/Layout/FixedBottom';
import {useNavigate, useParams} from 'react-router-dom';
import {ReturnDetailData} from '../service';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import useUser from '../../../../Utils/Hooks/useUser';
import {AppModel, ModelItemCode} from '../../../../Public';
import useModelSetting from '../../../../Utils/Hooks/useModelSetting';
import lodash from 'lodash-es';

export const ReturnDetailDataLoader = makeLoader(
    async e => await ReturnDetailData(e),
);

const Index = () => {
    const {detailData, createId, isSubmit} =
        useLoaderData<typeof ReturnDetailDataLoader>();
    const navigate = useNavigate();
    const {id} = useParams<{id: string}>();
    const [userInfo] = useUser();

    const {
        data: [bg, about, phone],
    } = useModelSetting(AppModel.Return, [
        ModelItemCode.ReturnBg,
        ModelItemCode.ReturnAbout,
        ModelItemCode.ReturnPhone,
    ]);

    useEffect(() => {
        if (userInfo?.userId === createId) {
            navigate(`/return/manage/${detailData.id}`, {replace: true});
        }
    }, []);

    return (
        <div className={Style.root}>
            <div className={Style.pd}>
                <Image src={bg?.value} className={Style.bg} />
            </div>
            <List>
                <List.Item extra={detailData.returnTime}>时间</List.Item>
                <List.Item extra={detailData.college}>组织</List.Item>
                <List.Item extra={detailData.contact}>团长</List.Item>
                <List.Item extra={detailData.phone}>团长联系方式</List.Item>
                {!lodash.isEmpty(phone?.value) && (
                    <List.Item className={Style.content} extra={phone?.value}>
                        咨询电话
                    </List.Item>
                )}
            </List>
            <div className={Style.pd}>
                <Title className={Style.title}>活动须知</Title>
                <div className={Style.content}>{about?.value}</div>
            </div>
            <FixedBottom>
                <div
                    className={Style.pd}
                    onClick={() => navigate(`/return/submit/${id}`)}>
                    <Button
                        color="primary"
                        shape="rounded"
                        block
                        disabled={isSubmit}>
                        {isSubmit ? '你已经报过名了' : '立即报名'}
                    </Button>
                </div>
            </FixedBottom>
        </div>
    );
};

export default Index;
