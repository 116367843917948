import React from 'react';
import {HeartFill, HeartOutline} from 'antd-mobile-icons';
import Style from './index.module.scss';
import classNames from 'classnames';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import {newsBad, newsGood} from './service';
import {Toast} from 'antd-mobile';
import {canVibrate, errorMessage} from '../../../Utils/help';
import Spin from '../../Spin';

export interface Props {
    isActive: boolean;
    targetId: number;
    likeId: string;
    onSuccess: () => void;
    number: string;
}
const Index = ({isActive, likeId, targetId, onSuccess, number}: Props) => {
    const [doLike, {loading: doLoading}] = useRequestFn(newsGood);
    const [cancelLike, {loading: cancelLoading}] = useRequestFn(newsBad);
    async function onLike() {
        if (doLoading || cancelLoading) {
            return;
        }
        if (canVibrate) {
            navigator.vibrate(50);
        }
        if (!likeId) {
            try {
                await doLike(targetId);
                onSuccess();
            } catch (e) {
                Toast.show(errorMessage(e));
            }
        } else {
            try {
                await cancelLike(likeId);
                onSuccess();
            } catch (e) {
                Toast.show(errorMessage(e));
            }
        }
    }

    return (
        <Spin loading={doLoading || cancelLoading}>
            <div className={Style.root} onClick={onLike}>
                {isActive ? (
                    <HeartFill
                        className={classNames(Style.active, Style.icon)}
                    />
                ) : (
                    <HeartOutline
                        className={classNames(Style.default, Style.icon)}
                    />
                )}
                {number}
            </div>
        </Spin>
    );
};

export default Index;
