import React, {useState} from 'react';
import Style from './index.module.scss';
import AuthContent from '../Components/AuthContent';
import {Button, Dialog, Form, Input, Toast} from 'antd-mobile';
import {NavLink, useNavigate} from 'react-router-dom';
import {useAtomValue} from 'jotai';
import {AgreeAtom} from '../store';
import classNames from 'classnames';
import SendMsg from '../../../Components/Form/SendMsg';
import {useDict} from '../useDict';
import SelectDictFormItem from '../../../Components/Form/SelectDictFormItem';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import {editPersonInfo, getUrls, register, RegisterForm} from '../service';
import {errorMessage} from '../../../Utils/help';
import Rules from '../../../Utils/rules';
import SelectDate from '../../../Components/Form/SelectDate';
import dayjs from 'dayjs';
import useModalHandler from '../../../Utils/Hooks/useModalHandler';
import ManualReviewModal from './Components/ManualReviewModal';
import {useSearchParam} from 'react-use';
import useDemo from '../../../Utils/Hooks/useDemo';
import TopNav from '../../../Components/Layout/TopNav';
import {EyeInvisibleOutline, EyeOutline} from 'antd-mobile-icons';
const InputProps = {
    placeholder: '请输入',
};
const Index = () => {
    const [visible, setVisible] = useState(false);
    const targetUrl = useSearchParam('targetUrl');
    const dict = useDict();
    const agree = useAtomValue(AgreeAtom);
    const [form] = Form.useForm<RegisterForm>();
    const phone = Form.useWatch('phone', form);
    const navigate = useNavigate();
    const [doReg, {loading}] = useRequestFn(register);
    const [regNext, {loading: regNextLoading}] = useRequestFn(editPersonInfo);

    const referrer = useSearchParam('referrer');

    const manualReviewModal = useModalHandler({
        component: ManualReviewModal,
        type: 'popup',
    });
    const isDemo = useDemo();

    async function onFinish(data: RegisterForm) {
        if (referrer) {
            data.referrer = referrer;
        }
        if (!agree) {
            return Toast.show('请阅读并同意隐私条款及服务协议');
        }
        if (data.password !== data.password2) {
            return Toast.show('两次输入密码不一致,请检查');
        }
        try {
            await doReg(data);
            await regNext(data);
            Toast.show('注册成功');
            if (targetUrl) {
                const url = new URL(targetUrl);
                url.searchParams.append(
                    'token',
                    localStorage.getItem('token')!,
                );
                url.searchParams.append(
                    'refreshToken',
                    localStorage.getItem('refreshToken')!,
                );
                window.location.replace(url);
            } else {
                navigate(`/`, {replace: true}); // <-- redirect
            }
        } catch (e: any) {
            if (e.response.data.code === '90004') {
                const result = await Dialog.confirm({
                    cancelText: '修改资料',
                    confirmText: '人工审核',
                    content: errorMessage(e),
                });
                if (result) {
                    manualReviewModal.open({
                        data,
                    });
                }
            } else {
                Toast.show(errorMessage(e));
            }
        }
    }

    return (
        <TopNav text="注册" canBack={false}>
            <div className={classNames(Style.root, 'bgCircle')}>
                <AuthContent>
                    <div>
                        <Form
                            layout="horizontal"
                            onFinish={onFinish}
                            mode="card"
                            form={form}>
                            <Form.Item
                                name="phone"
                                label="手机号"
                                rules={[{required: true}, Rules.MobilePhone]}>
                                <Input {...InputProps} />
                            </Form.Item>
                            {!isDemo && (
                                <Form.Item
                                    name="checkPhoneCode"
                                    label="验证码"
                                    rules={[{required: true}]}
                                    extra={<SendMsg phone={phone} />}>
                                    <Input {...InputProps} />
                                </Form.Item>
                            )}
                            <Form.Item
                                name="password"
                                label="密码"
                                rules={[{required: true}]}
                                extra={
                                    <div className={Style.extraPart}>
                                        {!visible ? (
                                            <EyeInvisibleOutline
                                                onClick={() => setVisible(true)}
                                            />
                                        ) : (
                                            <EyeOutline
                                                onClick={() =>
                                                    setVisible(false)
                                                }
                                            />
                                        )}
                                    </div>
                                }>
                                <Input
                                    placeholder="请输入密码"
                                    type={visible ? 'text' : 'password'}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password2"
                                label="确认密码"
                                rules={[{required: true}]}>
                                <Input
                                    placeholder="请再次输入密码"
                                    type={visible ? 'text' : 'password'}
                                />
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label="姓名"
                                rules={[{required: true}]}>
                                <Input {...InputProps} />
                            </Form.Item>
                            <Form.Item
                                name="cardId"
                                label="身份证号"
                                rules={[{required: true}]}>
                                <Input {...InputProps} />
                            </Form.Item>
                            <SelectDictFormItem
                                name="studyStyle"
                                label="学习形式"
                                rules={[{required: true}]}
                                options={dict.LearningStyleAtom.data}
                            />
                            <SelectDictFormItem
                                name="eduFlag"
                                label="培养方式"
                                rules={[{required: true}]}
                                options={dict.TrainingStyleAtom.data}
                            />
                            <SelectDate
                                name="enterYear"
                                label="入学年份"
                                rules={[{required: true}]}
                                dateProps={{
                                    min: dayjs('1900').toDate(),
                                    max: new Date(),
                                    precision: 'year',
                                    format: 'YYYY',
                                }}
                            />
                            <SelectDate
                                name="endYear"
                                label="毕业年份"
                                rules={[{required: true}]}
                                dateProps={{
                                    min: dayjs('1900').toDate(),
                                    max: new Date(),
                                    precision: 'year',
                                    format: 'YYYY',
                                }}
                            />
                            <Form.Item
                                name="oldCollege"
                                label="学院"
                                rules={[{required: true}]}>
                                <Input {...InputProps} />
                            </Form.Item>
                            <Form.Item
                                name="majorName"
                                label="学习专业"
                                rules={[{required: true}]}>
                                <Input {...InputProps} />
                            </Form.Item>
                        </Form>

                        <Button
                            color="primary"
                            block
                            onClick={form.submit}
                            loading={loading || regNextLoading}
                            className={Style.submitBtn}>
                            立即注册
                        </Button>

                        <div className={Style.links}>
                            <NavLink to={getUrls('/login', targetUrl)}>
                                去登录
                            </NavLink>
                        </div>
                    </div>
                </AuthContent>

                {manualReviewModal.render()}
            </div>
        </TopNav>
    );
};

export default Index;
