import React from 'react';
import style from './index.module.scss';
import {CardFull, CardProvider} from 'src/Components/Card';

import {getAlumniCard, getAlumniCardBasicParam} from 'src/Public/card';
import RequireAuth from 'src/Components/RequireAuth';
import TopNav from '../../../Components/Layout/TopNav';
import {useQuery} from 'react-query';
import {QrCode} from './Components/QrCode';

export default function Card() {
    const getData = useQuery({
        queryKey: ['getAlumniCard'],
        queryFn: getAlumniCard,
    });

    const getBase = useQuery({
        queryKey: ['getAlumniCardBasicParam'],
        queryFn: getAlumniCardBasicParam,
        onError: undefined,
    });

    if (getData.isLoading || getBase.isLoading) return null;

    return (
        <RequireAuth>
            <TopNav text="校友卡">
                <div className={style.root}>
                    {getBase.data?.showValue === '2' && (
                        <QrCode value={getBase.data?.qrCodeImg} />
                    )}

                    <CardProvider>
                        <CardFull />
                    </CardProvider>
                </div>
            </TopNav>
        </RequireAuth>
    );
}
