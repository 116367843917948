import React, {CSSProperties, useMemo, useRef, useState} from 'react';
import Style from './index.module.scss';
import {Loading, SearchBar} from 'antd-mobile';
import {List, InfiniteScroll} from 'antd-mobile';
import {AlumniPerson, findPerson, FindPersonProps} from './service';
import {AutoSizer, WindowScroller} from 'react-virtualized';
import {List as VirtualizedList} from 'react-virtualized/dist/es/List';
import {useNavigate} from 'react-router-dom';
import useRequestFn from '../../Utils/Hooks/useRequestFn';
import useMainHeight from '../../Utils/Hooks/useMainHeight';
import Config from '../../Config';
import SkeletonLoading from '../../Components/SkeletonLoading';

const Index = () => {
    const initialParams = {
        enterYear: '',
        major: '',
        name: '',
        pageNo: 0,
        pageSize: 20,
    };

    const [params, setParams] = useState<FindPersonProps>(initialParams);
    const [hasMore, setHasMore] = useState(true);
    const [list, setList] = useState<AlumniPerson[]>([]);
    const navigate = useNavigate();

    // 请求数据
    const [getData, {loading}] = useRequestFn(findPerson);
    const getList = async (params: FindPersonProps) => {
        setParams(params);
        const res = await getData(params);
        setList(data => [...data, ...res.list]);
        setHasMore(res.hasNextPage);
    };

    //加载更多
    const loadMore = async () => {
        await getList({
            ...params,
            pageNo: params.pageNo + 1,
        });
    };

    const onKeywordChange = async (name: string) => {
        setList([]);
        setHasMore(false);
        await getList({
            ...initialParams,
            name,
            pageNo: 1,
        });
    };

    function rowRenderer({
        index,
        key,
        style,
    }: {
        index: number;
        key: string;
        style: CSSProperties;
    }) {
        const item = list[index];
        if (!item) return;

        return (
            <List.Item
                key={key}
                style={style}
                arrow
                clickable
                onClick={() => navigate(item.userId)}>
                {item.name}
            </List.Item>
        );
    }

    const topRef = useRef<HTMLDivElement>(null);
    const topHeight = useMemo(() => {
        return topRef?.current?.getBoundingClientRect().height || 0;
    }, [topRef?.current]);
    const {mainHeight} = useMainHeight(topHeight);

    if (!list) {
        return <SkeletonLoading />;
    }

    return (
        <div className={Style.root}>
            <div
                className={Style.search}
                ref={topRef}
                style={{top: Config.height.topNavHeight}}>
                <SearchBar
                    placeholder="请输入关键字搜索"
                    onSearch={onKeywordChange}
                />
            </div>
            {loading && (
                <div className={Style.loading}>
                    <Loading />
                </div>
            )}
            <div className={Style.main} style={{height: mainHeight}}>
                <WindowScroller>
                    {({height, scrollTop, isScrolling}) => (
                        <List>
                            <AutoSizer disableHeight>
                                {({width}) => (
                                    <VirtualizedList
                                        autoHeight
                                        rowCount={list.length}
                                        rowRenderer={rowRenderer}
                                        width={width}
                                        height={height}
                                        rowHeight={50}
                                        overscanRowCount={10}
                                        isScrolling={isScrolling}
                                        scrollTop={scrollTop}
                                    />
                                )}
                            </AutoSizer>
                        </List>
                    )}
                </WindowScroller>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
            </div>
        </div>
    );
};

export default Index;
