import React, {RefObject} from 'react';
import {DatePicker, DatePickerProps, DatePickerRef, Form} from 'antd-mobile';
import {RenderPickerDateValue} from '../../../Utils/help';
import {FormItemProps} from 'antd-mobile/es/components/form/form-item';

interface DateProps extends DatePickerProps {
    format?: string;
}
interface Props extends FormItemProps {
    dateProps?: DateProps;
}
const Index = ({dateProps, ...props}: Props) => {
    return (
        <Form.Item
            {...props}
            trigger="onConfirm"
            onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                datePickerRef.current?.open();
            }}>
            <DatePicker {...dateProps}>
                {value =>
                    RenderPickerDateValue(
                        value,
                        dateProps?.format || 'YYYY-MM-DD',
                    )
                }
            </DatePicker>
        </Form.Item>
    );
};

export default Index;
