import axios from 'axios';
import {errorMessage, goLogin} from './help';
import {QueryClient} from 'react-query';
import {Toast} from 'antd-mobile';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onError: e => {
                Toast.show(errorMessage(e));
            },
        },
        mutations: {
            onError: e => {
                Toast.show(errorMessage(e));
            },
        },
    },
});

export const MainRequest = axios.create({baseURL: '/alapi'});

MainRequest.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');

        config.headers['token'] = token;
        config.headers['refreshToken'] = refreshToken;

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

MainRequest.interceptors.response.use(
    response => {
        if (response.data && typeof response.data !== 'object') goLogin();
        return response;
    },
    error => {
        if (error.response.status >= 400) {
            if (error.response.data.code === '2001') {
                const {token, refreshToken} = error.response.data.data;
                localStorage.setItem('token', token);
                localStorage.setItem('refreshToken', refreshToken);
                error.response.config.headers = {
                    ...error.response.config.headers,
                    token,
                    refreshToken,
                };
                return axios({...error.response.config});
            }
            if (error.response.status === 401) {
                goLogin();
            }
        }
        return Promise.reject(error);
    },
);

export const NoLoginRequest = axios.create({baseURL: '/alapi'});

NoLoginRequest.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');

        config.headers['token'] = token;
        config.headers['refreshToken'] = refreshToken;

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

NoLoginRequest.interceptors.response.use(
    response => response,
    error => {
        if (error.response.data.code === '2001') {
            const {token, refreshToken} = error.response.data.data;
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            error.response.config.headers = {
                ...error.response.config.headers,
                token,
                refreshToken,
            };
            return axios({...error.response.config});
        }
        return Promise.reject(error);
    },
);
