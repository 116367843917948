import React, {useEffect, useState} from 'react';
import Style from './index.module.scss';
import {List, SearchBar} from 'antd-mobile';
import {getAlumniList, IndexAlumni} from '../../service';
import Spin from '../../../../Components/Spin';
import useRequestFn from '../../../../Utils/Hooks/useRequestFn';
import Empty from '../../../../Components/Empty';
import SkeletonLoading from '../../../../Components/SkeletonLoading';

interface Props {
    alumniType: string;
    onClick: (id: string) => void;
}
const Index = ({alumniType, onClick}: Props) => {
    const [name, setName] = useState('');

    const [data, setData] = useState<IndexAlumni[] | null>(null);

    const [getData, {loading}] = useRequestFn(getAlumniList);

    async function onSearch(_name?: string) {
        const res = await getData({alumniType, name: _name || name});
        setData(res);
    }

    useEffect(() => {
        onSearch();
    }, [alumniType]);

    if (!data) {
        return <SkeletonLoading />;
    }

    return (
        <div className={Style.root}>
            <div className={Style.search}>
                <SearchBar
                    placeholder="请输入名称"
                    onSearch={onSearch}
                    onChange={setName}
                    value={name}
                />
            </div>
            <Spin loading={loading}>
                {data?.length > 0 && (
                    <List className={Style.main}>
                        {data?.map(item => (
                            <List.Item
                                onClick={() => onClick(item.alumniId)}
                                key={item.alumniId}
                                clickable>
                                {item.name}
                            </List.Item>
                        ))}
                    </List>
                )}
                {data?.length === 0 && (
                    <div className={Style.empty}>
                        <Empty />
                    </div>
                )}
            </Spin>
        </div>
    );
};

export default Index;
