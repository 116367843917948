/**
 * 用 0/1代替true/false
 */
import {Switch, SwitchProps} from 'antd-mobile';
import {isTrue} from '../../../Utils/help';
import React from 'react';

interface SimpleSwitchProps extends Omit<SwitchProps, 'checked' | 'onChange'> {
    value?: '0' | '1';
    onChange?(value?: '0' | '1'): void;
}

export function SimpleSwitch({value, onChange, ...props}: SimpleSwitchProps) {
    const checked = isTrue(value);

    function handleChange(checked: boolean) {
        onChange?.(checked ? '1' : '0');
    }

    return (
        <Switch
            {...props}
            checked={checked}
            onChange={handleChange}
            style={{'--height': '24px'}}
        />
    );
}
