import React from 'react';
import TabPage from '../../../Components/Layout/TabPage';
import {makeLoader, useLoaderData} from 'react-router-typesafe';
import {MyActivityData} from './service';
import List from './Components/List';
import {useAtom} from 'jotai';
import {currentAtom} from './store';

export const MyActivityDataLoader = makeLoader(MyActivityData);

const Index = () => {
    const [active, setActive] = useAtom(currentAtom);
    const {launchData, joinData} = useLoaderData<typeof MyActivityDataLoader>();
    const tabItems = [
        {key: 'join', title: '我参加的活动', element: <List data={joinData} />},
        {
            key: 'launch',
            title: '我发起的活动',
            element: <List data={launchData} />,
        },
    ];
    return (
        <TabPage tabItems={tabItems} active={active} setActive={setActive} />
    );
};

export default Index;
