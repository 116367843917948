import React, {useState} from 'react';
import Style from './index.module.scss';
import {useInterval} from 'react-use';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import {sendPhoneMsg} from '../../../Public';
import Spin from '../../Spin';
import {Toast} from 'antd-mobile';
import {errorMessage} from '../../../Utils/help';

interface Props {
    phone?: string;
}
const STATIC_TIME: number = 60;
const Index = ({phone}: Props) => {
    const [time, setTime] = useState(STATIC_TIME);
    useInterval(
        () => {
            if (time > 0) {
                setTime(v => v - 1);
            } else {
                setTime(STATIC_TIME);
            }
        },
        time < STATIC_TIME ? 1000 : null,
    );

    const [sendMsgAction, {loading}] = useRequestFn(sendPhoneMsg);

    async function onClick() {
        if (time === STATIC_TIME) {
            try {
                await sendMsgAction(phone!);
                Toast.show('发送成功');
            } catch (e) {
                Toast.show(errorMessage(e));
            }
            setTime(v => v - 1);
        }
    }

    if (!phone) {
        return <div className={Style.extraPart}>发送验证码</div>;
    }

    return (
        <Spin loading={loading}>
            <button
                className={Style.extraPart}
                onClick={onClick}
                disabled={time < STATIC_TIME}>
                {time === 60 ? '发送验证码' : `${time}秒后重新发送`}
            </button>
        </Spin>
    );
};
export default Index;
