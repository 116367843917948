import {NoLoginRequest} from 'src/Utils/Request';
import {isTrue} from 'src/Utils/help';
import {getPersonByLoginUserId} from './index';

export enum ActivationType {
    access = '0',
    noAccess = '1',
}

export interface CardInfo {
    alumniNo: string;
    name: string;
    enterYear?: string;
    majorLabel?: string;
    oldCollege?: string;
    oldCollegeLabel?: string;
    cardPic?: string;
    cardExpirationDate?: string;
}

/**
 * 获取校友卡信息
 */
export async function getAlumniCard(): Promise<CardInfo | undefined> {
    const cardFlag = await getAlumniCardFlag();
    if (!cardFlag) return undefined;

    const userInfo = await getPersonByLoginUserId();

    return NoLoginRequest.post<SuccessData<{alumniNo: string}>>(
        `/alumni/getCardAlumniNo`,
    ).then(res => ({...userInfo, ...res.data.data}));
}

export async function getAlumniCardFlag() {
    try {
        return await NoLoginRequest.post<
            SuccessData<{cardActiveFlag: BooleanValue}>
        >(`/alumni/getAlumniCardFlag`).then(res =>
            isTrue(res.data.data.cardActiveFlag),
        );
    } catch (e) {
        return false;
    }
}

/**
 * 判断是否可以激活校友卡
 */
export function checkAlumniCardAct() {
    return NoLoginRequest.post<
        SuccessData<{
            checkFlag: ActivationType;
        }>
    >(`/alumni/checkAlumniCardAct`).then(res => res.data.data);
}

export interface CardBaseInfo {
    showValue: string;
    qrCodeImg: string;
    backPic: string;
}

/**
 * 获取校友卡展示形式
 */
export function getAlumniCardBasicParam() {
    return NoLoginRequest.post<SuccessData<CardBaseInfo>>(
        '/alumni/getAlumniCardBasicParam',
    ).then(res => res.data.data);
}
