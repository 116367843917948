import React, {useMemo, useRef} from 'react';
import Styles from './index.module.scss';
import {Swiper, SwiperRef, Tabs} from 'antd-mobile';
import {useMeasure} from 'react-use';
import useMainHeight from '../../../Utils/Hooks/useMainHeight';
import FixedBottom from '../FixedBottom';

export interface TabPageProps {
    key: string;
    title: string;
    element: React.ReactNode;
}

interface Props {
    bottom?: React.ReactNode | null;
    tabItems: TabPageProps[];
    active: number;
    setActive: (n: number) => void;
}

const Index = ({tabItems, bottom, active, setActive}: Props) => {
    const swiperRef = useRef<SwiperRef>(null);
    const [ref, {height}] = useMeasure<HTMLDivElement>();

    const refBottom = useRef<HTMLDivElement>(null);
    const bottomHeight = useMemo(() => {
        return refBottom?.current?.getBoundingClientRect().height || 0;
    }, [refBottom?.current]);

    const {mainHeight} = useMainHeight(height + bottomHeight);

    return (
        <div>
            <div ref={ref}>
                <Tabs
                    activeKey={tabItems[active].key}
                    onChange={key => {
                        const index = tabItems.findIndex(
                            item => item.key === key,
                        );
                        setActive(index);
                        swiperRef.current?.swipeTo(index);
                    }}>
                    {tabItems.map(item => (
                        <Tabs.Tab title={item.title} key={item.key} />
                    ))}
                </Tabs>
            </div>
            <Swiper
                style={{height: mainHeight}}
                direction="horizontal"
                indicator={() => null}
                ref={swiperRef}
                defaultIndex={active}
                onIndexChange={index => {
                    setActive(index);
                }}>
                {tabItems.map(item => (
                    <Swiper.Item key={item.key}>
                        <div
                            className={Styles.content}
                            style={{height: mainHeight}}>
                            {item.element}
                        </div>
                    </Swiper.Item>
                ))}
            </Swiper>

            {bottom && <FixedBottom refs={refBottom}>{bottom}</FixedBottom>}
        </div>
    );
};

export default Index;
