import React, {useEffect, useMemo} from 'react';
import Style from './index.module.scss';
import {FileProps, UploadStatus} from '../../../Utils/Hooks/UpoadGenProps';
import useUploadGen from '../../../Utils/Hooks/useUploadGen';
import FileSelect from '../../FileSelect';
import {Button, Grid, Image, ImageViewer} from 'antd-mobile';
import ListItem from './ListItem';
import {getUploadToken} from '../../../Public';
import useRequest from '../../../Utils/Hooks/useRequest';
import {CloseCircleOutline} from 'antd-mobile-icons';
import lodash from 'lodash-es';

interface Props {
    value?: string[];
    onChange?: (value: string[]) => void;
    onCancel?: () => void;
    uploadUrl: string;
    data?: Record<string, any>;
    multiple?: boolean;
    canType?: string[];
    uploadLoadingChange?: (loading: boolean) => void;
    maxCount?: number;
}
const Index = ({
    onChange,
    onCancel,
    uploadUrl,
    data,
    multiple,
    canType,
    value = [],
    maxCount = 1000,
    uploadLoadingChange,
}: Props) => {
    const [tokenData] = useRequest(getUploadToken);

    const {cancel, onFileChange, uploadLoading, fileList, setFileList} =
        useUploadGen({
            data: data || {
                token: tokenData?.token,
            },
            uploadUrl,
            canType,
        });

    useEffect(() => {
        uploadLoadingChange?.(uploadLoading);
    }, [uploadLoading]);

    function cancelUpload() {
        cancel('STOP');
        onCancel?.();
    }

    const canUpload = useMemo(() => {
        return (
            fileList.length > 0 &&
            fileList.every(i => i.status === UploadStatus.Done)
        );
    }, [fileList]);

    useEffect(() => {
        if (canUpload) {
            const filesMap = fileList.map(
                item => `${tokenData?.domain}/${item.response?.key}`,
            );
            onChange?.([...value, ...filesMap]);
            setFileList([]);
        }
    }, [canUpload]);

    function onRemove(index: number) {
        const v = lodash.cloneDeep(value);
        v?.splice(index, 1);
        onChange?.(v || []);
    }

    return (
        <div className={Style.root}>
            <Grid columns={3} gap={15}>
                {value?.map((item, index) => {
                    return (
                        <Grid.Item key={item}>
                            <div className={Style.img}>
                                <CloseCircleOutline
                                    className={Style.close}
                                    onClick={() => onRemove(index)}
                                />
                                <Image
                                    onClick={() =>
                                        ImageViewer.show({image: item})
                                    }
                                    src={item}
                                    width={60}
                                    height={60}
                                    fit="cover"
                                />
                            </div>
                        </Grid.Item>
                    );
                })}
                {value?.length < maxCount && (
                    <Grid.Item>
                        {!uploadLoading && (
                            <FileSelect
                                loading={false}
                                buttonClass={Style.fileBtn}
                                multiple={multiple}
                                onFileSelect={onFileChange}
                            />
                        )}
                    </Grid.Item>
                )}
            </Grid>
            <div style={{width: '100%'}}>
                {uploadLoading && (
                    <Button
                        onClick={cancelUpload}
                        size="small"
                        style={{marginBottom: 10}}>
                        终止上传
                    </Button>
                )}
                {fileList.length > 0 && (
                    <div className={Style.upList}>
                        {Array.from(fileList).map(
                            (item: FileProps, index: number) => {
                                return <ListItem key={item.uid} file={item} />;
                            },
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
export default Index;
