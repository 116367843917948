import {MainRequest} from '../../Utils/Request';
import {LoaderFunctionArgs} from 'react-router-dom';

export interface AlumniPerson {
    userId: string;
    name: string;
    pic: string;
    majorId: string;
    enterYear: string;
    rankName: string;
}

export interface FindPersonProps extends PageProps {
    name: string;
    enterYear: string;
    major: string;
}
export function findPerson(data: FindPersonProps) {
    return MainRequest.postForm<PagedData<AlumniPerson[]>>(
        `alumni/getAlumniPersonByNEM`,
        data,
    ).then(res => res.data.data);
}

export interface PersonDetailProps {
    userId: string; //人员userId
    cardId: string; //身份证
    name: string; //姓名
    pic: string; //头像
    guid: string; //人员guid
    sex: string; //性别(1.男；2.女)
    email: string; //邮箱
    birth: string; //生日
    oldCollege: string; //学院
    companyName: string; //工作单位
    companyAddress: string; //单位地址
    companyOrg: string; //单位部门
    classLabel: string; //班级
    industryLabel: string; //所属行业
    enterYear: string; //入学年
    endYear: string; //毕业年
    phone: string; //手机号码
    wxunionid: string; //微信号
    alumniNo: string; //校友号
    infoFlag: string; //是否公开(0.否；1.是)
    oldCollegeLabel: string; //院系
    majorLabel: string; //专业名称
    weixinNum: string; // 微信
    eduLabel: string; //培养方式
    studyStyleLabel: string; //学习形式
}
export function getPersonDetail(userId: string) {
    //Detail页根据userId获得人员具体信息
    return MainRequest.get<SuccessData<PersonDetailProps>>(
        `alumni/getMemberByUserId`,
        {params: {userId}},
    ).then(res => res.data.data);
}

export function PersonDetailData(e: LoaderFunctionArgs) {
    const userId = e.params.userId as any;
    return getPersonDetail(userId);
}
