import React, {useContext} from 'react';
import style from './index.module.scss';

import {CardInfo} from 'src/Public/card';
import {CardFullLocked} from '../CardFullLocked';
import {CardContext} from '../../context';
import {useAtomValue} from 'jotai';
import {LogoImgAtom} from '../../../../Store/ImgAtom';
import styles from '../CardSmall/index.module.scss';

interface CardFullProps {
    data?: CardInfo | null;
}

export function CardFull({data}: CardFullProps) {
    const ctx = useContext(CardContext);
    const logo = useAtomValue(LogoImgAtom);

    const innerData = data || ctx.data;

    if (!innerData) return <CardFullLocked />;

    return (
        <div className={style.root}>
            <img className={style.image} src={ctx.base?.backPic} alt="" />

            <div className={style.logo}>
                <img src={logo} alt="" />
                <div className={styles.title}>校友卡</div>
            </div>

            <div className={style.cardID}>No.{innerData?.alumniNo}</div>

            <div className={style.info}>
                <div className={style.name}>{innerData?.name} </div>

                {innerData?.enterYear && <div>{innerData?.enterYear}级</div>}

                {innerData?.majorLabel && <div>{innerData?.majorLabel}</div>}

                {innerData?.oldCollegeLabel && (
                    <div>{innerData?.oldCollegeLabel}</div>
                )}
            </div>

            {innerData?.cardPic && (
                <div className={style.photo}>
                    <img src={innerData?.cardPic} alt="" />
                </div>
            )}
        </div>
    );
}
