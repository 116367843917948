import React, {useContext} from 'react';
import styles from './index.module.scss';
import {goVerify} from '../../utils';
import {CardContext} from '../../context';
import useUser from '../../../../Utils/Hooks/useUser';
import {goLogin} from '../../../../Utils/help';
import {ActivationType} from 'src/Public/card';
import style from '../CardFull/index.module.scss';
import {useAtomValue} from 'jotai';
import {LogoImgAtom} from '../../../../Store/ImgAtom';

export function CardFullLocked() {
    const ctx = useContext(CardContext);
    const logo = useAtomValue(LogoImgAtom);
    const [user] = useUser();

    const clickHandler = () => {
        if (!user) {
            goLogin();
        }
        if (ctx.checkFlag === ActivationType.access) {
            goVerify();
        }
    };

    return (
        <div className={styles.root} role="button" onClick={clickHandler}>
            <img className={style.image} src={ctx.base?.backPic} alt="" />

            <div className={style.logo}>
                <img src={logo} alt="" />
                <div className={styles.title}>校友卡</div>
            </div>

            <div className={styles.overlay}>
                <img
                    width="28rpx"
                    height="30rpx"
                    src={require('./img/lock.png')}
                    alt=""
                />
                <div className={styles.button}>激活校友卡</div>
            </div>
        </div>
    );
}
